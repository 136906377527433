import {
    IResourceComponentsProps, useCreate,
} from '@pankod/refine-core';
import {
    Image,
    Typography,
    Row,
    Col,
    Avatar,
    Card,
    useTable, Carousel, Skeleton, useSelect
} from '@pankod/refine-antd';
import {Button, Grid, Modal, Space, Stepper, Toast} from 'antd-mobile'
import {
    PlusOutlined,
    MinusOutlined,
    DeleteOutlined, DashOutlined, AppstoreOutlined, ShoppingCartOutlined
} from '@ant-design/icons';
import {IBasketListItem, IProduct, IUserCrediti, IUserGift} from '../../interfaces';
import React, {useEffect, useState} from 'react';
import {API_URL, TOKEN_KEY} from '../../constants';
import {useLocation, useNavigate} from 'react-router-dom';
import {RightOutline} from 'antd-mobile-icons';
import {apiFunction} from '../../apiFuntion';
import jwt_decode from 'jwt-decode';
const { Title } = Typography;
const { Meta } = Card;


export const ProductList: React.FC<IResourceComponentsProps> = () => {
    const navigate = useNavigate();
    const location = useLocation();
    // @ts-ignore
    const decoded: any = localStorage.getItem(TOKEN_KEY) ? jwt_decode(localStorage.getItem(TOKEN_KEY)) : {id: 0};
    const token = localStorage.getItem(TOKEN_KEY);
    let [prezzo, setPrezzo] = useState(0);
    let [currentSlide, setCurrentSlide] = useState(0);
    let [num, setNum]= useState(0);
    const [view, setView] = useState('list');
    const apiImage = API_URL;
    const {tableProps, tableQueryResult} = useTable<IProduct>(
        { resource: 'prodottis', metaData: {
        populate: "foto",
    }});
    const [credito, setCredito] = useState(0);
    const crediti = useCreate<IUserCrediti>();
    const { queryResult } = useSelect<IUserCrediti>({
        resource: "user-creditis",
        metaData: {
            populate: "user",
        },
        filters: [
            {
                field: "user][id]",
                operator: "eq",
                value: decoded?.id
            }
        ]
    });

    const basket = (JSON.parse(localStorage.getItem('basket') || '{}'));
    const [products, setProducts] = useState<IBasketListItem[]>((basket && basket.length > 0 ? basket : []));
    const getProductQuantityById = (id: number): IBasketListItem | undefined => {
        return products.find((p) => p.item?.id === id);
    };
    const getProductById = (id: number): IBasketListItem | undefined => {
        return products.find((p) => p.item?.id === id);
    };

    useEffect(() => {
        const total = (products.reduce((total,currentItem) =>  total = total + currentItem.importo , 0 ));
        getCredito().then( (r: any) => {
            console.log(r);
            if (r === 0) {
                console.log('Credito Insufficente');
                navigate('/acquista', {state: {importo: total}})
            }
        })
        setPrezzo(total);
        localStorage.setItem('basket', JSON.stringify(products));
        onCheck(currentSlide, currentSlide)
        if (queryResult.status === 'success') {
            setCredito(queryResult.data.data.reduce((total,currentItem: IUserCrediti) =>  total = total + currentItem.importo , 0 ));
        }
    });

    useEffect(() => {
        console.log(products)
        if (products && products.length === 0) {
            tableQueryResult && tableQueryResult.data?.data.map((pro, i) => {
                add(pro);
            })
            //navigate('/product');
        } else {
            // tableQueryResult && tableQueryResult.data?.data.map((pro, i) => {
            //     add(pro);
            // })
        }
        // @ts-ignore
    }, [tableQueryResult.data?.data.length > 0])
    const changeView = (view: string) => {
        setView(view)
    }

    const getCredito = () => {
        return apiFunction.getUserCrediti(token, decoded.id);
    }

    let incNum =()=>{
        if(num<10)
        {
            addProductToCart({
                item: tableQueryResult.data?.data[currentSlide],
                quantita: 1,
                importo: Number(tableQueryResult.data?.data[currentSlide].prezzo),
                id: Number(tableQueryResult.data?.data[currentSlide].id)
            });
        }
    };
    let decNum = () => {
        if(num>0)
        {
            removeProductFromCart({
                item: tableQueryResult.data?.data[currentSlide],
                quantita: 1,
                importo: Number(tableQueryResult.data?.data[currentSlide].prezzo),
                id: Number(tableQueryResult.data?.data[currentSlide].id)
            });
        }
    }

    let add =(item?: IProduct)=>{
        if(qtaCart(Number(item?.id))<10)
        {
            addProductToCart({
                item: item,
                quantita: 1,
                importo: Number(item?.prezzo),
                id: Number(item?.id)
            });
        }
    };
    let del = (item?: IProduct) => {
        if(qtaCart(Number(item?.id))>0)
        {
            removeProductFromCart({
                item: item,
                quantita: 1,
                importo: Number(item?.prezzo),
                id: Number(item?.id)
            });
        }
    }

    let addCart =(item?: IProduct)=>{
        if(qtaCart(Number(item?.id))<10)
        {
            addProductToCart({
                item: item,
                quantita: 1,
                importo: Number(item?.prezzo),
                id: Number(item?.id)
            });
        }
    };
    let delCart = (item?: IProduct) => {
        console.log(qtaCart(Number(item?.id)))
        if(qtaCart(Number(item?.id))>0)
        {
            removeProductFromCart({
                item: item,
                quantita: 1,
                importo: Number(item?.prezzo),
                id: Number(item?.id)
            });
        }
    }

    let removeProduct = (item?: IProduct) => {
        console.log(qtaCart(Number(item?.id)))
        const getProducts = products.filter((p) => p.item?.id !== item?.id);
        setProducts(getProducts);
        if (getProducts.length === 0) {
            go('/product', 'back')
        }
    }

    const qtaCart = (id: number) => {
        const b = basket?.findIndex((r: any) => r.item?.id === id)
        if (b !== -1) {
            return basket[b].quantita
        } else {
            return 0
        }
    }

    const prezzoCart = (id: number) => {
        console.log(basket)
        const b = basket.findIndex((r: any) => r.item.id === id)
        if (b !== -1) {
            return basket[b].importo
        } else {
            return 0
        }
    }

    function go(path: string, param?: string) {
        if (param === 'back') {
            navigate(path, { replace: true, state: {data: products} });
        } else {
            if (prezzo === 0) {
                Toast.show({
                    position: 'top',
                    content: <div style={{ alignContent: 'center', textAlign: 'center'}}>
                        <Title level={5} style={{ color: 'white'}}>Non hai selezionato <br />nessun Caffè</Title>
                    </div>
                })
            } else {
                navigate(path, { replace: true, state: {data: products} });
            }
        }
    }

    function onChange(current: number) {
        setCurrentSlide(current);
    }

    function onCheck(from: number, to: number) {
            const idProduct = Number(tableQueryResult.data?.data[to].id);
        if (idProduct) {
            const qta = Number(getProductQuantityById(idProduct)?.quantita)
            if (qta) {
                setNum(qta);
            } else {
                setNum(0)
            }
        }
    }

    const addProductToCart = (product: IBasketListItem): void => {
        {

            const existingProduct = getProductById(Number(product.item?.id));
            let newState: IBasketListItem[] = [];
            if (existingProduct) {
                newState = products.map((p) => {
                    if (p.item?.id === existingProduct.id) {
                        console.log('ADD QTA')
                        setNum(Number(num)+1)
                        return {
                            item: p.item,
                            quantita: Number(p.quantita) + product.quantita,
                            importo: p.importo + p.item.prezzo,
                            id: p.item.id
                        };
                    }
                    return p;
                });
                setProducts(newState);
            } else {
                console.log('ADD PRODOTTO')
                setNum(1)
                setProducts([...products, {
                    item: product.item,
                    quantita: product.quantita,
                    importo: product.importo,
                    id: product.id
                }]);
            }
        }
    };

    const removeProductFromCart = (product: IBasketListItem) => {
        setNum(Number(num)-1);
        console.log(num, Number(num)-1)
        let newState: IBasketListItem[] = [];
        if (Number(num)-1 === 0) {
            console.log('ELIMINO')
            const getProducts = products.filter((p) => p.item?.id !== product.id);
            setProducts(getProducts);
            // navigate('/product')
        } else {
            console.log('SCALO')
            newState = products.map((p) => {
                if (p.item?.id === product.id) {
                    return {
                        item: p.item,
                        quantita: Number(p.quantita) - product.quantita,
                        importo: p.importo - p.item.prezzo,
                        id: p.item.id
                    };
                }
                return p;
            });
            setProducts(newState);
        }

    };

    const saveCredito = () => {
        crediti.mutate({
            resource: "user-creditis",
            values: {
                descrizione: "Acquisto Gift",
                user: decoded.id,
                importo: - total
            }
        });
    }

    const gift = useCreate<IUserGift>();

    const saveData = () => {
        if (products && products.length > 0) {
            products.forEach( (c: any) => {
                gift.mutate({
                    resource: "user-gifts",
                    values: {
                        descrizione: "Acquisto" + ' ' + c.item.descrizione,
                        user: decoded.id,
                        fromUser: decoded.id,
                        prodotti: c.item.id,
                        tipo: 'acquistato',
                        quantita: c.quantita
                    }
                });
            });
            localStorage.setItem('basket', '');
            saveCredito();
            navigate('/completato', {state: []})
        } else {
            Toast.show({
                position: 'top',
                content: <div style={{textAlign: 'center'}}>Devi mettere almeno un <br />Caffè nel carrello!</div>
            })
        }

    }

    function confermaOrdine() {
        if (Number(credito) < total) {
            console.log('CREDITO INSUFFICENTE');
            Modal.show({
                content: 'Credito insufficiente',
                closeOnAction: true,
                actions: [
                    {
                        key: 'ok',
                        text: 'Acquista Crediti',
                        primary: true,
                    },
                    {
                        key: 'ko',
                        text: 'Annulla',
                    },
                ],
                onAction: (e) => {
                    if (e.key === 'ok') {
                        handleOk();
                    } else {
                        handleCancel();
                    }
                }
            })
        } else {
            // console.log('ORDINE CONFERMATO:', cart);
            saveData()
        }
    }

    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleOk = () => {
        setIsModalVisible(false);
        navigate('/acquista');
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const total = (products.reduce((total,currentItem: IBasketListItem) =>  total = total + currentItem.importo , 0 ));

    return (
            <>
                <Row justify="center" align="top" style={{marginTop: '30px'}}>
                    <Col span={10}>
                        <Title level={4} style={{
                            color: '#8E201BFF',
                            marginTop: '0px'
                        }}>Shop</Title>
                    </Col>
                </Row>
                <Row justify="center" align="top" style={{marginTop: '10px'}}>
                    {tableQueryResult && tableQueryResult.data?.data.map((pro, i) => (
                        <div key={pro.id} style={{
                            padding: 10,
                        }}>
                            <Col span={32}>
                                <div style={{
                                    color: '#FFFFFF',
                                    height: 200,
                                    width: 130,
                                    boxShadow: '-3px 1px 3px rgba(0, 0, 0, 0.1)',
                                    borderRadius: '30px',
                                    marginLeft: '15px',
                                    marginRight: '15px'
                                }}
                                >
                                    <Avatar style={{
                                        width: '100%',
                                        height: '100%',
                                        borderTopLeftRadius: 20,
                                        borderTopRightRadius: 20,
                                        borderBottomLeftRadius: 20,
                                        borderBottomRightRadius: 20
                                    }} src={apiImage + '' + pro?.foto.url}/>
                                    <div style={{margin: '15px'}}>

                                    </div>
                                    <div style={{
                                        textAlign: 'center',
                                        marginRight: 45,
                                    }}>
                                        <Button style={{
                                            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                            border: '1px solid #FFFFFF ',
                                            background: '#fad1b7',
                                            position: 'absolute',
                                            marginLeft: 20,
                                            marginTop: -210,
                                            borderRadius: 30,
                                            height: 30,
                                            width: 60,
                                            fontSize: 10,
                                            color: '#932928FF'}}>€ {Number(pro.prezzo).toLocaleString(undefined, {minimumFractionDigits: 2,
                                            maximumFractionDigits: 2})}</Button>
                                    </div>
                                    <div>
                                        <Title level={3} style={{
                                            fontSize: 14,
                                            position: 'absolute',
                                            zIndex: 99999999,
                                            marginTop: -50,
                                            color: '#FFFFFF',
                                            textAlign: 'center',
                                            inlineSize: 120,
                                            padding: '1px 3px 10px 10px',
                                            overflowWrap: 'break-word'
                                        }}>{pro.descrizione}</Title>
                                    </div>
                                    <div style={{
                                        marginRight: -230,
                                        marginTop: -10
                                    }}>
                                    </div>
                                </div>
                            </Col>
                            <Row justify="center" align="top" style={{marginTop: '10px'}}>
                                <Col span={32}>
                                    <Stepper
                                        style={{
                                            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                            '--border': '1px solid #f5f5f5',
                                            '--border-radius': '30px',
                                            '--border-inner': 'none',
                                            '--height': '30px',
                                            '--input-width': '70px',
                                            '--input-font-color': '#ffffff',
                                            '--input-font-size': '20px',
                                            '--input-background-color': 'rgba(255,255,255,0)',
                                            '--active-border': '1px solid #1677ff',
                                            '--button-background-color': 'rgba(255,255,255,0)',
                                        }}
                                        value={qtaCart(pro.id)}
                                        onChange={value => {
                                            if (value - qtaCart(pro.id) > 0) {
                                                addCart(pro)
                                            } else {
                                                delCart(pro)
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>

                    ))
                    }
                </Row>
                <Row justify="center" align="middle"
                     style={{
                         marginTop: 20
                }}>
                        <Card
                            bordered={false}
                            style={{
                                width: 300,
                                marginTop: 10,
                                borderRadius: 20,
                                border: 'none',
                                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                background: '#e28a74' }}
                        >
                            <Skeleton loading={false} avatar active>
                                <Meta
                                    avatar={<ShoppingCartOutlined style={{
                                        fontSize: 50,
                                        color: '#000000',
                                    }}/>}
                                    title={<div style={{color: '#ffffff', textAlign: 'right'}}>Totale Spesa: € {Number(total).toLocaleString(undefined, {minimumFractionDigits: 2,
                                        maximumFractionDigits: 2})}</div>}
                                    description={<div style={{color: '#000000', textAlign: 'right'}}>Credito a disposizione: € {Number(credito).toLocaleString(undefined, {minimumFractionDigits: 2,
                                        maximumFractionDigits: 2})}</div>}
                                />
                            </Skeleton>
                        </Card>
                </Row>
                <Row justify="center" align="top" style={{marginTop: 20}}>
                    {/*<Col span={8}>*/}
                    {/*    <Button onClick={() => go('/product', 'back')} style={{*/}
                    {/*        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',*/}
                    {/*        border: 'none',*/}
                    {/*        background: '#ffffff',*/}
                    {/*        color: '#800100',*/}
                    {/*        borderRadius: 20}}>Indietro</Button>*/}
                    {/*</Col>*/}
                    <Col span={8}>
                        <Button onClick={confermaOrdine} style={{
                            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                            border: 'none',
                            background: '#800100FF',
                            color: '#FFFFFF',
                            borderRadius: 20}}>Procedi</Button>
                    </Col>
                </Row>
            </> ||
        <>
            <Row justify="center" align="top">

            </Row>
            <Row justify="center" align="top" style={{marginTop: '30px'}}>
                <Col span={10}>
                    <Title level={5}>Shop</Title>
                </Col>
            </Row>
            {view === 'carousel' &&
                <>
                <Row justify="center" align="top" style={{marginTop: '10px'}}>
                    <Col>
                        <Carousel
                            afterChange={onChange}
                            beforeChange={onCheck}
                            className={'center'}
                            dots={false}
                            centerMode={tableQueryResult?.data?.data.length !== 1}
                            centerPadding={'30px'}
                            slidesToShow={1}
                            style={{width: '100%'}}>
                            {tableQueryResult && tableQueryResult.data?.data.map((pro, i) => (
                                <div key={pro.id}>
                                    <div style={{
                                        background: '#FFFFFF',
                                        height: 300,
                                        boxShadow: '-3px 1px 3px rgba(0, 0, 0, 0.1)',
                                        borderRadius: '30px',
                                        marginLeft: '15px',
                                        marginRight: '15px'
                                    }}>
                                        <Avatar style={{
                                            width: '100%',
                                            height: 160,
                                            borderTopLeftRadius: '30px',
                                            borderTopRightRadius: '30px'
                                        }} src={apiImage + '' + pro.foto.url}/>
                                        <div style={{margin: '15px'}}>
                                            <Button shape={'rounded'} style={{
                                                height: 80,
                                                width: 80,
                                                border: '1px solid #FFFFFF',
                                                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
                                                background: '#FAD1B7FF'
                                            }}>{Number(pro.prezzo).toLocaleString(undefined, {minimumFractionDigits: 2,
                                                maximumFractionDigits: 2})} €
                                            </Button>
                                        </div>
                                        <div>
                                            <Title level={3} style={{color: '#932928'}}>{pro.descrizione}</Title>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    </Col>
                </Row>
                    <Grid columns={7} gap={0} style={{marginTop: 20}}>
                        <Grid.Item span={2}>
                            <Button style={{
                                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                border: '1px solid #FFFFFF',
                                background: '#fad1b7',
                                borderRadius: '30px',
                                height: '40px',
                                width: 70,
                                fontSize: 12,
                                color: '#932928FF'}}>€ {Number(prezzo).toLocaleString(undefined, {minimumFractionDigits: 2,
                                maximumFractionDigits: 2})}</Button>
                        </Grid.Item>
                        <Grid.Item span={3}>
                            <Stepper
                                style={{
                                    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                    '--border': '1px solid #f5f5f5',
                                    '--border-radius': '30px',
                                    '--border-inner': 'none',
                                    '--height': '40px',
                                    '--input-width': '70px',
                                    '--input-font-color': '#ffffff',
                                    '--input-font-size': '25px',
                                    '--input-background-color': 'rgba(255,255,255,0)',
                                    '--active-border': '1px solid #1677ff',
                                    '--button-background-color': 'rgba(255,255,255,0)',
                                }}
                                value={num}
                                onChange={value => {
                                    if (value - num > 0) {
                                        incNum()
                                    } else {
                                        decNum()
                                    }
                                }}
                            />
                        </Grid.Item>
                        <Grid.Item span={2}>
                            <Button style={{
                                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                background: '#800100FF',
                                borderRadius: '30px',
                                height: '45px',
                                width: '45px',
                            }} onClick={() => go('/product?page=riepilogo')}>
                                <RightOutline color='#DBBCA7'/>
                            </Button>
                        </Grid.Item>
                    </Grid>
                </>
            ||
                <>
                    <Button style={{
                        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                        position: 'absolute',
                        zIndex: '999999',
                        marginTop: -155,
                        marginLeft: 120,
                        background: '#800100FF',
                        borderRadius: '30px',
                        height: '45px',
                        width: '45px',
                    }} onClick={() => go('/product?page=riepilogo')}>
                        <RightOutline color='#DBBCA7'/>
                    </Button>
                <Row justify="center" align="top" style={{marginTop: '10px'}}>
                    {tableQueryResult && tableQueryResult.data?.data.map((pro, i) => (
                        <div key={pro.id} style={{
                            padding: 10,
                        }}>
                            <Col span={32}>
                                <div style={{
                                    color: '#FFFFFF',
                                    height: 200,
                                    width: 130,
                                    boxShadow: '-3px 1px 3px rgba(0, 0, 0, 0.1)',
                                    borderRadius: '30px',
                                    marginLeft: '15px',
                                    marginRight: '15px'
                                }}
                                >
                                    <Avatar style={{
                                        width: '100%',
                                        height: '100%',
                                        borderTopLeftRadius: 20,
                                        borderTopRightRadius: 20,
                                        borderBottomLeftRadius: 20,
                                        borderBottomRightRadius: 20
                                    }} src={apiImage + '' + pro?.foto.url}/>
                                    <div style={{margin: '15px'}}>
                                        {/*<Checkbox*/}
                                        {/*    value={pro.id}*/}
                                        {/*    onChange={(val) => {*/}
                                        {/*        console.log(val, pro.id);*/}
                                        {/*        setProducts(pro)*/}
                                        {/*    }}></Checkbox>*/}
                                    </div>
                                    <div style={{
                                        textAlign: 'center',
                                        marginRight: 45,
                                    }}>
                                        <Button style={{
                                            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                            border: '1px solid #FFFFFF ',
                                            background: '#fad1b7',
                                            position: 'absolute',
                                            marginLeft: 20,
                                            marginTop: -210,
                                            borderRadius: 30,
                                            height: 30,
                                            width: 60,
                                            fontSize: 10,
                                            color: '#932928FF'}}>€ {Number(pro.prezzo).toLocaleString(undefined, {minimumFractionDigits: 2,
                                            maximumFractionDigits: 2})}</Button>
                                    </div>
                                    <div>
                                        <Title level={3} style={{
                                            fontSize: 14,
                                            position: 'absolute',
                                            zIndex: 99999999,
                                            marginTop: -50,
                                            color: '#FFFFFF',
                                            textAlign: 'center',
                                            inlineSize: 120,
                                            padding: '1px 3px 10px 10px',
                                            overflowWrap: 'break-word'
                                        }}>{pro.descrizione}</Title>
                                    </div>
                                    <div style={{
                                        marginRight: -230,
                                        marginTop: -10
                                    }}>
                                    </div>
                                </div>
                            </Col>
                            <Row justify="center" align="top" style={{marginTop: '10px'}}>
                                <Col span={32}>
                                    <Stepper
                                        style={{
                                            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                            '--border': '1px solid #f5f5f5',
                                            '--border-radius': '30px',
                                            '--border-inner': 'none',
                                            '--height': '30px',
                                            '--input-width': '70px',
                                            '--input-font-color': '#ffffff',
                                            '--input-font-size': '20px',
                                            '--input-background-color': 'rgba(255,255,255,0)',
                                            '--active-border': '1px solid #1677ff',
                                            '--button-background-color': 'rgba(255,255,255,0)',
                                        }}
                                        value={qtaCart(pro.id)}
                                        onChange={value => {
                                            if (value - qtaCart(pro.id) > 0) {
                                                addCart(pro)
                                            } else {
                                                delCart(pro)
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row justify="center" align="middle"
                                 style={{
                                     marginTop: 20
                                 }}>
                                <Card
                                    bordered={false}
                                    style={{
                                        width: 300,
                                        marginTop: 10,
                                        borderRadius: 20,
                                        border: 'none',
                                        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                        background: '#e28a74' }}
                                >
                                    <Skeleton loading={false} avatar active>
                                        <Meta
                                            avatar={<ShoppingCartOutlined style={{
                                                fontSize: 50,
                                                color: '#000000',
                                            }}/>}
                                            title={<div style={{color: '#ffffff', textAlign: 'right'}}>Totale Spesa: € {Number(total).toLocaleString(undefined, {minimumFractionDigits: 2,
                                                maximumFractionDigits: 2})}</div>}
                                            description={<div style={{color: '#000000', textAlign: 'right'}}>Credito a disposizione: € {Number(credito).toLocaleString(undefined, {minimumFractionDigits: 2,
                                                maximumFractionDigits: 2})}</div>}
                                        />
                                    </Skeleton>
                                </Card>
                            </Row>
                        </div>

                    ))
                    }
                </Row>
                    </>
            }
        </>
    );
};
