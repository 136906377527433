import React from 'react';

export const BarIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        viewBox="0 0 1024 1024"
        width="3em"
        height="3em"
        fill="none"
        {...props}
    >
        <g>
            <path d="M10,955c0,19.4,15.6,35,35,35h770c19.4,0,35-15.6,35-35c0-19.4-15.6-35-35-35H430H45C25.6,920,10,935.7,10,955z"/>
            <path d="M255,301.7c0,35.2,9.5,62.8,28.7,84.1c16.5,18.2,37.3,29.2,61,40.7c4.9,2.5,10.2,3.5,15.3,3.5c12.9,0,25.4-7.2,31.5-19.7c8.4-17.4,1.2-38.4-16.2-46.8C337.1,345,325,337.4,325,301.7c0-13.4,8.3-23.3,24-41c19.4-21.6,46-51.2,46-99c0-35.6-14.9-72.1-43.2-105.8c-19.7-23.6-39.3-37.8-41.4-39.3c-15.8-11.3-37.6-7.7-48.9,8.1c-11.3,15.8-7.7,37.6,8.1,48.9C283.9,83.8,325,122,325,161.7c0,20.1-10.2,32.4-28,52.4C278.2,234.8,255,260.7,255,301.7z"/>
            <path d="M500,208.4c0,19.7-8.9,38.8-17.4,57.3C474,284.4,465,303.9,465,325c0,35.9,18.5,66.6,52.1,86.6c22.3,13.2,44.1,17.4,46.6,17.9c2.1,0.4,4.2,0.6,6.3,0.6c16.6,0,31.3-11.8,34.4-28.7c3.5-19-9.1-37.2-28.1-40.7c-0.1,0-12-2.5-23.4-9.2C535,340.8,535,329.7,535,325c0-5.8,6.2-19.3,11.2-30.1c10.6-23,23.8-51.6,23.8-86.7c0-52.1-37-107.1-41.2-113.3c-11-15.9-32.8-19.8-48.6-8.9c-15.9,11-19.8,32.8-8.9,48.6C478.7,145.7,500,182.3,500,208.4z"/>
            <path d="M850,500c-44.2,0-83.7,20.7-109.3,52.7c-3-18.1-7.6-35.7-13.7-52.7H133c-11.7,32.9-18,68.1-18,105c0,173.9,141,315,315,315c137.9,0,255-88.6,297.7-211.9C751.7,751,797.5,780,850,780c77.2,0,140-62.8,140-140S927.2,500,850,500z M603.3,778.3C556.9,824.6,495.4,850,430,850s-126.9-25.4-173.3-71.8S185,670.5,185,605c0-11.8,0.8-23.5,2.5-35h485c1.6,11.5,2.5,23.2,2.5,35C675,670.5,649.6,732,603.3,778.3z M850,710c-38.6,0-70-31.4-70-70c0-38.6,31.4-70,70-70s70,31.4,70,70C920,678.6,888.6,710,850,710z"
            />
        </g>
    </svg>
);
