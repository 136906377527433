import {
    IResourceComponentsProps, useCreate,
} from '@pankod/refine-core';
import {
    Typography,
    Card,
    Row,
    Col,
    Button,
    Select
} from '@pankod/refine-antd';
import React, {useEffect, useState} from 'react';
import {PayPalButtons, PayPalScriptProvider} from '@paypal/react-paypal-js';
import {useLocation, useNavigate} from 'react-router-dom';
import {IUserCrediti} from '../../interfaces';
import jwt_decode from 'jwt-decode';
import {TOKEN_KEY} from '../../constants';
import {Spin} from 'antd';
const { Option } = Select;
const { Title } = Typography;


export const PagamentoList: React.FC<IResourceComponentsProps> = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [importo, setImporto] = useState('0');
    const { mutate, data } = useCreate<IUserCrediti>();
    // @ts-ignore
    const decoded: any = jwt_decode(localStorage.getItem(TOKEN_KEY));

    useEffect(() => {
        console.log(importo);
        // @ts-ignore
        setImporto(location.state?.importo)
    });

    function confirmed() {
        mutate({
            resource: "user-creditis",
            values: {
                descrizione: "Acquisto Credito",
                importo: Number(importo),
                user: decoded.id
            }
        });
        setTimeout( () => {
            navigate('/confirmed', { replace: true, state: 'details'})
        }, 2000)
    }

    return (
        <>
            <Row justify="center" align="top" style={{marginTop: '30px'}}>
                <Col span={14}>
                    <Title level={4} style={{
                        color: '#8E201BFF',
                        marginTop: '30px'
                    }}>Pagamento</Title>
                    <Title level={5} style={{
                        color: '#757575',
                        marginTop: '20px'
                    }}>Clicca sul pulsante "PayPal" per effettuare il pagamento</Title>
                </Col>
            </Row>
            <Row justify="center" align="top" style={{marginTop: '30px'}}>
                <Col hidden={isLoading}>
                    <PayPalScriptProvider options={{
                        "client-id": "AQzBm0o5-94aLB_SMJZbyHvZzU0s5Bt17ONc9AKlw3ne5-9gK1IdY4MPOGQKehMSrDE7RpbJ5V5QKwRZ",
                        currency: 'EUR'
                    }}>
                        <PayPalButtons
                            style={{layout: "horizontal", height: 40, shape: 'rect'}}
                            createOrder={(data, actions) => {
                                return actions.order.create({
                                    purchase_units: [
                                        {
                                            amount: {
                                                value: importo.toString(),
                                            },
                                        },
                                    ],
                                });
                            }}
                            // @ts-ignore
                            onApprove={(data, actions) => {
                                return actions?.order?.capture().then((details) => {
                                    const name = details?.payer?.name?.given_name;
                                    console.log('TRANSAZIONE ESEGUITA:', details);
                                    if (details.status === 'COMPLETED') {
                                        confirmed();
                                    } else {
                                        console.log('ERRORE')
                                    }
                                });
                            }}
                            onInit={(e: any) => {
                                console.log(e['correlationID']);
                                if (e['correlationID']) {
                                    setIsLoading(false);
                                }
                            }}
                            onCancel={() => {
                                console.log('OPERAZIONE ANNULLATA');
                            }}
                            onError={() => {
                                console.log('ERRORE GENERICO');
                            }}
                        />
                    </PayPalScriptProvider>
                </Col>
                <Col>
                    <Spin spinning={isLoading} size="large" />
                </Col>
            </Row>
            <Row justify="center" align="top" style={{marginTop: '30px'}}>
                <Col span={10}>
                    <Button type="primary" htmlType={'submit'}
                            style={{boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)', background: '#93292a', borderRadius: '30px', width: '150px', height: '40px', border: 'none'}} onClick={() => navigate('/acquista')}>Indietro</Button>
                </Col>
            </Row>
        </>
    );
};
