import {
    IResourceComponentsProps, useCreate, useList,
} from '@pankod/refine-core';
import {
    Avatar,
    Image,
    Typography,
    Card,
    Row,
    Col, useTable, Input, Skeleton, Icons, message
} from '@pankod/refine-antd';
import {
    UserSwitchOutlined,
    BarsOutlined,
    UserOutlined,
    MinusOutlined,
    PlusOutlined,
    DeleteOutlined,
    ShoppingCartOutlined,
    QrcodeOutlined
} from '@ant-design/icons';
import React, {useEffect, useState} from 'react';
import jwt_decode from 'jwt-decode';
import {API_URL, TOKEN_KEY} from '../../constants';
import {IProduct, IScaricoQrCode, IUserCrediti, IUserGift, IUserMovimentazioni} from '../../interfaces';
import {Button, Toast} from 'antd-mobile';
import moment from 'moment';
import QRCode from 'qrcode.react';
import {useLocation, useNavigate} from 'react-router-dom';
import {apiFunction} from '../../apiFuntion';
import {Link} from '@pankod/refine-react-router-v6';
import { Modal, Space } from 'antd';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const { Title } = Typography;
const { Meta } = Card;

export const MovimentiList: React.FC<IResourceComponentsProps> = () => {
    const navigate = useNavigate();
    const location = useLocation();
    // @ts-ignore
    const decoded: any = jwt_decode(localStorage.getItem(TOKEN_KEY));
    const apiImage = API_URL;
    const [tipo, setTipo] = useState('acquistato');
    const [tipologia, setTipologia] = useState(localStorage.getItem('tipologia'));
    const {data, error, isLoading} = useList<IUserGift>(
        {
            resource: 'user-gifts',
            metaData: {
                populate: "prodotti,prodotti.foto,user,fromUser",
            },
            config: {
                filters: [
                    {
                        field: "user][id]",
                        operator: "eq",
                        value: decoded?.id
                    },
                    {
                        field: "tipo",
                        operator: "contains",
                        value: tipo
                    }
                ],
                sort: [
                    {
                        field: 'createdAt',
                        order: 'desc'
                    }
                ]
            }});

    const {data: scaricoQrCode, error: errorQrCode, isLoading: isLoadingQrCode} = useList<IScaricoQrCode>(
        {
            resource: 'scarico-qr-codes',
            metaData: {
                populate: "users,user_movimentazioni, user_movimentazioni.user_gift, user_movimentazioni.user_gift.prodotti",
            },
            config: {
                pagination: {
                  pageSize: 9999999
                },
                filters: [
                    {
                        field: "users][id]",
                        operator: "eq",
                        value: decoded?.id
                    },
                ],
                sort: [
                    {
                        field: 'createdAt',
                        order: 'desc'
                    }
                ]
            }});

    const {data: riscuotereQrCode, error: errorRiscuotereQrCode, isLoading: isLoadingRiscuotereQrCode} = useList<IUserCrediti>(
        {
            resource: 'user-creditis',
            metaData: {
                populate: "user",
            },
            config: {
                filters: [
                    {
                        field: "user][id]",
                        operator: "eq",
                        value: decoded?.id
                    },
                    {
                        field: "isRefund",
                        operator: 'eq',
                        value: false
                    }
                ]
            }});

    const resultMovimentazioni = useList<IUserMovimentazioni>(
        {
            resource: 'user-movimentazionis',
            metaData: {
                populate: "user_gift,users, user_gift.fromUser, user_gift.user, user_gift.prodotti, toUser, fromUser",
            },
            queryOptions: {
                enabled: true
            },
            config: {
                filters: [
                    {
                        field: "users][id]",
                        operator: "eq",
                        value: decoded?.id
                    },
                    {
                        field: "tipo",
                        operator: "contains",
                        value: tipo
                    }
                ],
                sort: [
                    {
                        field: 'createdAt',
                        order: 'desc'
                    }
                ]
            }});

    useEffect(() => {
        switch (location.state) {
            case 'tutti':
                setTipo('');
                break;
            case 'ricevuto':
                setTipo('ricevuto');
                break;
            case 'inviato':
                setTipo('inviato');
                break;
            case 'acquistato':
                setTipo('acquistato');
                break;
            case 'usato':
                setTipo('usato');
                break;
            case 'erogati':
                setTipo('erogati');
                break;
            case 'riscosso':
                setTipo('riscosso');
                break;
            case 'riscuotere':
                setTipo('riscuotere');
                break;
            default:
                setTipo('');
                break;

        }
        // console.log(dataG)
    });

    const {info} = Modal;
    const {confirm} = Modal;
    const showConfirm = (token: any) => {
        info({
            title: '',
            icon: false,
            content: <div style={{textAlign: 'center'}}><QRCode id="qr-gen"
                             value={token}
                             size={180}
                             level={"H"}
                             renderAs={'svg'}
                                  includeMargin={true} /></div>,
        });
    };

    const sendMail = useCreate();
    const showPagamento = () => {
        if (scaricoQrCode && scaricoQrCode?.data.filter((x: any) => !x.isPagato).length === 0) {
            Toast.show('Nulla da Riscuotere');
        } else {
            confirm({
                title: 'Vuoi Richiedere il Pagamento?',
                icon: <Icons.EuroOutlined />,
                content: <div style={{textAlign: 'center'}}></div>,
                okText: 'SI',
                cancelText: 'NO',
                onOk() {
                    console.log('OK');
                    sendMail.mutateAsync({
                        resource: 'sendEmailConfirmation',
                        values: {
                            to: 'luciano.zappacosta@gmail.com',
                            subject: 'Richiesta Pagamento codice' ,
                            text: 'Inserire Testo Email'
                        }
                    }).then(res => {
                        Toast.show('Richiesta Inviata Correttamente');
                    })
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
        console.log(scaricoQrCode?.data.filter((x: any) => !x.isPagato).length);



    };
    // const zoomQrCode = (qrCodeToken: any) => (
    //     <Modal {...modalProps} footer={false} >
    //         <div style={{
    //             color: '#ffffff',
    //             textAlign: 'center',
    //             marginTop: 3,
    //             fontSize: 11
    //         }}>
    //             <QRCode id="qr-gen"
    //                     value={qrCodeToken}
    //                     size={160}
    //                     level={"H"}
    //                     includeMargin={true} />
    //         </div>
    //     </Modal>
    // );

    return (
        <>
            <div>
                <Row justify="center" align="top">
                    <Col>
                        <Title level={4} style={{
                            color: '#8E201BFF',
                            marginTop: '30px'
                        }}>Lista Movimenti</Title>
                    </Col>
                </Row>
                <Row justify="center" align="top" style={{marginTop: '-10px'}}>
                    <Col span={6} hidden={tipologia !== 'bar'}>
                        <Button shape={'rounded'} style={{
                            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                            border: (tipo === 'acquistato' || tipo === 'erogati') ? '3px #ffe35e solid' : 'none',
                            fontSize: '20px',
                            color: '#ffffff',
                            background: '#800100FF',
                            width: '50px',
                            height: '50px',
                            marginTop: '30px'
                        }}
                                onClick={() => navigate('/movimenti', { state: tipologia === 'bar' ? 'erogati' : 'acquistato'})}
                        >
                            <BarsOutlined />
                        </Button>
                        <p style={{color: '#FFFFFF'}}>{tipologia === 'bar' ? 'Erogati' : 'Acquistati'}</p>
                    </Col>
                    <Col span={6} hidden={tipologia !== 'bar'}>
                        <Button shape={'rounded'} style={{
                            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                            border: (tipo === 'acquistato' || tipo === 'riscuotere') ? '3px #ffe35e solid' : 'none',
                            fontSize: '20px',
                            color: '#ffffff',
                            background: '#e28a74',
                            width: '50px',
                            height: '50px',
                            marginTop: '30px'
                        }}
                                onClick={() => navigate('/movimenti', { state: tipologia === 'bar' ? 'riscuotere' : 'acquistato'})}
                        >
                            <Icons.DollarOutlined />
                        </Button>
                        <p style={{color: '#FFFFFF'}}>{tipologia === 'bar' ? 'Fatturati' : 'Acquistati'}</p>
                    </Col>
                    <Col span={6} hidden={tipologia !== 'bar'}>
                        <Button shape={'rounded'} style={{
                            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                            border: (tipo === 'ricevuto' || tipo === 'riscosso') ? '2px #ffe35e solid' : 'none',
                            fontSize: '20px',
                            color: '#FFFFFF',
                            background: '#C64141FF',
                            width: '50px',
                            height: '50px',
                            marginTop: '30px'
                        }}
                                onClick={() => navigate('/movimenti', { state: tipologia === 'bar' ? 'riscosso' : 'ricevuto'})}
                        >
                            <Icons.BankOutlined />
                        </Button>
                        <p style={{color: '#FFFFFF'}}>{tipologia === 'bar' ? 'Riscossi' : 'Ricevuti'}</p>
                    </Col>
                    <Col span={6} hidden={tipologia === 'bar'}>
                        <Button shape={'rounded'} style={{
                            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                            border: tipo === '' ? '2px #ffe35e solid' : 'none',
                            fontSize: '20px',
                            color: '#800100',
                            background: '#ffffff',
                            width: '50px',
                            height: '50px',
                            marginTop: '30px'
                        }}
                                onClick={() => navigate('/movimenti', { state: ''})}
                        >
                            <FontAwesomeIcon icon={solid('list-check')} />
                        </Button>
                        <p style={{color: '#FFFFFF'}}>Tutti</p>
                    </Col>
                    <Col span={6} hidden={tipologia === 'bar'}>
                        <Button shape={'rounded'} style={{
                            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                            border: tipo === 'acquistato' ? '2px #ffe35e solid' : 'none',
                            fontSize: '20px',
                            color: '#800100',
                            background: '#ffffff',
                            width: '50px',
                            height: '50px',
                            marginTop: '30px'
                        }}
                                onClick={() => navigate('/movimenti', { state: 'acquistato'})}
                        >
                            <FontAwesomeIcon icon={solid('money-bill-wave')} />
                        </Button>
                        <p style={{color: '#FFFFFF'}}>Acquistati</p>
                    </Col>
                    <Col span={6} hidden={tipologia === 'bar'}>
                        <Button shape={'rounded'} style={{
                            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                            border: tipo === 'ricevuto' ? '2px #ffe35e solid' : 'none',
                            fontSize: '20px',
                            color: '#800100',
                            background: '#ffffff',
                            width: '50px',
                            height: '50px',
                            marginTop: '30px'
                        }}
                                onClick={() => navigate('/movimenti', { state: 'ricevuto'})}
                        >
                            <FontAwesomeIcon icon={solid('mail-bulk')} />
                        </Button>
                        <p style={{color: '#FFFFFF'}}>Ricevuti</p>
                    </Col>
                    <Col span={6} hidden={tipologia === 'bar'}>
                        <Button shape={'rounded'} style={{
                            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                            border: tipo === 'inviato' ? '2px #ffe35e solid' : 'none',
                            fontSize: '20px',
                            color: '#800100',
                            background: '#ffffff',
                            width: '50px',
                            height: '50px',
                            marginTop: '30px'
                        }}
                                onClick={() => navigate('/movimenti', { state: 'inviato'})}
                        >
                            <FontAwesomeIcon icon={solid('paper-plane')} />
                        </Button>
                        <p style={{color: '#FFFFFF'}}>Inviati</p>
                    </Col>
                </Row>
                <Row justify="center" align="middle" style={{marginTop: '-10px'}} hidden={tipologia !== 'bar'}>
                    <Col span={12}>
                        <Title level={5} style={{color: '#c64140'}}>{(tipo === 'erogati' && scaricoQrCode && scaricoQrCode.data && scaricoQrCode.data.length > 0)
                            ? 'Qta: ' + scaricoQrCode?.data.length
                            + ' Totale: ' + Number(scaricoQrCode?.data.reduce((accumulator: any, object: any) => {return accumulator + object['price'];}, 0)).toFixed(2) + '€'
                            : 'Qta: ' + scaricoQrCode?.data.length
                            + ' Totale: ' + Number(scaricoQrCode?.data.reduce((accumulator: any, object: any) => {return accumulator + object['price'];}, 0)).toFixed(2) + '€'}
                        </Title>
                    </Col>
                    <Col span={12} hidden={tipo === 'riscuotere' || tipo === 'riscosso'}>
                        <Button shape={'rounded'} style={{
                            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                            border: (tipo === 'ricevuto' || tipo === 'erogati') ? '2px #ffe35e solid' : 'none',
                            fontSize: '14px',
                            color: '#000000',
                            background: '#fcc422',
                            marginBottom: 10
                        }}
                                onClick={showPagamento}
                        >
                            <u>Richiedi Pagamento</u>
                        </Button>
                    </Col>
                </Row>
            </div>
            <div style={{
                overflowY: 'auto',
                height: window.innerHeight - 200
            }}>
                <Row justify="center" align="middle" style={{marginTop: '10px'}}>
                    {/*<Col span={32} hidden={tipo === 'usato'}>*/}
                    {/*    {resultMovimentazioni && resultMovimentazioni?.data?.data.map( (pro: any, i) => (*/}
                    {/*        <Card key={pro.id}*/}
                    {/*              bordered={false}*/}
                    {/*              style={{*/}
                    {/*                  alignItems: 'flex-start',*/}
                    {/*                  alignContent: 'flex-start',*/}
                    {/*                  textAlign: 'left',*/}
                    {/*                  width: 300,*/}
                    {/*                  marginTop: 10,*/}
                    {/*                  borderRadius: 20,*/}
                    {/*                  border: 'none',*/}
                    {/*                  boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',*/}
                    {/*                  background: '#e28a74' }}*/}
                    {/*              onClick={() => (pro.user_gift.quantita > 0  ? navigate('/use', { state: pro.user_gift.tipo === 'acquistato' ? 'acquistato' : 'ricevuto'}) : navigate('/movimenti', { state: pro.user_gift.tipo === 'acquistato' ? 'acquistato' : 'ricevuto'}))}*/}
                    {/*        >*/}
                    {/*            /!*<Image src={apiImage + '' + pro?.prodotti?.foto?.url} style={{width: 60, borderRadius: 10}} />*!/*/}
                    {/*            <div><span style={{color: '#000000', fontWeight: 800}}>{(pro.user_gift.tipo === 'acquistato' ? 'Acquisto: ' : 'Ricevuto: ')}</span> <span style={{color: '#93292a', fontWeight: 400}}>{pro.user_gift.prodotti?.descrizione} - Qta: {pro.user_gift.quantita + pro.user_gift.quantitaUsata}</span></div>*/}
                    {/*            <div><span style={{color: '#000000', fontWeight: 800}}>Data: </span> <span style={{color: '#FFFFFF', fontWeight: 400}}>{moment(pro.createdAt).format('DD-MM-YYYY')} {tipo === 'ricevuto' ? '' : '- Prezzo: ' + pro.user_gift.prodotti.prezzo.toFixed(2)+ '€'}</span></div>*/}
                    {/*            <div hidden={(pro.user_gift.quantitaUsata === 0)}><span style={{color: '#000000', fontWeight: 800}}>{pro.qrCodeToken ? 'Tipo: ' : 'Friend : '}</span> <span style={{color: '#93292a', fontWeight: 400}}>{((pro?.toUser) ? pro?.toUser?.nome + ' ' + pro.toUser?.cognome : pro.fromUser ? pro.fromUser?.nome + ' ' + pro.fromUser?.cognome : pro.qrCodeToken ? 'Generato QrCode' : 'Inviato Link')}</span></div>*/}
                    {/*            <div style={{*/}
                    {/*                color: '#c64140',*/}
                    {/*                textAlign: 'right',*/}
                    {/*                marginTop: 3,*/}
                    {/*            }}>Rimanenza: {pro.user_gift.quantita} Usato: {(pro.user_gift.quantitaUsata ? pro.user_gift.quantitaUsata : 0)}</div>*/}
                    {/*        </Card>*/}
                    {/*    ))}*/}
                    {/*</Col>*/}
                    {/*<Col span={32} hidden={tipo === 'acquistato' || tipo === 'ricevuto'}>*/}
                    {/*    {resultMovimentazioni && resultMovimentazioni.data?.data?.map( (pro: any, i) => (*/}
                    {/*        <Card key={pro.id}*/}
                    {/*              bordered={false}*/}
                    {/*              style={{*/}
                    {/*                  alignItems: 'flex-start',*/}
                    {/*                  alignContent: 'flex-start',*/}
                    {/*                  textAlign: 'left',*/}
                    {/*                  width: 300,*/}
                    {/*                  marginTop: 10,*/}
                    {/*                  borderRadius: 20,*/}
                    {/*                  border: 'none',*/}
                    {/*                  boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',*/}
                    {/*                  background: '#e28a74' }}*/}
                    {/*        >*/}
                    {/*            /!*<Image src={apiImage + '' + pro?.prodotti?.foto?.url} style={{width: 60, borderRadius: 10}} />*!/*/}
                    {/*            <div><span style={{color: '#000000', fontWeight: 800}}>{(pro.isUsed ? 'Consumato: ' : 'Da Consumare: ')}</span> <span style={{color: '#93292a', fontWeight: 400}}>{pro.user_gift?.descrizione}</span></div>*/}
                    {/*            <div style={{*/}
                    {/*                color: '#ffffff',*/}
                    {/*                textAlign: 'center',*/}
                    {/*                marginTop: 3,*/}
                    {/*                fontSize: 11*/}
                    {/*            }}>*/}
                    {/*                <QRCode id="qr-gen"*/}
                    {/*                        value={pro.qrCodeToken}*/}
                    {/*                        size={60}*/}
                    {/*                        level={"H"}*/}
                    {/*                        includeMargin={true} />*/}
                    {/*            </div>*/}
                    {/*            <div style={{ textAlign: 'center'}}><span style={{color: '#000000', fontWeight: 800}}>Data: </span> <span style={{color: '#FFFFFF', fontWeight: 400}}>{moment(pro.createdAt).format('DD-MM-YYYY')}</span></div>*/}
                    {/*        </Card>*/}
                    {/*    ))}*/}
                    {/*</Col>*/}
                    <Col span={32} hidden={tipo === 'acquistato' || tipo === 'ricevuto' || tipo === 'usato' || tipo === 'riscuotere' || tipo === 'riscosso'}>
                        {scaricoQrCode && scaricoQrCode.data?.map( (pro: any, i) => (
                            <Card key={pro.id}
                                  bordered={false}
                                  style={{
                                      alignItems: 'flex-start',
                                      alignContent: 'flex-start',
                                      textAlign: 'left',
                                      width: 330,
                                      marginTop: 10,
                                      borderRadius: 20,
                                      border: 'none',
                                      boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                      background: '#e28a74'
                                  }}
                            >
                                {/*<Image src={apiImage + '' + pro?.prodotti?.foto?.url} style={{width: 60, borderRadius: 10}} />*/}
                                <div><span style={{color: '#000000', fontWeight: 800}}>Erogato: <span style={{
                                    color: '#000000',
                                    fontWeight: 400
                                }}>{pro.user_movimentazioni.user_gift.prodotti?.descrizione}</span>{' - ' + pro.price.toFixed(2) + ' €'}</span>
                                </div>
                                <div><span style={{color: '#000000', fontWeight: 800}}>Data:  </span> <span style={{
                                    color: '#FFFFFF',
                                    fontWeight: 400
                                }}> {moment(pro.createdAt).format('DD-MM-YYYY HH:mm')} </span></div>
                                <div><span style={{color: '#000000', fontWeight: 800}}>Code:  </span> <span style={{
                                    color: '#FFFFFF',
                                    fontWeight: 400,
                                    fontSize: 12
                                }}> {pro.user_movimentazioni.qrCodeToken} </span></div>
                                <div style={{
                                    color: '#c64140',
                                    textAlign: 'right',
                                    marginTop: 3,
                                }}></div>
                            </Card>
                        ))}
                    </Col>
                    <Col span={32}
                         hidden={tipo === 'acquistato' || tipo === 'ricevuto' || tipo === 'usato' || tipo === 'erogati'}>
                        {scaricoQrCode && scaricoQrCode.data?.filter((f: any) => f.isPagato).map( (pro: any, i) => (
                            <Card key={pro.id}
                                  bordered={false}
                                  style={{
                                      alignItems: 'flex-start',
                                      alignContent: 'flex-start',
                                      textAlign: 'left',
                                      width: 330,
                                      marginTop: 10,
                                      borderRadius: 20,
                                      border: 'none',
                                      boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                      background: '#62c641'
                                  }}
                            >
                                {/*<Image src={apiImage + '' + pro?.prodotti?.foto?.url} style={{width: 60, borderRadius: 10}} />*/}
                                <div><span style={{color: '#000000', fontWeight: 800}}>{tipo === 'riscuotere' ? 'Fatturato': 'Riscosso'} <span style={{
                                    color: '#000000',
                                    fontWeight: 400
                                }}>{pro.user_movimentazioni.user_gift.prodotti?.descrizione}</span>{' - ' + pro.price.toFixed(2) + ' €'}</span>
                                </div>
                                <div><span style={{color: '#000000', fontWeight: 800}}>Data:  </span> <span style={{
                                    color: '#FFFFFF',
                                    fontWeight: 400
                                }}> {moment(pro.updateAt).format('DD-MM-YYYY HH:mm')} </span></div>
                                <div><span style={{color: '#000000', fontWeight: 800}}>Code:  </span> <span style={{
                                    color: '#FFFFFF',
                                    fontWeight: 400,
                                    fontSize: 12
                                }}> {pro.user_movimentazioni.qrCodeToken} </span></div>
                                <div style={{
                                    color: '#c64140',
                                    textAlign: 'right',
                                    marginTop: 3,
                                }}></div>
                            </Card>
                        ))}
                    </Col>
                    {/*<Col span={32} hidden={tipo === 'acquistato' || tipo === 'ricevuto' || tipo === 'usato' || tipo === 'erogati'}>*/}
                    {/*    {riscuotereQrCode && riscuotereQrCode.data?.map( (pro: any, i) => (*/}
                    {/*        <Card key={pro.id}*/}
                    {/*              bordered={false}*/}
                    {/*              style={{*/}
                    {/*                  alignItems: 'flex-start',*/}
                    {/*                  alignContent: 'flex-start',*/}
                    {/*                  textAlign: 'left',*/}
                    {/*                  width: 300,*/}
                    {/*                  marginTop: 10,*/}
                    {/*                  borderRadius: 20,*/}
                    {/*                  border: 'none',*/}
                    {/*                  boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',*/}
                    {/*                  background: '#e28a74' }}*/}
                    {/*        >*/}
                    {/*            /!*<Image src={apiImage + '' + pro?.prodotti?.foto?.url} style={{width: 60, borderRadius: 10}} />*!/*/}
                    {/*            <div><span style={{color: '#000000', fontWeight: 800}}><span style={{color: '#000000', fontWeight: 400}}>{pro.descrizione}</span> - Qta: 1</span></div>*/}
                    {/*            <div><span style={{color: '#000000', fontWeight: 800}}>Data:  </span> <span style={{color: '#FFFFFF', fontWeight: 400}}> {moment(pro.createdAt).format('DD-MM-YYYY')} {'- Prezzo: ' + pro.importo.toFixed(2)+ ' €'}</span></div>*/}
                    {/*            <div style={{*/}
                    {/*                color: '#c64140',*/}
                    {/*                textAlign: 'right',*/}
                    {/*                marginTop: 3,*/}
                    {/*            }}></div>*/}
                    {/*        </Card>*/}
                    {/*    ))}*/}
                    {/*</Col>*/}
                    <Col span={32}>
                        {data && data.data?.map( (pro: any, i) => (
                            <Card key={pro.id}
                                  bordered={false}
                                  style={{
                                      alignItems: 'flex-start',
                                      alignContent: 'flex-start',
                                      textAlign: 'left',
                                      width: 300,
                                      marginTop: 10,
                                      borderRadius: 20,
                                      border: 'none',
                                      boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                      background: pro.tipo === 'acquistato' ? '#e28a74' : '#b66868' }}
                            >
                                {/*<Image src={apiImage + '' + pro?.prodotti?.foto?.url} style={{width: 60, borderRadius: 10}} />*/}
                                <div>
                                <span style={{color: '#000000', fontWeight: 800}}>
                                <span style={{color: '#000000', fontWeight: 400}}>{pro.descrizione}</span><span>{pro.tipo === 'ricevuto' ? ' da: ' : ''}</span> <span style={{color: '#93292a', fontWeight: 400}}>{((pro?.tipo === 'ricevuto') ? pro?.fromUser?.nome + ' ' + pro.fromUser?.cognome : '')}</span>{pro.tipo === 'acquistato' ?  '- Qta: ' + (pro.quantita + (pro.quantitaUsata ? pro.quantitaUsata : 0)) : ''}</span>
                                </div>
                                <div><span style={{color: '#000000', fontWeight: 800}}>Data:  </span>
                                    <span style={{color: '#FFFFFF', fontWeight: 400}}> {moment(pro.createdAt).format('DD-MM-YYYY HH:mm')} </span><span style={{color: '#f1c85f', fontWeight: 800}}>{(pro.tipo === 'acquistato' && !pro.isOmaggio) ? ' - Tot: ' + (pro.quantita + pro.quantitaUsata) * pro.prodotti?.prezzo.toFixed(2)+ ' €' : ''}</span>
                                </div>
                                <div style={{
                                    color: '#c64140',
                                    textAlign: 'right',
                                    marginTop: 3,
                                }}></div>
                            </Card>
                        ))}
                    </Col>
                    <Col span={32}>
                        {resultMovimentazioni && resultMovimentazioni.data?.data.map( (pro: any, i) => (
                            <Card key={pro.id}
                                  bordered={false}
                                  style={{
                                      alignItems: 'flex-start',
                                      alignContent: 'flex-start',
                                      textAlign: 'left',
                                      width: 300,
                                      marginTop: 10,
                                      borderRadius: 20,
                                      border: 'none',
                                      boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                      background: pro.tipo === 'generato' ? '#93292a' : '#b66868' }}
                                  onClick={() => pro.qrCodeToken ? showConfirm(pro.qrCodeToken) : null}
                            >
                                {/*<Image src={apiImage + '' + pro?.prodotti?.foto?.url} style={{width: 60, borderRadius: 10}} />*/}
                                <div>
                                {/*<span style={{color: '#000000', fontWeight: 800}}>*/}
                                    <span style={{color: '#000000', fontWeight: 400}}>{pro.descrizione}</span>
                                    <span>{pro.tipo === 'ricevuto' ? ' da: ' : ''}</span>
                                    <span style={{color: '#93292a', fontWeight: 400}}>{((pro?.tipo === 'ricevuto') ? pro?.fromUser?.nome + ' ' + pro.fromUser?.cognome : (pro?.toUser ? pro?.toUser?.nome + ' ' + pro.toUser?.cognome : ' Tramite Link'))}</span>
                                {/*</span>*/}
                                </div>
                                <div>
                                    <span style={{color: '#000000', fontWeight: 800}}>Data:  </span>
                                    <span style={{color: '#FFFFFF', fontWeight: 400}}> {moment(pro.createdAt).format('DD-MM-YYYY HH:mm')} </span><span style={{color: '#f1c85f', fontWeight: 800}}>{(pro.tipo === 'acquistato') ? ' - Tot: ' + (pro.quantita + pro.quantitaUsata) * pro.prodotti?.prezzo.toFixed(2)+ ' €' : pro.isUsed ? '' : ''}</span>
                                    <span style={{color: '#f1c85f', fontWeight: 400}}>{(pro.tipo === 'generato' ? pro.isUsed ? ' - Consumato' : ' - Da Consumare' : '')}</span>
                                </div>
                                <div style={{
                                    color: '#c64140',
                                    textAlign: 'center',
                                    marginTop: 3,
                                }} ></div>

                            </Card>

                        ))}
                    </Col>
                </Row>
            </div>

        </>
    );
};
