export const UserIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle cx="16" cy="16" r="16" fill="#67BE23" />
        <path
            d="M21.8482 11.9933C21.5097 11.2127 21.0217 10.506 20.4116 9.91302C19.7996 9.31466 19.0781 8.83975 18.2864 8.51422C17.463 8.1726 16.5892 8 15.6884 8C14.7876 8 13.9138 8.1726 13.0903 8.51242C12.2939 8.84144 11.5801 9.31251 10.9652 9.91123C10.3555 10.5046 9.86754 11.2112 9.5286 11.9915C9.178 12.8005 9 13.66 9 14.5446C9 15.8139 9.30385 17.0779 9.90078 18.2969C10.3808 19.2768 11.0515 20.2315 11.8965 21.1395C13.3403 22.6893 14.8541 23.6368 15.2839 23.8903C15.4058 23.9624 15.5449 24.0002 15.6866 24C15.8268 24 15.9653 23.964 16.0893 23.8903C16.519 23.6368 18.0329 22.6893 19.4767 21.1395C20.3217 20.2333 20.9924 19.2768 21.4724 18.2969C22.0729 17.0797 22.3768 15.8175 22.3768 14.5464C22.3768 13.6618 22.1988 12.8023 21.8482 11.9933ZM15.6884 22.621C14.5035 21.8676 10.2945 18.8848 10.2945 14.5464C10.2945 13.1457 10.8537 11.8296 11.8695 10.8372C12.889 9.8429 14.2446 9.29453 15.6884 9.29453C17.1321 9.29453 18.4878 9.8429 19.5072 10.839C20.5231 11.8296 21.0823 13.1457 21.0823 14.5464C21.0823 18.8848 16.8732 21.8676 15.6884 22.621ZM15.6884 11.524C13.9408 11.524 12.524 12.9408 12.524 14.6884C12.524 16.436 13.9408 17.8528 15.6884 17.8528C17.436 17.8528 18.8528 16.436 18.8528 14.6884C18.8528 12.9408 17.436 11.524 15.6884 11.524ZM17.1124 16.1124C16.9256 16.2997 16.7036 16.4482 16.4593 16.5494C16.2149 16.6506 15.9529 16.7025 15.6884 16.7021C15.1508 16.7021 14.6456 16.4917 14.2644 16.1124C14.0771 15.9256 13.9286 15.7036 13.8274 15.4593C13.7262 15.2149 13.6743 14.9529 13.6747 14.6884C13.6747 14.1508 13.885 13.6456 14.2644 13.2644C14.6456 12.8832 15.1508 12.6747 15.6884 12.6747C16.226 12.6747 16.7312 12.8832 17.1124 13.2644C17.4935 13.6456 17.7021 14.1508 17.7021 14.6884C17.7021 15.226 17.4935 15.7312 17.1124 16.1124Z"
            fill="white"
        />
    </svg>
);
