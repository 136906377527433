import {
    IResourceComponentsProps,
} from '@pankod/refine-core';
import {
    Typography,
    Card,
    Row,
    Col,
    Button, Image
} from '@pankod/refine-antd';
import {LinkOutlined, MailOutlined} from '@ant-design/icons';
import {useLocation, useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {API_URL, SITE_URL} from '../../constants';

const { Title } = Typography;
type LocationState = { token: string; id: number, image: any, message: string, friend: any };

export const InviatoList: React.FC<IResourceComponentsProps> = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [link, setLink] = useState('');
    const {token} = location.state as LocationState;
    const {id} = location.state as LocationState;
    const {image} = location.state as LocationState;
    const {message} = location.state as LocationState;
    const {friend} = location.state as LocationState;


    const copy = async () => {
        await navigator.clipboard.writeText(link);
        alert('Link copiato!');
    }

    useEffect(() => {
        setLink(SITE_URL + '/landing?token=' + token + '&id=' + id);
        console.log(image)
        console.log(message)
        console.log(friend['nome'])
    })

    return (
        <>
            <Row justify={'center'} align={'top'} style={{marginTop: 40, justifyContent: 'center'}}>
                <Col span={24} style={{ padding: '20px' }}>
                    <Card
                        style={{
                            textAlign: 'center',
                            border: 'none',
                            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                            borderRadius: '40px',
                            height: 280,
                            width: '100%',
                            background: '#e28a74',
                            color: '#FFFFFF' }}>
                        <Row gutter={[16, 32]} style={{marginBottom: '10px'}}>
                            <Col span={24}>
                                <Title level={5} style={{color: '#FFFFFF'}}>{message}</Title>
                            </Col>
                        </Row>
                        <Row gutter={[16, 32]} style={{marginBottom: '10px'}}>
                            <Col span={24}>
                                <Image hidden={image.length === 0}
                                       style={{
                                           borderRadius: 20,
                                           position: 'relative',
                                           marginTop: 10,

                                           width: '70%'
                                       }}
                                       src={API_URL + image.url}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row justify={'center'} align={'top'} style={{marginTop: -38, textAlign: 'center'}}>
                <Col span={24}>
                    <Button style={{
                        position: 'absolute',
                        width: 80,
                        height: 80,
                        border: '2px solid #FFFFFF',
                        borderRadius: '50%',
                        background: '#e28a74',
                        zIndex: '99999',
                        padding: 5,
                        marginLeft: '-10%',
                        marginTop: -25
                    }} type="primary" shape="circle" icon={<MailOutlined style={{
                        fontSize: 35,
                        fontWeight: 800
                    }} />} />
                </Col>
            </Row>

            <Row justify={'center'} align={'top'} style={{marginTop: 1, justifyContent: 'center'}}>
                <Col span={24} style={{ padding: '20px' }}>
                    <Card
                        style={{
                            textAlign: 'center',
                            border: 'none',
                            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                            borderRadius: '40px',
                            height: 280,
                            width: '100%',
                            background: '#e28a74',
                            color: '#FFFFFF' }}>
                        <Row gutter={[16, 32]} style={{marginBottom: '30px', justifyContent: 'center'}}>
                            <Col span={22}>
                                <Title level={3} style={{color: '#800000', marginTop: 25}}>{token ? 'Ecco il tuo link!' : 'Complimenti!'}</Title>
                                <Title level={5} style={{color: '#FFFFFF'}}>{token ? 'Copia il link attraverso il pulsante apposito e invita i tuoi contatti a far parte di Sugar!' : 'Hai inviato correttamente la tua Gift a ' + friend['nome'] + ' ' + friend['cognome']}</Title>
                                <Title level={5} style={{
                                    color: '#FFFFFF',
                                    fontSize: 10,
                                }}>{token ? SITE_URL + '/landing?token=' + token + '&id=' + id : ''}
                                </Title>
                                <Button type="primary" htmlType={'button'} hidden={!token}
                                        style={{
                                            background: '#ffeaea',
                                            borderRadius: '30px',
                                            width: '120px',
                                            height: '30px',
                                            border: 'none',
                                            marginTop: 15,
                                            color: '#000000'
                                        }}
                                        onClick={() => copy()}
                                >Copia Link
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row justify={'center'} align={'top'} style={{marginTop: 1, justifyContent: 'center'}}>
                <Col span={24} style={{ padding: '1px' }}>
                    <Button type="primary" htmlType={'submit'}
                            style={{background: '#93292a', borderRadius: '30px', width: '150px', height: '40px', border: 'none'}} onClick={() => navigate('/home')}
                    >Torna alla Home</Button>
                </Col>
            </Row>

        </>
    );
};
