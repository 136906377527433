import React from "react";
import { NotificationProvider } from "@pankod/refine-core";
import { UndoableNotification } from "undoableNotification";
import { Button, Space, Toast } from 'antd-mobile'

export const notificationProvider: NotificationProvider = {
    open: ({ key, message, type, undoableTimeout, cancelMutation }) => {
        console.log('KEY:', key, 'MESSAGE: ', message, 'TYPE: ', type, undoableTimeout, cancelMutation)
        if (type === "progress") {

        } else {
            if (false) {
                // toast.update(key as React.ReactText, {
                //     render: message,
                //     closeButton: true,
                //     autoClose: 5000,
                //     type
                // });
            } else {
                if (key === 'login-error') {
                    Toast.show({
                        content: 'Username e/o Password errati',
                        position: 'top'
                    });
                } else {
                    Toast.show({
                        content: message,
                        position: 'top'
                    });
                }
            }
        }
    },
    close: (key) => Toast.clear()
};
