import React from 'react';
import {
    Row,
    Col,
    AntdLayout,
    Typography,
    Button,
    Image,
} from "@pankod/refine-antd";

import {useLocation, useNavigate} from 'react-router-dom';
import {Trans} from 'react-i18next';

const { Text, Title } = Typography;

export const SuccessPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const tokenGift = decodeURIComponent(location.search)?.split('?')[1]?.split('&')[0].split('=')[1];
    const id = decodeURIComponent(location.search)?.split('?')[1]?.split('&')[1]?.split('=')[1];
    return (
        <>
                <Row>
                    <Col style={{textAlign: 'center'}}>
                        <Image
                            style={{ marginBottom: 26, marginTop: 10}}
                            src="/images/logo_header.png"
                            width="50%"
                        />
                    <Title level={2} style={{textAlign: 'center'}}>
                        <Trans
                            i18nKey="pages.register.message"
                            defaults="<0>Benvenuto!</0>"
                            components={[<Text key="0" style={{color: '#010300'}}/>]}
                        />
                    </Title>
                    <Title level={3} style={{textAlign: 'center'}}>
                        <Trans
                            i18nKey="pages.register.message"
                            defaults="<0>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five cen</0>"
                            components={[<Text key="0" style={{fontSize: '10px', color: '#010300', width: '100px'}}/>]}
                        />
                    </Title>
                    <Title level={2} style={{textAlign: 'center'}}>
                        <Trans
                            i18nKey="pages.register.message"
                            defaults="<0>Scopri come iniziare!</0>"
                            components={[<Text key="0" style={{color: '#010300'}}/>]}
                        />
                    </Title>
                </Col>
            </Row>
            <Row justify={'center'} align={'middle'} style={{marginTop: '50px'}} >
                <Col span={24} style={{textAlign: 'center'}}>
                    <Row justify={'center'} align={'middle'} gutter={[24, 48]}>
                        <Col span={8}>
                            <Button shape={'circle'} style={{
                                border: 'none',
                                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                fontSize: '20px',
                                color: '#FFFFFF',
                                background: '#C64141FF',
                                width: '60px',
                                height: '60px',
                            }}>
                                1
                            </Button>
                        </Col>
                        <Col span={8}>
                            <Button shape={'circle'} style={{
                                border: 'none',
                                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                fontSize: '20px',
                                color: '#FFFFFF',
                                background: '#C64141FF',
                                width: '60px',
                                height: '60px',
                            }}>
                                2
                            </Button>
                        </Col>
                        <Col span={8}>
                            <Button shape={'circle'} style={{
                                border: 'none',
                                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                fontSize: '20px',
                                color: '#FFFFFF',
                                background: '#C64141FF',
                                width: '60px',
                                height: '60px',
                            }}>
                                3
                            </Button>
                        </Col>
                    </Row>
                    <Row justify={'center'} align={'middle'} style={{marginTop: '30px'}} gutter={[24, 48]}>
                        <Col span={32}>
                            <Title level={4}>Infografica</Title>
                        </Col>
                    </Row>
                    <Row justify={'center'} align={'middle'} style={{marginTop: '30px'}} gutter={[24, 48]}>
                        <Col span={8}>
                            <Button shape={'circle'} style={{
                                border: 'none',
                                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                fontSize: '20px',
                                color: '#FFFFFF',
                                background: '#f28f8f',
                                width: '60px',
                                height: '60px',
                            }}>
                                1
                            </Button>
                        </Col>
                        <Col span={8}>
                            <Button shape={'circle'} style={{
                                border: 'none',
                                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                fontSize: '20px',
                                color: '#FFFFFF',
                                background: '#f28f8f',
                                width: '60px',
                                height: '60px',
                            }}>
                                2
                            </Button>
                        </Col>
                        <Col span={8}>
                            <Button shape={'circle'} style={{
                                border: 'none',
                                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                fontSize: '20px',
                                color: '#FFFFFF',
                                background: '#f28f8f',
                                width: '60px',
                                height: '60px',
                            }}>
                                3
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row justify={'center'} align={'top'} style={{textAlign: 'center', marginTop: '70px'}}>
                <Col span={32}>
                    <Button style={{boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)', background: '#ffffff', color: '#000000', borderRadius: '30px', width: '140px', height: '35px', border: 'none'}} onClick={() => navigate('/login' + ((location.search) ? '?token=' + tokenGift + '&id=' + id : ''))}>Procedi</Button>
                </Col>
            </Row>
            </>
    );
};
