import {Card, Col, Image, Row, Skeleton, Typography, useSelect, Button} from '@pankod/refine-antd';
import React, {useEffect, useState} from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import {IScaricoQrCode, IUserCrediti, IUserGift, IUserMovimentazioni} from '../../interfaces';
import jwt_decode from 'jwt-decode';
import {API_URL, TOKEN_KEY} from '../../constants';
import { useHtml5QrCodeScanner, useAvailableDevices, useHtml5QrCode } from 'react-html5-qrcode-reader';
import {useCreate, useList, useOne, useUpdate} from '@pankod/refine-core';
import {Toast} from 'antd-mobile';
import {apiFunction} from '../../apiFuntion';
import {sleep} from 'antd-mobile/es/utils/sleep';
import moment from 'moment';
import {Spin} from 'antd';
const html5QrCodeScannerFile = process.env.PUBLIC_URL + '/html5-qrcode.min.js'; // <-- this file is in /public.
const { Title } = Typography;
const { Meta } = Card;

export const QrCode: React.FC = () => {
    const { Html5Qrcode } = useHtml5QrCode(
        html5QrCodeScannerFile
    );
    // @ts-ignore
    const decoded: any = localStorage.getItem(TOKEN_KEY) ? jwt_decode(localStorage.getItem(TOKEN_KEY)) : {id: 0};
    const apiImage = API_URL;
    const navigate = useNavigate();
    const location = useLocation();
    const [qrCode, setQrCode] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [idGift, setIdGift] = useState<number>(0);
    const scaricoQrCode = useCreate<IScaricoQrCode>();
    const updateMovimentazioni = useUpdate<IUserMovimentazioni>();
    const {mutateAsync: userGift, isSuccess: giftIsSuccess, isLoading: giftIsLoading, isError: giftIsError} = useUpdate<IUserGift>();
    const crediti = useCreate<IUserCrediti>();
    const { queryResult } = useSelect<IUserCrediti>({
        resource: "user-creditis",
        metaData: {
            populate: "user",
        },
        filters: [
            {
                field: "user][id]",
                operator: "eq",
                value: decoded?.id
            }
        ]
    });

    const {data, isSuccess, isLoading, refetch} = useList<IUserMovimentazioni>({
        resource: 'user-movimentazionis',
        metaData: {
            populate: "user_gift, user_gift.prodotti, user_gift.prodotti.foto, users",
        },
        queryOptions: {
            enabled: false
        },
        config: {
            filters: [
                {
                    field: "qrCodeToken",
                    operator: "eq",
                    value: qrCode
                },
                {
                    field: "isUsed",
                    operator: "eq",
                    value: false
                },
            ]
        },
    });

    const userQtaGift = useOne<IUserGift>({
        resource: 'user-gifts',
        queryOptions: {
            enabled: false
        },
        id: idGift
    });

    const config = { fps: 10, qrbox: { width: 200, height: 200 } };

    useEffect(() => {
        // console.log(qrCode)
        // refetch();
        // if (!isLoading && qrCode) {
        //     if (data?.data.length === 0) {
        //         Toast.show('Qr Code non valido e/o già consumato');
        //     }
        // }
    }, [qrCode])

    function leggoQrCode(code: any) {
        console.log(code);
        setQrCode(code);
        setTimeout(() => {
            refetch().then( (res: any) => {
                console.log(res);
                if (!res.isLoading) {
                    if (res.data?.data.length === 0) {
                        Toast.show('Qr Code non valido e/o già consumato');
                        // setQrCode(undefined)
                    } else {
                        // setQrCode(undefined)
                        // Toast.show('QrCode Scaricato Correttamente');
                        setLoading(true);
                        setTimeout(() => {
                            setTimeout(() => {
                                setIdGift(res.data?.data[0].user_gift.id);
                                setTimeout(async () => {
                                    await scaricoQrCode.mutateAsync({
                                        resource: "scarico-qr-codes",
                                        successNotification: false,
                                        values: {
                                            descrizione: "Consumo Gift",
                                            dataScarico: new Date(),
                                            user_movimentazioni: res.data?.data[0].id,
                                            price: res.data?.data[0].user_gift.prodotti.prezzo,
                                            users: decoded.id
                                        }
                                    });
                                    if (scaricoQrCode.isError) throw 'Errore Scarico Qr Code';
                                    await updateMovimentazioni.mutateAsync({
                                        resource: "user-movimentazionis",
                                        successNotification: false,
                                        id: Number(res.data?.data[0].id),
                                        values: {
                                            isUsed: true,
                                        }
                                    });
                                    if (updateMovimentazioni.isError) throw 'Errore Update Movimentazioni';
                                    crediti.mutateAsync({
                                        resource: "user-creditis",
                                        successNotification: false,
                                        values: {
                                            descrizione: "Erogato Caffè da QrCode",
                                            user: decoded.id,
                                            importo: res.data?.data[0].user_gift.prodotti.prezzo,
                                            qrCodeToken: qrCode,
                                            isRefund: false,
                                            user_movimentazioni: Number(res.data?.data[0].id)
                                        }
                                    }).then(res => {
                                        Toast.show('Qr Code Scaricato Correttamente');
                                        setTimeout(() => {
                                            setLoading(false);
                                            navigate('/home')
                                        }, 500)
                                    });
                                    if (crediti.isError) throw 'Errore Inserimento Credito';
                                }, 100)
                            }, 100);
                        }, 100)
                    }
                }
            });
        }, 500)

    }

    function methodThatReturnsAPromise(m: any) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                setIdGift(m.user_gift.id);
                    setTimeout(async () => {
                        console.log(m);
                        await scaricoQrCode.mutateAsync({
                            resource: "scarico-qr-codes",
                            successNotification: false,
                            values: {
                                descrizione: "Consumo Gift",
                                dataScarico: new Date(),
                                user_movimentazioni: m.id,
                                price: m.user_gift.prodotti.prezzo,
                                users: decoded.id
                            }
                        });
                        if (scaricoQrCode.isError) throw 'Errore Scarico Qr Code';
                        await updateMovimentazioni.mutateAsync({
                                resource: "user-movimentazionis",
                            successNotification: false,
                                id: Number(m.id),
                                values: {
                                    isUsed: true,
                                }
                            });
                        if (updateMovimentazioni.isError) throw 'Errore Update Movimentazioni';
                        crediti.mutateAsync({
                                resource: "user-creditis",
                            successNotification: false,
                                values: {
                                    descrizione: "Erogato Caffè da QrCode",
                                    user: decoded.id,
                                    importo: m.user_gift.prodotti.prezzo,
                                    qrCodeToken: qrCode,
                                    isRefund: false,
                                    user_movimentazioni: Number(m.id)
                                }
                            }).then(res => {
                            resolve(m);
                        });
                        if (crediti.isError) throw 'Errore Inserimento Credito';
                        // SCARICO QUANTITA
                        // userQtaGift.refetch({}).then( async (u: any) => {
                        //     console.log('Processing', u.data.data.quantita);
                        //     setTimeout(async () => {
                        //         await userGift({
                        //             resource: "user-gifts",
                        //             id: u.data.data.id,
                        //             values: {
                        //                 quantita: u.data.data.quantita - 1,
                        //                 quantitaUsata: (u.data.data.quantitaUsata ? u.data.data.quantitaUsata : 0) + 1
                        //             }
                        //         });
                        //         resolve(m);
                        //     }, 100)
                        // })
                    }, 100)
            }, 100);
        });
    }

    const consumaQrCode = async () => {
        data?.data.reduce( (accumulatorPromise: any, nextID) => {
            return accumulatorPromise.then(() => {
                return methodThatReturnsAPromise(nextID);
            });
        }, Promise.resolve());
    }

    function startQrCode() {
        if (Html5Qrcode) {
            let html5Qrcode = new Html5Qrcode("reader");
                Html5Qrcode.getCameras().then((devices: any) => {
                    if (devices && devices.length > 0) {
                        devices.map((d: any, index: number) => {
                            if (index === 1) {
                                html5Qrcode.start(
                                    d.id,
                                    config,
                                    (decodedText: any, decodedResult: any) => {
                                        leggoQrCode(decodedText);
                                    },
                                    (errorMessage: any) => {
                                        // parse error, ignore it.
                                    })
                                    .catch((err: any) => {
                                        // Start failed, handle it.
                                    });
                            } else {
                                html5Qrcode.start(
                                    { facingMode: "environment" },
                                    config,
                                    (decodedText: any, decodedResult: any) => {
                                        leggoQrCode(decodedText);
                                    },
                                    (errorMessage: any) => {
                                        // parse error, ignore it.
                                    })
                                    .catch((err: any) => {
                                        // Start failed, handle it.
                                    });
                            }
                        })
                    }
                })
        }

    }

    useEffect(() => {
            startQrCode();
    }, [Html5Qrcode]);

    return (
        <>
            <Row justify="center" align="top" style={{
                marginTop: 40,
                backgroundColor: qrCode ? 'green' : 'F4D3BAFF'
            }}>
                <Col span={20}>
                    <div id='reader' hidden={loading}></div>
                    <div style={{
                       marginTop: 50
                    }} hidden={!loading}>
                        <Spin size={'large'} />
                    </div>
                    {/*<input type={'text'} onKeyUp={(e: any) => {leggoQrCode(e.target.value)}}/>*/}
                </Col>
            </Row>
            {/*<Row justify="center" align="top" style={{marginTop: '10px'}}>*/}
            {/*    <Col span={32}>*/}
            {/*            {data && data?.data.map((pro: IUserMovimentazioni, i) => (*/}
            {/*                <Card key={pro.id}*/}
            {/*                      bordered={false}*/}
            {/*                      style={{*/}
            {/*                          width: 300,*/}
            {/*                          marginTop: 10,*/}
            {/*                          borderRadius: 20,*/}
            {/*                          border: 'none',*/}
            {/*                          boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',*/}
            {/*                          background: '#f4d3ba'*/}
            {/*                      }}*/}
            {/*                      actions={[*/}
            {/*                          <div>€ {(Number(pro.user_gift?.prodotti.prezzo * 1)).toLocaleString(undefined, {*/}
            {/*                              minimumFractionDigits: 2,*/}
            {/*                              maximumFractionDigits: 2*/}
            {/*                          })}</div>,*/}
            {/*                          <div>Qta: 1</div>,*/}
            {/*                      ]}*/}
            {/*                >*/}
            {/*                    <Skeleton loading={false} avatar active>*/}
            {/*                        <Meta*/}
            {/*                            avatar={<Image src={apiImage + '' + pro?.user_gift?.prodotti?.foto.url}*/}
            {/*                                           style={{width: 60, borderRadius: 10}}/>}*/}
            {/*                            title={<div style={{color: '#932928FF'}}>{pro.user_gift?.descrizione}</div>}*/}
            {/*                            description=""*/}
            {/*                        />*/}
            {/*                    </Skeleton>*/}
            {/*                </Card>*/}
            {/*            ))}*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            {/*<Row justify={'center'} align={'top'} style={{marginTop: 10, justifyContent: 'center'}} hidden={data?.data?.length === 0}>*/}
            {/*    <Col span={24} style={{ padding: '1px' }}>*/}
            {/*        <Button type="primary" htmlType={'submit'}*/}
            {/*                style={{background: '#e28a74', borderRadius: '30px', width: '150px', height: '40px', border: 'none', marginRight: 3}}*/}
            {/*                onClick={() => {*/}
            {/*                    consumaQrCode().then( (res: any) => {*/}
            {/*                        if (res) {*/}
            {/*                            Toast.show('Gift Scaricato Correttamente')*/}
            {/*                            navigate('/home')*/}
            {/*                        }*/}
            {/*                    })*/}
            {/*                }}*/}
            {/*        >Scarica Caffè</Button>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
        </>
    );
}
