import {useCreate, useLogin, useTranslate, useUpdate} from '@pankod/refine-core';

import {
    Row,
    Col,
    AntdLayout,
    Card,
    Typography,
    Form,
    Button,
    Input,
    Image,
} from '@pankod/refine-antd';

import {Trans} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {IUser} from '../../interfaces';
import {Modal, Input as InputMobile, Toast} from 'antd-mobile'
import {apiFunction} from '../../apiFuntion';
const {Text, Title} = Typography;

export interface ILoginForm {
    username: string;
    password: string;
    remember: boolean;
    redirectPath: string;
}

export const LoginPage: React.FC = () => {
    const [form] = Form.useForm<ILoginForm>();
    const [email, setEmail] = useState('')
    const t = useTranslate();
    const location = useLocation();
    const navigate = useNavigate();
    const user = useUpdate<IUser>();
    const tokenGift = decodeURIComponent(location.search)?.split('?')[1]?.split('&')[0].split('=')[1];
    const id = decodeURIComponent(location.search)?.split('?')[1]?.split('&')[1]?.split('=')[1];
    const {mutate: login} = useLogin<ILoginForm>();
    useEffect(() => {

    })


    return (
        <AntdLayout
            style={{
                background: "#FAD1B7"
            }}
        >
            <Row
                justify="center"
                align="middle"
                style={{
                    height: '100vh',
                }}
            >
                <Col xs={22}>
                    <div style={{maxWidth: '408px', margin: 'auto'}}>
                        <Image
                            preview={false}
                            style={{marginBottom: 20}}
                            src="/images/logo.png"
                            width="100%"
                        />
                        <div style={{maxWidth: '408px', margin: 'auto', textAlign: 'center', marginTop: 50}}>
                            <Title level={3} className="layout-title">
                                <Trans
                                    i18nKey="pages.login.message"
                                    defaults="<0>Sign</0> in"
                                    components={[<Text key="0" style={{color: '#67be23'}}/>]}
                                />
                            </Title>
                        </div>
                        <Form<ILoginForm>
                            layout="vertical"
                            form={form}
                            onFinish={(values) => {
                                values.redirectPath = '/home' + ((location.search) ? '?token=' + tokenGift + '&id=' + id : '');
                                login(values);
                            }}
                            requiredMark={false}
                            initialValues={{
                                remember: false,
                                username: '',
                                password: '',
                            }}
                        >
                            <Card
                                style={{
                                    textAlign: 'center',
                                    border: '1px outset #222222',
                                    borderRadius: 30,
                                    background: 'rgba(255,255,255,0)',
                                    marginBottom: 10
                                }}
                            >
                                <Form.Item
                                    name="username"
                                    label={t('pages.login.emails', '')}
                                    rules={[{required: true, message: 'Email is required!'}]}
                                >
                                    <Input
                                        bordered={false}
                                        style={{
                                            color: '#000000',
                                            borderRadius: '0px',
                                            textAlign: 'left',
                                            border: 'none',
                                            borderBottom: '1px solid #000000',
                                        }}
                                        size="large"
                                        placeholder="Email"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    label={t(
                                        'pages.login.passwords',
                                        '',
                                    )}
                                    rules={[{required: true, message: 'Password is required!'}]}
                                    style={{marginBottom: '12px'}}
                                >
                                    <Input
                                        bordered={false}
                                        style={{
                                            color: '#000000',
                                            borderRadius: '0px',
                                            textAlign: 'left',
                                            border: 'none',
                                            borderBottom: '1px solid #000000',
                                        }}
                                        type="password"
                                        placeholder="Password"
                                        size="large"
                                    />
                                </Form.Item>
                            </Card>
                            <div style={{marginBottom: '12px'}}>
                                <Row justify="center" align="top" gutter={16}>
                                    <Col xs={32}>
                                        <a
                                            style={{
                                                fontSize: '12px',
                                            }}
                                            onClick={() =>
                                                Modal.alert({
                                                    title: 'Reset Password',
                                                    content: ( <><Input
                                                        placeholder='Email'
                                                        onInput={ (val: any) => {
                                                            setEmail(val.target.value)
                                                        }}
                                                    /></>),
                                                    confirmText: 'Invia',
                                                    onConfirm: async () => {
                                                        console.log('Confirmed: ', email);
                                                        const r = await apiFunction.resetLinkPassword(email);
                                                        if (r) {
                                                            Toast.show('Email Inviata con successo!')
                                                        }

                                                    },
                                                })
                                            }
                                        >
                                            <u>
                                                {t(
                                                    'pages.login.forgotPasswords',
                                                    'Hai dimenticato la tua password?',
                                                )}
                                            </u>
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                            <Row gutter={16} style={{marginTop: 50}}>
                                <Col xs={12}>
                                    <Button
                                        type="default"
                                        size="large"
                                        htmlType="submit"
                                        block
                                        href={'choice?token=' + tokenGift + '&id=' + id}
                                        style={{
                                            border: 'none',
                                            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                            color: '#ffffff',
                                            borderRadius: '40px',
                                            background: '#c64140'
                                        }}
                                    >
                                        {t('pages.login.registrati', 'Registrati')}
                                    </Button>
                                </Col>
                                <Col xs={12}>
                                    <Button
                                        type="default"
                                        size="large"
                                        htmlType="submit"
                                        block
                                        style={{
                                            border: 'none',
                                            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                            color: '#ffffff',
                                            borderRadius: '40px',
                                            background: '#800100'
                                        }}
                                    >
                                        {t('pages.login.entra', 'Entra')}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
            </Row>
        </AntdLayout>
    );
};
