import {
    IResourceComponentsProps, useCreate, useList, useUpdate,
} from '@pankod/refine-core';
import {
    Typography,
    Row,
    Col,
    Avatar,
    Card,
    useTable, Carousel, useSelect, useSimpleList, Image, Skeleton, Input, Alert, Modal, Badge
} from '@pankod/refine-antd';
import {Button, Checkbox, Stepper, Toast} from 'antd-mobile'
import {IBasketListItem, IMessage, IProduct, IUserFriend, IUserGift, IUserMovimentazioni} from '../../interfaces';
import React, {createRef, useEffect, useState} from 'react';
import {API_URL, TOKEN_KEY} from '../../constants';
import {useLocation, useNavigate} from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import {
    FlagOutlined,
    ShoppingCartOutlined, PlusOutlined, MinusOutlined, DeleteOutlined
} from '@ant-design/icons';
import {apiFunction} from '../../apiFuntion';
import {Spin} from 'antd';
import moment from 'moment/moment';
import dayjs from 'dayjs';
const { Title } = Typography;
const { Meta } = Card;


export const UseList: React.FC<IResourceComponentsProps> = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const tokenSession = localStorage.getItem(TOKEN_KEY);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const token = decodeURIComponent(location.search)?.split('?')[1]?.split('&')[0]?.split('=')[1];
    const id = decodeURIComponent(location.search)?.split('?')[1]?.split('&')[1]?.split('=')[1];
    const stop = 0;
    // @ts-ignore
    const decoded: any = localStorage.getItem(TOKEN_KEY) ? jwt_decode(localStorage.getItem(TOKEN_KEY)) : {id: 0};
    const apiImage = API_URL;
    const [tipo, setTipo] = useState('tutti');
    const [view, setView] = useState('list');
    const basketUse = (JSON.parse(localStorage.getItem('basketUse') || '{}'));
    const [products, setProducts] = useState<IBasketListItem[]>((basketUse && basketUse.length > 0 ? basketUse : []));
    const [dataCart, setDataCart] = useState<any>([]);
    const [qta, setQta] = useState(0);
    const [qtaUsata, setQtaUsata] = useState(0);
    const [maxQta, setMaxQta] = useState(0);
    const [isLoadGift, setIsLoadGift] = useState(false);
    const {data, error, isLoading} = useList<IUserGift>({
        resource: 'user-gifts',
        metaData: {
            populate: "prodotti, prodotti.foto, fromUser, toUser",
        },
        config: {
            filters: [
                {
                    field: "user][id]",
                    operator: "eq",
                    value: decoded?.id
                },
                {
                    field: "quantita",
                    operator: "gt",
                    value: 0
                },
                {
                    field: "tipo",
                    operator: "contains",
                    value: 'ricevuto'
                }
            ]
        },
    });
    const userFindMovimentazioni = useList<IUserMovimentazioni>({
        resource: 'user-movimentazionis',
        metaData: {
            populate: "user_gift, user_gift.prodotti, users",
        },
        config: {
            filters: [
                {
                    field: "token",
                    operator: "eq",
                    value: token
                },
                {
                    field: "isUsed",
                    operator: "eq",
                    value: false
                },
            ]
        },
    });
    const userMovimentazioni = useCreate<IUserMovimentazioni>();
    const userUpdateMovimentazioni = useUpdate<IUserMovimentazioni>();
    const userGift = useUpdate<IUserGift>();
    const gift = useCreate<IUserGift>();
    const friend = useCreate<IUserFriend>();
    const message = useCreate<IMessage>();
    const resultFriend = useList<IUserFriend>({
        resource: "user-friends",
        metaData: {
            populate: "*",
        },
        queryOptions: {
            enabled: false
        }
    });

    // function addReceivedGift(giftReceived: any) {
    //     gift.mutate({
    //         resource: "user-gifts",
    //         values: {
    //             descrizione: "Ricevuto" + ' ' + giftReceived?.user_gift?.descrizione,
    //             user: decoded.id,
    //             prodotti: giftReceived?.user_gift?.prodotti?.id,
    //             tipo: 'ricevuto',
    //             quantita: 1
    //         }
    //     });
    //     if (gift.error) throw 'Errore Inserimento Movimentazione';
    //     userUpdateMovimentazioni.mutate({
    //         resource: "user-movimentazionis",
    //         id: Number(giftReceived.id),
    //         values: {
    //             isUsed: true,
    //         }
    //     });
    // }

    // async function getFriend(fromUser: any, toUser: any) {
    //     return new Promise<any>(resolve => {
    //         resultFriend.refetch().then( (res: any) => {
    //             // console.log(res);
    //             if (res.status === 'success') {
    //                 console.log(fromUser, toUser, res.data.data.findIndex( (x: IUserFriend) => (x.friends.id === fromUser && x.users.id === toUser)));
    //                 const fU = res.data.data.findIndex( (x: IUserFriend) => (x.friends.id === fromUser && x.users.id === toUser));
    //                 if (fU !== -1) {
    //                     resolve(true);
    //                 } else {
    //                     resolve(false);
    //                 }
    //             }
    //         }).catch( e => {
    //             console.log(e);
    //         });
    //     })
    // }

    // function addFriend() {
    //     getFriend(Number(id), Number(decoded.id)).then( (res: boolean) => {
    //         // console.log(res);
    //         if (!res) {
    //             friend.mutate({
    //                 resource: "user-friends",
    //                 values: {
    //                     descrizione: "Nuovo Collegamento",
    //                     isActive: true,
    //                     friends: id,
    //                     users: decoded.id
    //                 }
    //             });
    //             friend.mutate({
    //                 resource: "user-friends",
    //                 values: {
    //                     descrizione: "Nuovo Collegamento",
    //                     isActive: true,
    //                     friends: decoded.id,
    //                     users: id
    //                 }
    //             });
    //         }
    //     }).catch(e => {
    //         console.log(e);
    //     })
    // }

    function generaQrCode() {
        let d = new Date().getTime();//Timestamp
        let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            let r = Math.random() * 16;//random number between 0 and 16
            if(d > 0){//Use timestamp until depleted
                r = (d + r)%16 | 0;
                d = Math.floor(d/16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r)%16 | 0;
                d2 = Math.floor(d2/16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }
    useEffect(() => {
        setIsLoadGift(true);
        apiFunction.addReceivedGift(Number(id), decoded.id, tokenSession, token).then( (res: any) => {
            // console.log(res);
            if (res.status === 200 && res.data.data.length > 0 ) {
                apiFunction.addFriend(Number(id), decoded.id, tokenSession);
                setTipo('ricevuto');
                if (res.data.data[0].attributes.leggiMessaggioSubito) {
                    // MOSTRO MESSAGGIO
                    Modal.info({
                        title: 'Messaggio Ricevuto da: ' + res.data.data[0].attributes.users.data.attributes.nome + '' + res.data.data[0].attributes.users.data.attributes.cognome,
                        content: (
                            <div style={{
                                zIndex: 999999999
                            }}>
                                <p>{res.data.data[0].attributes.messaggio}</p>
                                <Image
                                    style={{
                                        zIndex: 999999999
                                    }}
                                    preview={false}
                                    width={200}
                                    src={apiImage + '' + res.data.data[0].attributes?.immagine?.data?.attributes?.url}
                                />
                            </div>
                        ),
                        onOk() {},
                    });
                }
                setIsLoadGift(false);
            } else {
                setIsLoadGift(false);
            }
        }).catch((e: any) => {

        });
        // if (token && id) {
        //     if (userFindMovimentazioni.error) throw 'Errore';
        //     if (userFindMovimentazioni.isSuccess && userFindMovimentazioni.data?.data.length > 0) {
        //         console.log('CREO GIFT: ', userFindMovimentazioni);
        //         userFindMovimentazioni && userFindMovimentazioni.data?.data.map((pro: IUserMovimentazioni, i: any) => {
        //             apiFunction.addReceivedGift(pro, decoded.id, tokenSession, token);
        //         })
        //         apiFunction.addFriend(Number(id), decoded.id, tokenSession);
        //     }
        //     setTipo('ricevuto')
        // }
    }, []);

    useEffect(() => {
        localStorage.setItem('basketUse', JSON.stringify(products));
        switch (location.state) {
            case 'ricevuto':
                setTipo('ricevuto');
                break;
            case 'acquistato':
                setTipo('acquistato');
                break;
            default:
                setTipo('');
                break;

        }
        // console.log(dataG)
    });

    const changeView = (view: string) => {
        setView(view)
        if (view === 'qrCode') {

        }
    }

    const dataG = data?.data.reduce((r: any, { id, descrizione, prodotti, quantita, quantitaUsata}) => {
        // if (tipo === '') {
        //     r.push({ id, descrizione, prodotti, quantita });
        //     return r;
        // } else {
        let temp: any = r.find((o: any) => o?.prodotti?.id === prodotti?.id);
        if (!temp) {
            r.push({ id, descrizione, prodotti, quantita, quantitaUsata });
        } else {
            temp.quantita += quantita;
        }
        return r;
        // }
    }, []);

    // useEffect(() => {
    //
    //     setDataG(result ? result : data);
    // }, [])


    let add = (item?: any)=>{
        console.log(item);
        setDataCart(item);
        setView('use')
        setMaxQta(item?.quantita);
        if(qtaCart(Number(item?.prodotti?.id)) < item?.quantita)
        {
            setQta(1);
            addProductToCart({
                item: item.prodotti,
                quantita: 1,
                maxQta: item.quantita,
                importo: Number(item?.prodotti?.prezzo),
                id: Number(item?.id)
            });
        } else {
            // Toast.show('Non hai più Gift a disposizione');
            Toast.show({content: <div style={{ textAlign: 'center'}}>Devi generare prima il<br /> QrCode</div>});
        }

    };
    let del = (item?: any) => {
        setView('use')
        if(qtaCart(Number(item?.item.id))<= item?.quantita)
        {
            setQta(qta - 1);
            removeProductFromCart({
                item: item.item,
                quantita: item?.quantita,
                maxQta: item?.maxQta,
                importo: Number(item?.item?.prezzo),
                id: Number(item?.item.id)
            });
        }
    }

    const qtaCart = (id: number) => {
        const b = products.findIndex((r: any) => r.item?.id === id)
        if (b !== -1) {
            return products[b].quantita
        } else {
            return 0
        }
    }

    const getMaxQta = (id: number) => {
        const b = products.findIndex((r: any) => r.item?.id === id)
        if (b !== -1) {
            return products[b].maxQta
        } else {
            return 0
        }
    }

    const getProductById = (id: number): IBasketListItem | undefined => {
        return products.find((p: any) => p.item?.id === id);
    };

    const addProductToCart = (product: IBasketListItem): void => {
        {

            const existingProduct = getProductById(Number(product.item?.id));
            let newState: IBasketListItem[] = [];
            if (existingProduct) {
                // console.log('Exisisting: ', existingProduct)
                newState = products.map((p: any) => {
                    if (p.item?.id === existingProduct.item?.id) {
                        return {
                            item: p.item,
                            // quantita: Number(p.quantita) + product.quantita,
                            quantita: 1,
                            // maxQta: p.maxQta,
                            maxQta: p.quantita,
                            importo: p.importo + p.item.prezzo,
                            id: p.id
                        };
                    }
                    return p;
                });
                // console.log('NewState: ', newState)
                setProducts(newState);
            } else {
                // console.log('Product: ', product)
                setProducts([...products, {
                    item: product.item,
                    // quantita: product.quantita,
                    // maxQta: product.maxQta,
                    quantita: 1,
                    maxQta: product.quantita,
                    importo: product.importo,
                    id: product.id
                }]);
                // console.log('Product New: ', products)
            }
        }
    };

    const removeProductFromCart = (product: IBasketListItem) => {
        let newState: IBasketListItem[] = [];
        if (Number(product.quantita) === 1) {
            const getProducts = products.filter((p: any) => p.item?.id !== product.id);
            setProducts(getProducts);
            if (getProducts.length === 0) {
                setView('list');
            }
        } else {
            newState = products.map((p: any) => {
                if (p.item?.id === product.id) {
                    return {
                        item: p.item,
                        quantita: Number(p.quantita) - 1,
                        importo: p.importo - p.item.prezzo,
                        id: p.item.id
                    };
                }
                return p;
            });
            setProducts(newState);
        }

    };

    const go = (path: string) => {
        const t = generaQrCode();
        products.forEach( (b: any) => {
            for (let q = 0; q < Number(qtaCart(b.item.id)); q++) {
                userMovimentazioni.mutate({
                    resource: "user-movimentazionis",
                    values: {
                        descrizione: "Generato Qr Code",
                        users: decoded.id,
                        token: generaQrCode(),
                        qrCodeToken: t,
                        user_gift: b.id,
                        tipo: 'generato',
                        isUsed: false
                    }
                });
                if (userMovimentazioni.error) throw 'Errore';
            }
            // Scarico subito i caffe inviati tramite QrCode
            console.log('Scarico: ', dataCart)
            userGift.mutate({
                resource: "user-gifts",
                id: b.id,
                values: {
                    quantita: maxQta - 1,
                    quantitaUsata: dataCart.quantitaUsata + 1
                },
            })
            if (userGift.error) throw 'Errore Update Gift';
        });
        localStorage.setItem('basketUse', JSON.stringify([]));
        navigate(path, {state: {qrCode: t}})
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    return (
        dataG && dataG?.length === 0 &&
                <>
                    <div hidden={isLoadGift}>
                        <Row justify={'center'} align={'top'} style={{marginTop: 40, justifyContent: 'center'}}>
                            <Col span={24} style={{ padding: '20px' }}>
                                <Card
                                    style={{
                                        textAlign: 'center',
                                        border: 'none',
                                        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                        borderRadius: '40px',
                                        height: 280,
                                        width: '100%',
                                        background: '#93292a',
                                        color: '#FFFFFF' }}>
                                    <Row gutter={[16, 32]} style={{marginBottom: '10px'}}>
                                        <Col span={24}>
                                            <Title level={5} style={{color: '#FFFFFF'}}>Animazione</Title>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row justify={'center'} align={'top'} style={{marginTop: -38, textAlign: 'center'}}>
                            <Col span={24}>
                                <div style={{
                                    position: 'absolute',
                                    width: 80,
                                    height: 80,
                                    border: '2px solid #FFFFFF',
                                    borderRadius: '50%',
                                    background: '#93292a',
                                    zIndex: 99,
                                    padding: 5,
                                    marginLeft: '40%',
                                    marginTop: -25
                                }}>
                                    <FlagOutlined style={{fontSize: 30, verticalAlign: 'middle', padding: 20, color: '#FFFFFF'}}/>
                                </div>
                            </Col>
                        </Row>

                        <Row justify={'center'} align={'top'} style={{marginTop: 1, justifyContent: 'center'}}>
                            <Col span={24} style={{ padding: '20px' }}>
                                <Card
                                    style={{
                                        textAlign: 'center',
                                        border: 'none',
                                        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                        borderRadius: '40px',
                                        height: 230,
                                        width: '100%',
                                        background: '#93292a',
                                        color: '#FFFFFF' }}>
                                    <Row gutter={[16, 32]} style={{marginBottom: '10px', justifyContent: 'center'}}>
                                        <Col span={22}>
                                            <Title level={3} style={{color: '#FFFFFF', marginTop: 25}}>Ops!</Title>
                                            <Title level={5} style={{color: '#FFFFFF'}}>{(data?.data.length !== 0 ? 'Non hai ricevuto nessun Caffè' : 'Non hai coffee voucher a disposizione!')}</Title>
                                            <Button type="button"
                                                    style={{background: '#f3d3ba', color: '#000000', borderRadius: '30px', width: '150px', height: '40px', border: 'none', marginTop: 10}}
                                                    onClick={() => (data?.data.length === 0 ? navigate('/product?page=riepilogo') : navigate('/use'))}
                                            >{(data?.data.length === 0 ? 'Acquista' : 'Indietro')}</Button>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        {/*<Row justify={'center'} align={'top'} style={{marginTop: 1, justifyContent: 'center'}}>*/}
                        {/*    <Col span={24} style={{ padding: '1px' }}>*/}
                        {/*        <Button type="button"*/}
                        {/*                style={{background: '#93292a', color: '#FFFFFF', borderRadius: '30px', width: '150px', height: '40px', border: 'none'}}*/}
                        {/*                onClick={() => navigate('/product')}*/}
                        {/*        >Vai allo Shop</Button>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                    </div>
                    <div hidden={!isLoadGift} style={{
                        marginTop: 300
                    }}>
                        <Spin size="large" />
                    </div>
                </> ||
        <>
            <Row justify="center" align="top">
                <Col>
                    <Title level={4} style={{
                        color: '#8E201BFF',
                        marginTop: '50px'
                    }}>{view === 'use' ? 'Coffe Basket' : 'Consuma'}</Title>
                </Col>
            </Row>
            <Row justify="space-around" align="top" style={{marginTop: '30px'}}>
                <Col span={14} style={{marginTop: -30}}>
                    <Title level={4} style={{
                        fontSize: 16,
                        color: '#8E201BFF',
                        marginTop: '0px'
                    }}>{view === 'use' ? '' : 'Seleziona la Gift da utilizzare'}</Title>
                </Col>
            </Row>
            <Row justify="center" align="top" style={{marginTop: '30px'}} hidden={view === 'use'}>

            </Row>
            <Row justify="center" align="top" hidden={view !== 'send'}>
                <Col span={18} style={{marginTop: -30}}>
                    <Title level={5} style={{
                        color: '#8E201BFF',
                        marginTop: '30px'
                    }}>Genera Link!</Title>
                </Col>
            </Row>
            {view === 'use' &&
                <>
                    <Row justify="center" align="top" style={{marginTop: '10px'}}>
                        <Col span={32}>
                            {products && products.map( (pro: IBasketListItem, i: any) => (
                                <Card key={pro.id}
                                      bordered={false}
                                      style={{
                                          width: 300,
                                          marginTop: 10,
                                          borderRadius: 20,
                                          border: 'none',
                                          boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                          background: '#f4d3ba' }}
                                      actions={[
                                          <div>
                                              <DeleteOutlined style={{color: '#932928FF'}} onClick={() => del(pro)}/>
                                              {/*€ {Number(pro.item?.prezzo) * pro.quantita}*/}
                                          </div>,
                                          // <MinusOutlined />,
                                          <div>Qta: {pro.quantita}</div>,
                                          // <PlusOutlined key="ellipsis" />,
                                          // <PlusOutlined style={{color: '#932928FF'}} onClick={() => add(dataCart)}/>
                                      ]}
                                >
                                    <Skeleton loading={false} avatar active>
                                        <Meta
                                            avatar={<Image src={apiImage + '' + pro?.item?.foto.url} style={{width: 60, borderRadius: 10}} />}
                                            title={<div style={{color: '#932928FF'}}>{pro.item?.descrizione}</div>}
                                            description=""
                                        />
                                    </Skeleton>
                                </Card>
                            ))}
                        </Col>
                    </Row>
                    <Row justify="center" align="top" style={{marginTop: 20}}>
                        <Col span={8}>
                            <Button style={{
                                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                border: 'none',
                                background: '#ffffff',
                                color: '#800100',
                                borderRadius: 20}}
                                    onClick={(val) => {
                                        setView('list')
                                    }}
                            >Indietro</Button>
                        </Col>
                        <Col span={10}>
                            <Button style={{
                                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                border: 'none',
                                background: '#800100FF',
                                color: '#FFFFFF',
                                borderRadius: 20}}
                                    onClick={(val) => {
                                        go('/generaCode')
                                    }}
                            >Genera QrCode</Button>
                        </Col>
                    </Row>
                    <Row justify={'center'} align={'top'} style={{marginTop: 10, justifyContent: 'center'}}>
                        <Col span={24} style={{ padding: 30 }}>
                            <span style={{
                                color: '#93292AFF',
                                fontSize: 18,
                            }}>
                        Stiamo per preparati un ottimo caffè, per poter richiedere il caffè al Bar, genera il QR Code attraverso l'apposito tasto.

                    </span>
                        </Col>
                    </Row>
                    <Row justify={'center'} align={'top'} style={{marginTop: 10, justifyContent: 'center'}}>
                        <Col span={24} style={{ padding: 30 }}>
                            <span style={{
                                color: '#8c8c8c',
                                fontSize: 14,
                            }}>
                            Ricorda che dovrai generare un singolo QR Code per ogni voucher che si desidera utilizzare.
                            </span>
                        </Col>
                    </Row>
                </>
            }
            {view === 'carousel' &&

            <Row justify="center" align="top" style={{marginTop: '30px'}}>
                <Col>
                        <Carousel
                            className={'center'}
                            dots={false}
                            centerMode={dataG?.length !== 1}
                            centerPadding={'30px'}
                            slidesToShow={1}
                            style={{width: '100%'}}>
                            {dataG && dataG?.map((pro: any) => (
                                <div key={pro.id}>
                                    <div style={{
                                        background: pro.isRicevuto ? '#FFFFFF' : 'rgba(147,41,42,0)',
                                        color: '#FFFFFF',
                                        height: 300,
                                        boxShadow: '-3px 1px 3px rgba(0, 0, 0, 0.1)',
                                        borderRadius: '30px',
                                        marginLeft: '15px',
                                        marginRight: '15px'
                                    }} onClick={(val) => {
                                        add(pro)
                                    }}>
                                        <Avatar style={{
                                            width: '100%',
                                            height: 160,
                                            borderTopLeftRadius: '30px',
                                            borderTopRightRadius: '30px'
                                        }} src={apiImage + '' + pro?.prodotti?.foto.url}/>
                                        <div style={{margin: '15px'}} hidden={pro.isRicevuto}>

                                        </div>
                                        <div style={{
                                            textAlign: 'center',
                                            marginRight: 45,
                                        }}>
                                            <Button style={{
                                                marginTop: 80,
                                                height: 40,
                                                width: 40,
                                                position: 'absolute',
                                                border: 'none',
                                                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                                background: '#F4D3BAFF'
                                            }} shape={'rounded'}>{pro?.quantita - qtaCart(pro?.prodotti?.id)}</Button>
                                        </div>
                                        <div>
                                            <Title level={3} style={{
                                                color: pro.isRicevuto ? '#93292a' : '#FFFFFF',
                                                marginTop: pro.isRicevuto ? 57 : 0
                                            }}>{pro.descrizione}</Title>
                                        </div>
                                        <div style={{
                                            marginRight: -230,
                                            marginTop: -10
                                        }}>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                </Col>
            </Row>
                || view === 'list' &&
                <>
                <Row justify="center" align="top" style={{marginTop: '10px'}}>
                    {data && data.data?.map((pro: any) => (
                        <>
                            <div style={{
                                color: '#FFFFFF',
                                height: 200,
                                width: 130,
                                boxShadow: '-3px 1px 3px rgba(0, 0, 0, 0.1)',
                                borderRadius: '30px',
                                marginLeft: '15px',
                                marginRight: '15px',
                                marginBottom: 10
                            }}
                                 onClick={(val) => {
                                     add(pro)
                                 }}
                            >
                                <Avatar style={{
                                    width: '100%',
                                    height: '100%',
                                    borderTopLeftRadius: 20,
                                    borderTopRightRadius: 20,
                                    borderBottomLeftRadius: 20,
                                    borderBottomRightRadius: 20
                                }} src={apiImage + '' + pro?.prodotti?.foto.url}/>
                                <div style={{margin: '15px'}} hidden={pro.isRicevuto}>
                                    {/*<Checkbox*/}
                                    {/*    value={pro.id}*/}
                                    {/*    onChange={(val) => {*/}
                                    {/*        console.log(val, pro.id);*/}
                                    {/*        setProducts(pro)*/}
                                    {/*    }}></Checkbox>*/}
                                </div>
                                <div style={{
                                    textAlign: 'center',
                                    marginRight: 45,
                                }}>
                                    <Button style={{
                                        marginTop: -200,
                                        position: 'absolute',
                                        zIndex: 99,
                                        border: 'solid',
                                        borderColor: 'white',
                                        height: 30,
                                        width: 30,
                                        fontSize: 12,
                                        padding: '3px 3px 2px 3px',
                                        marginLeft: 5,
                                        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                        background: '#F4D3BAFF'
                                    }} shape={'rounded'}>{pro.quantita}</Button>
                                </div>
                                <div>
                                    <Title level={3} style={{
                                        fontSize: 14,
                                        position: 'absolute',
                                        color: pro.tipo === 'ricevuto' ? '#93292a' : '#FFFFFF',
                                        marginTop: pro.isRicevuto ? 75 : -75,
                                        zIndex: 9,
                                        textAlign: 'center',
                                        inlineSize: 120,
                                        padding: '1px 3px 10px 10px',
                                        overflowWrap: 'break-word'
                                    }}>{pro.tipo === 'ricevuto' ? ' da: ' + pro.fromUser?.nickname : 'Acquistato'}</Title>
                                    <Title level={5} style={{
                                        fontSize: 10,
                                        position: 'absolute',
                                        color: pro.tipo === 'ricevuto' ? '#93292a' : '#838383',
                                        marginTop: pro.isRicevuto ? 60 : -35,
                                        zIndex: 9,
                                        textAlign: 'center',
                                        inlineSize: 120,
                                        padding: '1px 3px 10px 10px',
                                        overflowWrap: 'break-word'
                                    }}>il: {dayjs(pro.createdAt).format('DD-MM-YYYY HH:mm')}</Title>
                                </div>
                                <div style={{
                                    marginRight: -230,
                                    marginTop: -10
                                }}>
                                </div>
                            </div>
                            {/*<Card key={pro.id}*/}
                            {/*      bordered={false}*/}
                            {/*      style={{*/}
                            {/*          alignItems: 'flex-start',*/}
                            {/*          alignContent: 'flex-start',*/}
                            {/*          textAlign: 'left',*/}
                            {/*          width: 300,*/}
                            {/*          marginTop: 10,*/}
                            {/*          borderRadius: 20,*/}
                            {/*          border: 'none',*/}
                            {/*          boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',*/}
                            {/*          background: '#e28a74' }}*/}
                            {/*      onClick={(val) => {*/}
                            {/*          add(pro)*/}
                            {/*      }}*/}
                            {/*>*/}
                            {/*    /!*<Image src={apiImage + '' + pro?.prodotti?.foto?.url} style={{width: 60, borderRadius: 10}} />*!/*/}
                            {/*    <div><span style={{color: '#FFFFFF', fontWeight: 600}}>{pro.prodotti?.descrizione} - Totale: {pro.quantita + pro.quantitaUsata}</span></div>*/}
                            {/*    /!*<div><span style={{color: '#000000', fontWeight: 800}}>Data: </span> <span style={{color: '#FFFFFF', fontWeight: 400}}>{moment(pro.createdAt).format('DD-MM-YYYY')} {tipo === 'ricevuto' ? '' : '- Prezzo: ' + pro.prodotti.prezzo.toFixed(2)+ '€'}</span></div>*!/*/}
                            {/*    /!*<div hidden={(pro.tipo === 'acquistato')}><span style={{color: '#000000', fontWeight: 800}}>Friend: </span> <span style={{color: '#93292a', fontWeight: 400}}>{pro.fromUser?.nome + ' ' + pro.fromUser?.cognome}</span></div>*!/*/}
                            {/*    <div style={{*/}
                            {/*        color: '#c64140',*/}
                            {/*        textAlign: 'right',*/}
                            {/*        marginTop: 3,*/}
                            {/*    }}>Rimanenza: {pro.quantita} Usato: {(pro.quantitaUsata ? pro.quantitaUsata : 0)}</div>*/}
                            {/*</Card>*/}
                        </>


                        ))
                    }
                </Row>
                </>
            }
        </>
    );
};
