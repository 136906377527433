import {
    IResourceComponentsProps, useCreate,
} from '@pankod/refine-core';
import {
    Typography,
    Row,
    Col,
    Radio
} from '@pankod/refine-antd';
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {IUserCrediti} from '../../interfaces';
import jwt_decode from 'jwt-decode';
import {TOKEN_KEY} from '../../constants';
import {Button } from 'antd-mobile'
import {CollectMoneyOutline} from 'antd-mobile-icons';
const { Title } = Typography;


export const AcquistaList: React.FC<IResourceComponentsProps> = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [importo, setImporto] = useState<(string | null)[]>();
    const [showPayment, setShowPayment] = useState(false);
    const { mutate } = useCreate<IUserCrediti>();
    const [visible, setVisible] = useState(false)
    // @ts-ignore
    const decoded: any = jwt_decode(localStorage.getItem(TOKEN_KEY));
    const basicColumns = [
            { label: '5 €', value: '5' },
            { label: '10 €', value: '10' },
            { label: '15 €', value: '15' },
            { label: '20 €', value: '20' },
            { label: '30 €', value: '30' },

    ]
    // function handleChange(value: string) {
    //     console.log('UPDATE')
    //     setImporto(value);
    // }

    function go() {
        console.log('NAVIGATE')
        navigate('/pagamento', {state: {importo: importo}})
    }

    useEffect(() => {
        console.log('Importo', importo);
    });

    function confirmed() {
        mutate({
            resource: "user-creditis",
            values: {
                descrizione: "Acquisto Credito",
                importo: Number(importo),
                user: decoded.id
            }
        });
        setTimeout( () => {
            navigate('/confirmed', { replace: true, state: 'details'})
        }, 3000)
    }

    return (
        <>
            <Row justify="center" align="top">
                <Col span={10}>
                    <Button shape={'rounded'} style={{
                        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                        border: 'none',
                        fontSize: '30px',
                        color: '#FFFFFF',
                        background: '#800100FF',
                        width: '100px',
                        height: '100px',
                        marginTop: '50px',
                    }}
                    >
                        <CollectMoneyOutline />
                    </Button>
                </Col>
            </Row>
            <Row justify="center" align="top" style={{marginTop: '30px'}}>
                <Col span={10}>
                    <Title level={5}>Acquista Crediti</Title>
                </Col>
            </Row>
            <Row justify="center" align="top" style={{marginTop: '30px'}}>
                <Col>
                    {/*<Button*/}
                    {/*    style={{*/}
                    {/*        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',*/}
                    {/*        border: 'none',*/}
                    {/*    }}*/}
                    {/*    color={'success'}*/}
                    {/*    shape={'rounded'}*/}
                    {/*    onClick={() => {*/}
                    {/*        setVisible(true)*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    {importo ? importo + ' €' : 'Scegli Taglio'}*/}
                    {/*</Button>*/}
                    <Radio.Group
                        options={basicColumns}
                        onChange={(v: any) => {setImporto(v.target.value)}}
                        value={importo} optionType="button"
                    />
                    {/*<Picker*/}
                    {/*    cancelText={'Cancel'}*/}
                    {/*    confirmText={'Ok'}*/}
                    {/*    columns={basicColumns}*/}
                    {/*    visible={visible}*/}
                    {/*    onClose={() => {*/}
                    {/*        setVisible(false)*/}
                    {/*    }}*/}
                    {/*    value={importo}*/}
                    {/*    onConfirm={v => {*/}
                    {/*        setImporto(v)*/}
                    {/*    }}*/}
                    {/*/>*/}
                </Col>
            </Row>
            <Row justify="center" align="top" style={{marginTop: '30px'}}>
                <Col>
                    { !showPayment &&
                        <Button block type="button"
                                style={{
                                    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                    color: '#FFFFFF',
                                    background: '#93292a',
                                    borderRadius: '30px',
                                    width: '150px',
                                    height: '40px',
                                    border: 'none'}} onClick={go}>Procedi</Button>
                    }
                </Col>
            </Row>
        </>
    );
};
