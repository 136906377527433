import {
    IResourceComponentsProps,
} from '@pankod/refine-core';
import {
    Avatar,
    Image,
    Typography,
    Card,
    Row,
    Col,
    Radio,
    useForm,
    Select,
    Input,
    Form,
    Button,
    Checkbox, ImageField, Upload,
    getValueFromEvent
} from '@pankod/refine-antd';
import Icon, {EditOutlined, ClockCircleOutlined, SearchOutlined, RightCircleOutlined, EditFilled, CreditCardFilled, CreditCardOutlined, AlipayCircleOutlined} from '@ant-design/icons';
import "./style.less";
import {IUser, IUserVariable} from '../../interfaces';
import {useEffect, useState} from 'react';
import jwt_decode from 'jwt-decode';
import {API_URL, TOKEN_KEY} from '../../constants';
const { Title } = Typography;
const PayPal = () => (
    <svg width="1em" height="1em" viewBox="0 0 256 302">
        <g>
            <path d="M217.168476,23.5070146 C203.234077,7.62479651 178.045612,0.815753338 145.823355,0.815753338 L52.3030619,0.815753338 C45.7104431,0.815753338 40.1083819,5.6103852 39.0762042,12.1114399 L0.136468302,259.076601 C-0.637664968,263.946149 3.13311322,268.357876 8.06925331,268.357876 L65.804612,268.357876 L80.3050438,176.385849 L79.8555471,179.265958 C80.8877248,172.764903 86.4481659,167.970272 93.0324607,167.970272 L120.46841,167.970272 C174.366398,167.970272 216.569147,146.078116 228.897012,82.7490197 C229.263268,80.8761167 229.579581,79.0531577 229.854273,77.2718188 C228.297683,76.4477414 228.297683,76.4477414 229.854273,77.2718188 C233.525163,53.8646924 229.829301,37.9325302 217.168476,23.5070146" fill="#27346A"></path>
            <path d="M102.396976,68.8395929 C103.936919,68.1070797 105.651665,67.699203 107.449652,67.699203 L180.767565,67.699203 C189.449511,67.699203 197.548776,68.265236 204.948824,69.4555699 C207.071448,69.7968545 209.127479,70.1880831 211.125242,70.6375799 C213.123006,71.0787526 215.062501,71.5781934 216.943728,72.1275783 C217.884341,72.4022708 218.808307,72.6852872 219.715624,72.9849517 C223.353218,74.2002577 226.741092,75.61534 229.854273,77.2718188 C233.525163,53.8563683 229.829301,37.9325302 217.168476,23.5070146 C203.225753,7.62479651 178.045612,0.815753338 145.823355,0.815753338 L52.2947379,0.815753338 C45.7104431,0.815753338 40.1083819,5.6103852 39.0762042,12.1114399 L0.136468302,259.068277 C-0.637664968,263.946149 3.13311322,268.349552 8.0609293,268.349552 L65.804612,268.349552 L95.8875974,77.5798073 C96.5035744,73.6675208 99.0174265,70.4627756 102.396976,68.8395929 Z" fill="#27346A"></path>
            <path d="M228.897012,82.7490197 C216.569147,146.069792 174.366398,167.970272 120.46841,167.970272 L93.0241367,167.970272 C86.4398419,167.970272 80.8794007,172.764903 79.8555471,179.265958 L61.8174095,293.621258 C61.1431644,297.883153 64.4394738,301.745495 68.7513129,301.745495 L117.421821,301.745495 C123.182038,301.745495 128.084882,297.550192 128.983876,291.864891 L129.458344,289.384335 L138.631407,231.249423 L139.222412,228.036354 C140.121406,222.351053 145.02425,218.15575 150.784467,218.15575 L158.067979,218.15575 C205.215193,218.15575 242.132193,199.002194 252.920115,143.605884 C257.423406,120.456802 255.092683,101.128442 243.181019,87.5519756 C239.568397,83.4399129 235.081754,80.0437153 229.854273,77.2718188 C229.571257,79.0614817 229.263268,80.8761167 228.897012,82.7490197 L228.897012,82.7490197 Z" fill="#2790C3"></path>
            <path d="M216.952052,72.1275783 C215.070825,71.5781934 213.13133,71.0787526 211.133566,70.6375799 C209.135803,70.1964071 207.071448,69.8051785 204.957148,69.4638939 C197.548776,68.265236 189.457835,67.699203 180.767565,67.699203 L107.457976,67.699203 C105.651665,67.699203 103.936919,68.1070797 102.4053,68.8479169 C99.0174265,70.4710996 96.5118984,73.6675208 95.8959214,77.5881313 L80.3133678,176.385849 L79.8638711,179.265958 C80.8877248,172.764903 86.4481659,167.970272 93.0324607,167.970272 L120.476734,167.970272 C174.374722,167.970272 216.577471,146.078116 228.905336,82.7490197 C229.271592,80.8761167 229.579581,79.0614817 229.862597,77.2718188 C226.741092,75.623664 223.361542,74.2002577 219.723948,72.9932757 C218.816631,72.6936112 217.892665,72.4022708 216.952052,72.1275783" fill="#1F264F"></path>
        </g>
    </svg>
);

export const ProfileList: React.FC<IResourceComponentsProps> = () => {
    const [isEdit, setIsEdit] = useState(true);
    const [tipologia, setTipologia] = useState('');
    const [image, setImage] = useState('');
    const apiImage = API_URL;
    const {setId, id, formProps, queryResult, saveButtonProps, onFinish, redirect, form} = useForm<IUser>(
        {
            action: "edit",
            resource: 'users',
            redirect: false,
        });

    // @ts-ignore
    const decoded: any = jwt_decode(localStorage.getItem(TOKEN_KEY));
    useEffect(() => {
        setId(decoded.id);
        if (queryResult?.status === 'success') {
            setTipologia(queryResult?.data?.data?.tipologia)
            if (queryResult?.data?.data?.nickname) {
                localStorage.setItem('isProfileOk', 'true');
            } else {
                localStorage.setItem('isProfileOk', 'false');
            }
        }
    });
    const uploadButton = (
        <div>
            <Icon type={'plus'} />
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    function handleChange(info: any) {
            if (info.file.status === 'uploading') {
                return;
            }
            if (info.file.status === 'done') {
                setImage(info.file.response[0]);
            }
    }
    // @ts-ignore
    const imageUrl = image ? apiImage + '' + image.url : apiImage + '' + queryResult?.data?.data?.avatar?.url;
    return (
        <>
            <Form
            layout={'vertical'}
            {...formProps}

            onFinish={async (values: any) => {
                if (image) {
                    values.avatar = image;
                }
                return (
                    formProps.onFinish &&
                    formProps.onFinish({
                        ...values,
                    })
                );
            }}
        >
            <Row>
                <Col span={24}>

                    <Form.Item name={'avatar'}
                               getValueFromEvent={getValueFromEvent}
                               valuePropName="response"
                    >
                        <Upload
                            name="files"
                            showUploadList={false}
                            action= {API_URL + "/api/upload"}
                            onChange={handleChange}
                        >
                            {imageUrl ? <Avatar
                                style={{marginTop: '40px'}}
                                size={{xs: 100, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100}}
                                // @ts-ignore
                                src={imageUrl} alt={'Avatar'}
                            /> : uploadButton}
                        </Upload>
                    </Form.Item>
                    <Title level={4} style={{marginTop: '20px'}}>
                        <Form.Item name={'nickname'}>
                            <Input bordered={false} style={{
                                borderRadius: '0px',
                                textAlign: 'center',
                                border: 'none',
                                borderBottom: '1px solid #FFFFFF',
                                padding: '5px 20px',
                                width: '200px'}}
                                   placeholder={(tipologia !== 'bar' ? 'Nickname': 'Nome Bar')} disabled={!isEdit} />
                        </Form.Item>
                    </Title>
                </Col>
            </Row>
            <Row justify={'center'} align={'top'}>
                <Col span={24} style={{ padding: '20px' }}>
                    <Card bordered={false}
                          style={{ boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)', textAlign: 'left', borderRadius: '20px', width: '100', background: '#93292a', color: '#FFFFFF' }}>
                        <Row gutter={[16, 32]} style={{marginBottom: '10px'}}>
                            <Col span={22}>
                                <Title level={5} style={{color: '#FFFFFF'}}>Dati Profilo</Title>
                            </Col>
                            {/*<Col span={2}>*/}
                            {/*    <EditFilled style={{color: '#fad1b7'}} onClick={() => setIsEdit(!isEdit)}/>*/}
                            {/*</Col>*/}
                        </Row>
                        <Form.Item
                            name={(tipologia !== 'bar' ? 'nome': 'ragioneSociale')}>
                            <Input
                                // prefix={<div style={{marginLeft: '-10px'}}><RightCircleOutlined /></div>}
                                bordered={false} style={{
                                color: '#FFFFFF',
                                borderRadius: '0px',
                                textAlign: 'left',
                                border: 'none',
                                borderBottom: '1px solid #FFFFFF',
                                width: '100%'}}
                                   placeholder={(tipologia !== 'bar' ? 'Nome': 'Ragione Sociale')} disabled={!isEdit} />
                        </Form.Item>
                        <Form.Item name={'cognome'} hidden={(tipologia === 'bar')}>
                            <Input bordered={false} style={{
                                color: '#FFFFFF',
                                borderRadius: '0px',
                                textAlign: 'left',
                                border: 'none',
                                borderBottom: '1px solid #FFFFFF',
                                width: '100%'}}
                                   placeholder="Cognome" disabled={!isEdit} />
                        </Form.Item>
                        <Form.Item name={'nickname'}>
                            <Input bordered={false} style={{
                                color: '#FFFFFF',
                                borderRadius: '0px',
                                textAlign: 'left',
                                border: 'none',
                                borderBottom: '1px solid #FFFFFF',
                                width: '100%'}}
                                   placeholder={(tipologia === 'bar') ? "Nome Bar" : "nickname"} disabled={!isEdit} />
                        </Form.Item>
                        <Row>
                            <Col span={14}>
                                <Form.Item name={'dataNascita'} hidden={(tipologia === 'bar')}>
                                    <Input type={'date'} bordered={false} style={{
                                        color: '#FFFFFF',
                                        borderRadius: '0px',
                                        textAlign: 'left',
                                        border: 'none',
                                        borderBottom: '1px solid #FFFFFF',
                                        width: '100%'}}
                                           placeholder="Data Di Nascita" disabled={!isEdit} />
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item name={'sesso'} hidden={(tipologia === 'bar')}>
                                    <Select bordered={false}
                                            style={{
                                                color: '#FFFFFF',
                                                borderRadius: '0px',
                                                textAlign: 'left',
                                                border: 'none',
                                                borderBottom: '1px solid #FFFFFF',
                                                width: '100%'}}
                                        options={[
                                            {
                                                label: "Uomo",
                                                value: "Maschio",
                                            },
                                            {
                                                label: "Donna",
                                                value: "Femmina",
                                            },
                                            {
                                                label: "Altro",
                                                value: "Altro",
                                            },
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item name={'indirizzo'}>
                            <Input bordered={false} style={{
                                color: '#FFFFFF',
                                borderRadius: '0px',
                                textAlign: 'left',
                                border: 'none',
                                borderBottom: '1px solid #FFFFFF',
                                width: '100%'}}
                                   placeholder="Indirizzo" disabled={!isEdit} />
                        </Form.Item>
                        <Row>
                            <Col span={18}>
                                <Form.Item name={'nazione'}>
                                    <Input bordered={false} style={{
                                        color: '#FFFFFF',
                                        borderRadius: '0px',
                                        textAlign: 'left',
                                        border: 'none',
                                        borderBottom: '1px solid #FFFFFF',
                                        width: '100%'}}
                                           placeholder="Nazione" disabled={!isEdit} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name={'provincia'}>
                                    <Input bordered={false} style={{
                                        color: '#FFFFFF',
                                        borderRadius: '0px',
                                        textAlign: 'left',
                                        border: 'none',
                                        borderBottom: '1px solid #FFFFFF',
                                        width: '100%'}}
                                           placeholder="Provincia" disabled={!isEdit} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={14}>
                                <Form.Item name={'citta'}>
                                    <Input bordered={false} style={{
                                        color: '#FFFFFF',
                                        borderRadius: '0px',
                                        textAlign: 'left',
                                        border: 'none',
                                        borderBottom: '1px solid #FFFFFF',
                                        width: '100%'}}
                                           placeholder="Città" disabled={!isEdit} />
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item name={'cap'}>
                                    <Input bordered={false} style={{
                                        color: '#FFFFFF',
                                        borderRadius: '0px',
                                        textAlign: 'left',
                                        border: 'none',
                                        borderBottom: '1px solid #FFFFFF',
                                        width: '100%'}}
                                           placeholder="CAP" type={'number'} disabled={!isEdit} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item name={'piva'} hidden={(tipologia !== 'lover')}>
                            <Input bordered={false} style={{
                                color: '#FFFFFF',
                                borderRadius: '0px',
                                textAlign: 'left',
                                border: 'none',
                                borderBottom: '1px solid #FFFFFF',
                                width: '100%'}}
                                   placeholder="Partita Iva" disabled={!isEdit} />
                        </Form.Item>
                        <Form.Item name={'codiceDestinatario'} hidden={(tipologia !== 'lover')}>
                            <Input bordered={false} style={{
                                color: '#FFFFFF',
                                borderRadius: '0px',
                                textAlign: 'left',
                                border: 'none',
                                borderBottom: '1px solid #FFFFFF',
                                width: '100%'}}
                                   placeholder="Codice Destinatario" disabled={!isEdit} />
                        </Form.Item>
                        <Form.Item name={'email'}>
                            <Input bordered={false} style={{
                                color: '#FFFFFF',
                                borderRadius: '0px',
                                textAlign: 'left',
                                border: 'none',
                                borderBottom: '1px solid #FFFFFF',
                                width: '100%'}}
                                   placeholder="Email" disabled={!isEdit} />
                        </Form.Item>
                        <Form.Item name={'telefono'}>
                            <Input bordered={false} style={{
                                color: '#FFFFFF',
                                borderRadius: '0px',
                                textAlign: 'left',
                                border: 'none',
                                borderBottom: '1px solid #FFFFFF',
                                width: '100%'}}
                                   placeholder="Telefono" disabled={!isEdit} />
                        </Form.Item>
                    </Card>
                </Col>
            </Row>
            {/*<Row justify={'center'} align={'top'}>*/}
            {/*    <Col span={24} style={{ padding: '20px' }}>*/}
            {/*        <Card*/}
            {/*              style={{border: 'none', boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)', textAlign: 'left', borderRadius: '20px', width: '100', background: '#c64141', color: '#FFFFFF' }}>*/}
            {/*            <Row gutter={[16, 32]} style={{marginBottom: '10px'}}>*/}
            {/*                <Col span={22}>*/}
            {/*                   <Title level={5} style={{color: '#FFFFFF'}}>Dati Pagamento</Title>*/}
            {/*                </Col>*/}
            {/*                <Col span={2}>*/}
            {/*                    <EditFilled style={{color: '#fad1b7'}} onClick={() => setIsEdit(!isEdit)}/>*/}
            {/*                </Col>*/}
            {/*            </Row>*/}
            {/*            <Row justify={'center'} align={'top'}>*/}
            {/*                <Col span={8}>*/}
            {/*                    <CreditCardOutlined style={{textAlign: 'center', fontSize: '30px', width: '80px', height: '60px'}}/>*/}
            {/*                </Col>*/}
            {/*                <Col span={8}>*/}
            {/*                    <PayPalIcon style={{textAlign: 'center', fontSize: '30px', width: '80px', height: '60px'}}/>*/}
            {/*                </Col>*/}
            {/*                <Col span={8}>*/}
            {/*                    <CreditCardFilled style={{textAlign: 'center', fontSize: '30px', width: '80px', height: '60px'}}/>*/}
            {/*                </Col>*/}
            {/*            </Row>*/}
            {/*            <Row>*/}
            {/*                <Col span={24}>*/}
            {/*                    <Form.Item name={'cap'}>*/}
            {/*                        <Input bordered={false} style={{*/}
            {/*                            color: '#FFFFFF',*/}
            {/*                            borderRadius: '0px',*/}
            {/*                            textAlign: 'left',*/}
            {/*                            border: 'none',*/}
            {/*                            borderBottom: '1px solid #FFFFFF',*/}
            {/*                            width: '100%'}}*/}
            {/*                               placeholder="Circuito Carta" type={'number'} disabled={!isEdit} />*/}
            {/*                    </Form.Item>*/}
            {/*                </Col>*/}
            {/*            </Row>*/}
            {/*            <Row>*/}
            {/*                <Col span={11}>*/}
            {/*                    <Form.Item name={'cap'}>*/}
            {/*                        <Input bordered={false} style={{*/}
            {/*                            color: '#FFFFFF',*/}
            {/*                            borderRadius: '0px',*/}
            {/*                            textAlign: 'left',*/}
            {/*                            border: 'none',*/}
            {/*                            borderBottom: '1px solid #FFFFFF',*/}
            {/*                            width: '100%'}}*/}
            {/*                               placeholder="Titolare Carta" type={'text'} disabled={!isEdit} />*/}
            {/*                    </Form.Item>*/}
            {/*                </Col>*/}
            {/*                <Col span={13}>*/}
            {/*                    <Form.Item name={'dataNascita'}>*/}
            {/*                        <Input bordered={false} style={{*/}
            {/*                            color: '#FFFFFF',*/}
            {/*                            borderRadius: '0px',*/}
            {/*                            textAlign: 'left',*/}
            {/*                            border: 'none',*/}
            {/*                            borderBottom: '1px solid #FFFFFF',*/}
            {/*                            width: '100%'}}*/}
            {/*                               placeholder="Scadenza" type={'date'} disabled={!isEdit} />*/}
            {/*                    </Form.Item>*/}
            {/*                </Col>*/}
            {/*            </Row>*/}
            {/*        </Card>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            <Row justify={'center'} align={'top'} hidden={(tipologia === 'lover')}>
                <Col span={24} style={{ padding: '20px' }}>
                    <Card bordered={false}
                          style={{ boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)', textAlign: 'left', borderRadius: '20px', width: '100', background: '#93292a', color: '#FFFFFF' }}>
                        <Row gutter={[16, 32]} style={{marginBottom: '10px'}}>
                            <Col span={22}>
                                <Title level={5} style={{color: '#FFFFFF'}}>Scheda Servizi</Title>
                            </Col>
                            <Col span={2}>
                                <EditFilled style={{color: '#fad1b7'}} onClick={() => setIsEdit(!isEdit)}/>
                            </Col>
                        </Row>
                        <Row justify={'center'} align={'middle'} style={{marginBottom: '20px'}} gutter={[48,48]}>
                            <Col span={8}>
                                <input type={'checkbox'} style={{
                                    background: '#443334',
                                    borderRadius: '50px !important',
                                    verticalAlign: 'middle',
                                    border: '2px solid #ddd',
                                    cursor: 'pointer',
                                    width: '50px',
                                    height: '50px'}} />
                                <span style={{fontSize: '12px', textAlign: 'center'}}>Servizio</span>
                            </Col>
                            <Col span={8}>
                                <input type={'checkbox'} style={{
                                    background: '#443334',
                                    borderRadius: '50px !important',
                                    verticalAlign: 'middle',
                                    border: '2px solid #ddd',
                                    cursor: 'pointer',
                                    width: '50px',
                                    height: '50px'}} />
                                <span style={{fontSize: '12px', textAlign: 'center'}}>Servizio</span>
                            </Col>
                            <Col span={8}>
                                <input type={'checkbox'} style={{
                                    background: '#443334',
                                    borderRadius: '50px !important',
                                    verticalAlign: 'middle',
                                    border: '2px solid #ddd',
                                    cursor: 'pointer',
                                    width: '50px',
                                    height: '50px'}} />
                                <span style={{fontSize: '12px', textAlign: 'center'}}>Servizio</span>
                            </Col>
                        </Row>
                        <Row justify={'center'} align={'middle'} style={{marginBottom: '20px'}} gutter={[48,48]}>
                            <Col span={8}>
                                <input type={'checkbox'} style={{
                                    background: '#443334',
                                    borderRadius: '50px !important',
                                    verticalAlign: 'middle',
                                    border: '2px solid #ddd',
                                    cursor: 'pointer',
                                    width: '50px',
                                    height: '50px'}} />
                                <span style={{fontSize: '12px', textAlign: 'center'}}>Servizio</span>
                            </Col>
                            <Col span={8}>
                                <input type={'checkbox'} style={{
                                    background: '#443334',
                                    borderRadius: '50px !important',
                                    verticalAlign: 'middle',
                                    border: '2px solid #ddd',
                                    cursor: 'pointer',
                                    width: '50px',
                                    height: '50px'}} />
                                <span style={{fontSize: '12px', textAlign: 'center'}}>Servizio</span>
                            </Col>
                            <Col span={8}>
                                <input type={'checkbox'} style={{
                                    background: '#443334',
                                    borderRadius: '50px !important',
                                    verticalAlign: 'middle',
                                    border: '2px solid #ddd',
                                    cursor: 'pointer',
                                    width: '50px',
                                    height: '50px'}} />
                                <span style={{fontSize: '12px', textAlign: 'center'}}>Servizio</span>
                            </Col>
                        </Row>
                        <Row justify={'center'} align={'middle'} style={{marginBottom: '20px'}} gutter={[48,48]}>
                            <Col span={8}>
                                <input type={'checkbox'} style={{
                                    background: '#443334',
                                    borderRadius: '50px !important',
                                    verticalAlign: 'middle',
                                    border: '2px solid #ddd',
                                    cursor: 'pointer',
                                    width: '50px',
                                    height: '50px'}} />
                                <span style={{fontSize: '12px', textAlign: 'center'}}>Servizio</span>
                            </Col>
                            <Col span={8}>
                                <input type={'checkbox'} style={{
                                    background: '#443334',
                                    borderRadius: '50px !important',
                                    verticalAlign: 'middle',
                                    border: '2px solid #ddd',
                                    cursor: 'pointer',
                                    width: '50px',
                                    height: '50px'}} />
                                <span style={{fontSize: '12px', textAlign: 'center'}}>Servizio</span>
                            </Col>
                            <Col span={8}>
                                <input type={'checkbox'} style={{
                                    background: '#443334',
                                    borderRadius: '50px !important',
                                    verticalAlign: 'middle',
                                    border: '2px solid #ddd',
                                    cursor: 'pointer',
                                    width: '50px',
                                    height: '50px'}} />
                                <span style={{fontSize: '12px', textAlign: 'center'}}>Servizio</span>
                            </Col>
                        </Row>

                    </Card>
                </Col>
            </Row>
            {/*<Row justify={'center'} align={'top'}>*/}
            {/*    <Col span={24} style={{ padding: '20px' }}>*/}
            {/*        <Card bordered={false}*/}
            {/*              style={{ textAlign: 'left', borderRadius: '20px', width: '100', background: '#93292a', color: '#FFFFFF' }}>*/}
            {/*            <Row gutter={[16, 32]} style={{marginBottom: '10px'}}>*/}
            {/*                <Col span={22}>*/}
            {/*                    <Title level={5} style={{color: '#FFFFFF'}}>Password</Title>*/}
            {/*                </Col>*/}
            {/*                <Col span={2}>*/}
            {/*                    <EditFilled style={{color: '#fad1b7'}} onClick={() => setIsEdit(!isEdit)}/>*/}
            {/*                </Col>*/}
            {/*            </Row>*/}
            {/*            <Form.Item name={'password'}>*/}
            {/*                <Input bordered={false} style={{*/}
            {/*                    color: '#FFFFFF',*/}
            {/*                    borderRadius: '0px',*/}
            {/*                    textAlign: 'left',*/}
            {/*                    border: 'none',*/}
            {/*                    borderBottom: '1px solid #FFFFFF',*/}
            {/*                    width: '100%'}}*/}
            {/*                       placeholder="Password" type={'password'} disabled={!isEdit} />*/}
            {/*            </Form.Item>*/}
            {/*        </Card>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            <Row justify={'center'} align={'top'}>
                <Col span={24}>
                    <Form.Item>
                        <Button type="primary" htmlType={'submit'}
                                style={{border: 'none', boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)', background: '#93292a', borderRadius: '30px', width: '160px', height: '50px'}}
                        >Aggiorna</Button>
                    </Form.Item>
                </Col>
            </Row>
            </Form>
        </>
    );
};
