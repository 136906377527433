import {
    CrudFilters,
    IResourceComponentsProps, useList,
} from '@pankod/refine-core';
import {
    Avatar,
    Image,
    Typography,
    Card,
    Row,
    Col, useTable, Input, Skeleton
} from '@pankod/refine-antd';
import {
    UserSwitchOutlined, BarsOutlined, UserOutlined, MinusOutlined, PlusOutlined, DeleteOutlined
} from '@ant-design/icons';
import React, {useEffect, useState} from 'react';
import jwt_decode from 'jwt-decode';
import {API_URL, TOKEN_KEY} from '../../constants';
import {DeliveryMap} from '../../components/deliveryMap';
import {IBasketListItem, IProduct, IUserFriend} from '../../interfaces';
import {Button} from 'antd-mobile';
const { Title } = Typography;
const { Meta } = Card;

export const NetworkList: React.FC<IResourceComponentsProps> = () => {
    // @ts-ignore
    const decoded: any = jwt_decode(localStorage.getItem(TOKEN_KEY));
    const apiImage = API_URL;
    const [tipo, setTipo] = useState('amici');
    const friends = useTable<IUserFriend>(
        {
            resource: 'user-friends',
            metaData: {
                populate: "users,users.avatar,friends, friends.avatar",
            },
            permanentFilter: [
                {
                    field: "users][id]",
                    operator: "eq",
                    value: decoded?.id
                }
            ],
            onSearch: (params: any) => {
                const filters: CrudFilters = [];
                const { description } = params;

                filters.push(
                    {
                        operator: 'or',
                        value : [
                            {
                                field: "friends][cognome]",
                                operator: "contains",
                                value: description,
                            },
                            {
                                field: "friends][nome]",
                                operator: "contains",
                                value: description,
                            },
                            {
                                field: "friends][nickname]",
                                operator: "contains",
                                value: description,
                            },
                        ],
                    }
                );

                return filters;
            },
        });

    console.log(friends);
    useEffect(() => {

    });

    const searchFriend = (e: any) => {
        console.log(e.target.value);
    }
    return (
        <>
            <Row justify="center" align="top" style={{marginTop: '30px'}}>
                {/*<Col span={24}>*/}
                {/*    <Button shape={'rounded'} style={{*/}
                {/*        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',*/}
                {/*        border: tipo === 'feed' ? '3px #ffe35e solid' : 'none',*/}
                {/*        fontSize: '20px',*/}
                {/*        color: '#800100',*/}
                {/*        background: '#ffffff',*/}
                {/*        width: '50px',*/}
                {/*        height: '50px',*/}
                {/*        marginTop: '30px'*/}
                {/*    }}*/}
                {/*            onClick={() => setTipo('feed')}*/}
                {/*    >*/}
                {/*        <BarsOutlined />*/}
                {/*    </Button>*/}
                {/*    <p style={{color: '#FFFFFF'}}>Feed</p>*/}
                {/*</Col>*/}
                {/*<Col span={6}>*/}
                {/*    <Button shape={'rounded'} style={{*/}
                {/*        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',*/}
                {/*        border: tipo === 'amici' ? '3px #ffe35e solid' : 'none',*/}
                {/*        fontSize: '20px',*/}
                {/*        color: '#FFFFFF',*/}
                {/*        background: '#800100FF',*/}
                {/*        width: '50px',*/}
                {/*        height: '50px',*/}
                {/*        marginTop: '30px'*/}
                {/*    }}*/}
                {/*            onClick={() => setTipo('amici')}*/}
                {/*    >*/}
                {/*        <UserSwitchOutlined />*/}
                {/*    </Button>*/}
                {/*    <p style={{color: '#FFFFFF'}}>Amici</p>*/}
                {/*</Col>*/}
                {/*<Col span={6}>*/}
                {/*    <Button shape={'rounded'} style={{*/}
                {/*        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',*/}
                {/*        border: tipo === 'profilo' ? '3px #ffe35e solid' : 'none',*/}
                {/*        fontSize: '20px',*/}
                {/*        color: '#800100',*/}
                {/*        background: '#ffffff',*/}
                {/*        width: '50px',*/}
                {/*        height: '50px',*/}
                {/*        marginTop: '30px'*/}
                {/*    }}*/}
                {/*            onClick={() => setTipo('profilo')}*/}
                {/*    >*/}
                {/*        <UserOutlined />*/}
                {/*    </Button>*/}
                {/*    <p style={{color: '#FFFFFF'}}>Profilo</p>*/}
                {/*</Col>*/}
                <Col span={24}>
                    {/*<Title level={4} style={{*/}
                    {/*    color: '#8E201BFF',*/}
                    {/*    marginTop: '50px'*/}
                    {/*}}>Coffe lovers</Title>*/}
                    <Title level={5} style={{
                        color: '#8E201BFF',
                        marginTop: '10px'
                    }}>Coffe Lovers</Title>
                </Col>
                <Col span={18}>
                    <Input bordered={false} style={{
                        color: '#000000',
                        borderRadius: '0px',
                        marginTop: '15px',
                        textAlign: 'left',
                        border: 'none',
                        borderBottom: '2px solid #FFFFFF',
                        width: '100%'}}
                           onChange={(e) => searchFriend(e)}
                           placeholder="cerca..." />
                </Col>
            </Row>
            <Row justify="center" align="top" style={{marginTop: '10px'}}>
                <Col span={32}>
                    {friends && friends.tableQueryResult.data?.data.map( (pro: any, i) => (
                        <Card key={pro.id}
                              bordered={false}
                              style={{
                                  width: 300,
                                  marginTop: 10,
                                  borderRadius: 20,
                                  border: 'none',
                                  boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                  background: '#f4d3ba' }}
                        >
                            <Skeleton loading={false} avatar active>
                                <Meta
                                    avatar={<Image src={apiImage + '' + pro?.friends?.avatar?.url} style={{width: 60, borderRadius: 10}} />}
                                    title={<div style={{color: '#932928FF'}}>{pro.friends?.nome + ' ' + pro.friends?.cognome}</div>}
                                    description=""
                                />
                            </Skeleton>
                        </Card>
                    ))}
                </Col>
            </Row>
        </>
    );
};
