import {Refine} from '@pankod/refine-core';
import {
  ReadyPage,
  ErrorComponent,
  ConfigProvider
} from '@pankod/refine-antd';
import "@pankod/refine-antd/dist/styles.min.css";
import routerProvider from "@pankod/refine-react-router-v6";
import { DataProvider } from "@pankod/refine-strapi-v4";
import { authProvider } from "./authProvider";
import de_DE from "antd/lib/locale/de_DE";
import dayjs from "dayjs";
import "dayjs/locale/de";
import "styles/antd.less";
import {API_URL, SOCKET_URL} from './constants';
import {
  Title,
  OffLayoutArea,
} from "components/layout";
import { useTranslation } from "react-i18next";
import {useEffect, useState} from 'react';
import {ChoicePage} from './pages/register/choice';
import {RegisterPage} from './pages/register';
import {LoginPage} from './pages/login';
import {Home} from './pages/home';
import {ProfileList} from './pages/profile';
import {Top} from './components/layout/top';
import {SuccessPage} from './pages/register/success';
import {ProductList} from './pages/product';
import {LocatorList} from './pages/locator';
import {CheckoutList} from './pages/checkout';
import {ConfirmedList} from './pages/confirmed';
import {AcquistaList} from './pages/acquista';
import {PagamentoList} from './pages/pagamento';
import {notificationProvider} from './notificationProvider';
import {InviaList} from './pages/invia';
import {CompletatoList} from './pages/invia/completato';
import {InviatoList} from './pages/invia/inviato';
import {UseList} from './pages/usa';
import {QrList} from './pages/usa/qr';
import {NetworkList} from './pages/network';
import {LandingList} from './pages/usa/landing';
import {QrCode} from './pages/qrcode';
import { TOKEN_KEY } from "./constants";
import axios, {AxiosRequestConfig} from 'axios';
import {io} from 'socket.io-client';
import {apiFunction} from './apiFuntion';
import {Toast} from 'antd-mobile';
import {ResetList} from './pages/login/reset';
import {MovimentiList} from './pages/movimenti';
const axiosInstance = axios.create();
export const socket = io(SOCKET_URL, {
    path: "/strapi/socket.io",
    forceNew: true,
    reconnectionAttempts: 3,
    timeout: 3000,
    query: { token: 'efb8badb8ea765450e591cc32efc1934ca996f4263d461b39408a31f1dc2e938cd9df0e9051640ec5d572502b92625cab239b314862abc7fda2bf641123f297cfe769b1686e29ea202e7633608f4dc8d2496656d61cdd54d302a477e05dba85927ef7c32accc0b22b5d38f22cd1318511b323fbe1569ae647c45e1127644f85a' },
});

axiosInstance.interceptors.request.use(
    // Here we can perform any function we'd like on the request
    (request: AxiosRequestConfig) => {
        // Retrieve the token from local storage
        const token = localStorage.getItem(TOKEN_KEY);
        // Check if the header property exists
        if (request.headers && token) {
            // Set the Authorization header if it exists
            request.headers[
                "Authorization"
                ] = `Bearer ${token}`;
        } else {
            // Create the headers property if it does not exist
            // request.headers = {
            //     Authorization: `Bearer ${token}`,
            // };
        }

        return request;
    },
);


function App() {
  const { t, i18n } = useTranslation();
  const token = localStorage.getItem(TOKEN_KEY);
  const id = localStorage.getItem('id');
  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const locale = i18nProvider.getLocale();

  useEffect(() => {
    if (locale === "de") {
      dayjs.locale("de");
    } else {
      dayjs.locale("en");
    }
  }, [locale]);


  useEffect( () => {
      if (token && id) {
        console.log(token);
        apiFunction.setSocketId(token, Number(id), socket.id).then( (res: any) => {
            console.log(res);
        });
      }
      if (id) {
          socket.on("create", (data) => {
              console.log('Ricevo Socket Create:', data.attributes.descrizione);
              Toast.show('Hai Ricevuto un Coffee Gift in Regalo!');
          })
          socket.on("update", (data) => {
              console.log('Ricevo Socket Update:', data)
          })
          socket.on("find", (data) => {
              console.log('Ricevo Socket Ricerca:', data)
          })
          socket.on("delete", (data) => {
              console.log('Ricevo Socket Delete:', data)
          })
      }
  })

    return (
        <ConfigProvider locale={locale === 'de' ? de_DE : undefined}>
                <Refine
                    notificationProvider={notificationProvider}
                    ReadyPage={ReadyPage}
                    catchAll={<ErrorComponent/>}
                    routerProvider={{
                        ...routerProvider,
                        routes  : [
                            {
                                element: <LoginPage/>,
                                path: '/',
                            },
                            {
                                element: <Home/>,
                                path: '/home',
                            },
                            {
                                element: <RegisterPage/>,
                                path: '/register',
                            },
                            {
                                element: <ResetList/>,
                                path: '/reset',
                            },
                            {
                                element: <ChoicePage/>,
                                path: '/choice',
                            },
                            {
                                element: <SuccessPage/>,
                                path: '/success',
                            },
                            {
                                element: <LandingList/>,
                                path: '/landing',
                            },
                        ],
                    }}
                    authProvider={authProvider}
                    // @ts-ignore
                    dataProvider={DataProvider(API_URL + `/api`, axiosInstance)}
                    LoginPage={LoginPage}
                    Title={Title}
                    Layout={({children, Footer, OffLayoutArea}) => (
                        <Top>
                            {children}
                        </Top>
                    )}
                    OffLayoutArea={OffLayoutArea}
                    warnWhenUnsavedChanges={true}
                    i18nProvider={i18nProvider}
                    resources={[
                        {name: 'home', list: Home},
                        {name: 'qrcode', list: QrCode},
                        {name: 'product', list: ProductList},
                        {name: 'profile', list: ProfileList},
                        {name: 'locator', list: LocatorList},
                        {name: 'checkout', list: CheckoutList},
                        {name: 'confirmed', list: ConfirmedList},
                        {name: 'acquista', list: AcquistaList},
                        {name: 'pagamento', list: PagamentoList},
                        {name: 'invia', list: InviaList},
                        {name: 'completato', list: CompletatoList},
                        {name: 'inviato', list: InviatoList},
                        {name: 'use', list: UseList},
                        {name: 'generaCode', list: QrList},
                        {name: 'network', list: NetworkList},
                        {name: 'movimenti', list: MovimentiList},
                        {name: 'login', list: LoginPage}
                    ]}
                />
        </ConfigProvider>
  );
}

export default App;
