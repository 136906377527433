import {
    IResourceComponentsProps, useCustom,
} from '@pankod/refine-core';
import {
    Typography,
    Card,
    Row,
    Col,
    Button, Image, Form, Input, AntdLayout
} from '@pankod/refine-antd';
import {useLocation, useNavigate} from 'react-router-dom';
import React, {useState} from 'react';
import {Toast} from 'antd-mobile';
import {API_URL} from '../../constants';
import {apiFunction} from '../../apiFuntion';

const { Title } = Typography;


export const ResetList: React.FC<IResourceComponentsProps> = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    function resetPassword() {
        if (password === confirmPassword && password && confirmPassword) {
            apiFunction.resetPassword(location.search.split('?')[1].split('=')[1], password, confirmPassword).then( (res: any) => {
                console.log(res);
                if (res) {
                    Toast.show('Password Modificata con Successo');
                    setTimeout( () => {
                        navigate('/login')
                    }, 2000)
                } else {
                    Toast.show('Si è verificato un errore!')
                }
            })
        } else {
            Toast.show('Le password non coincidono');
        }
    }
    return (
        <>
            <Row
                justify="center"
                align="middle"
                style={{
                    marginTop: 150
                }}
            >
                <Col xs={22}>
                    <div style={{maxWidth: '408px', margin: 'auto'}}>
                        <Image
                            preview={false}
                            style={{marginBottom: 20}}
                            src="/images/logoSugar-removebg-preview.png"
                            width="100%"
                            onClick={ () => {
                                navigate('/login');
                            }}
                        />
                        <div style={{maxWidth: '408px', margin: 'auto', textAlign: 'center'}}>
                            <Title level={3} className="layout-title">
                                Reset Password
                            </Title>
                        </div>
                    </div>
                </Col>
                <Col xs={14}>
                        <Input placeholder="New Password" style={{
                            marginTop: 50,
                            marginBottom: 10
                        }}
                               onChange={ (val: any) => {
                                   setPassword(val.target.value)
                               }}
                        />
                        <Input placeholder="Confirm Password"
                               onChange={ (val: any) => {
                                   setConfirmPassword(val.target.value)
                               }}
                        />
                        <Button type="primary" shape="round"
                                size={'large'} />
                </Col>
            </Row>
            <Row justify="center" align="top" style={{marginTop: '30px'}}>
                <Col span={10}>
                    <Button type="primary" htmlType={'submit'}
                            style={{boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)', background: '#93292a', borderRadius: '30px', width: '150px', height: '40px', border: 'none'}} onClick={() => resetPassword()}>Conferma</Button>
                </Col>
            </Row>
            </>
    );
};
