import {Image} from '@pankod/refine-antd';
import {Popup, Button, Grid} from 'antd-mobile'
import {Layout} from 'antd';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useLogout} from '@pankod/refine-core';
import {apiFunction} from '../../../apiFuntion';
import {TOKEN_KEY} from '../../../constants';
import jwt_decode from 'jwt-decode';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const {Content} = Layout;

export const Top: React.FC = ({children}) => {
    const navigate = useNavigate();
    const {mutate: logout} = useLogout();
    const tokenSession = localStorage.getItem(TOKEN_KEY);
    // @ts-ignore
    const decoded: any = localStorage.getItem(TOKEN_KEY) ? jwt_decode(localStorage.getItem(TOKEN_KEY)) : {id: 0};
    const [prefix, setPrefix] = useState('NN');
    const [state, setState] = useState({
        visible: false, placement: 'left'
    });

    function showDrawer() {
        setState({
            placement: '',
            visible: true
        });
    }

    function onClose() {
        setState({
            placement: '',
            visible: false
        });
    }

    function onChange(e: any) {
        setState({
            visible: false,
            placement: e.target.value
        });
    }

    function onNavigate(path: string) {
        navigate(path, { state: 'riscuotere', replace: true});
        onClose();
    }


    useEffect(() => {
        apiFunction.getUserData(tokenSession, decoded.id).then( (res: any) => {
            localStorage.setItem('tipologia', res.tipologia);
            setPrefix(res.nome ? (res.nome.charAt(0) + res.cognome.charAt(0)) : res.nickname.charAt(0) + res.nickname.charAt(1));
        }).catch( (e: any) => {
            console.log(e);
        })
    }, []);

    return (
        <>
            <div style={{
                background: '#FAD1B7',
                textAlign: 'center',
                position: 'fixed',
                zIndex: '999999',
                width: '100%'
            }}>
                <Grid columns={8} gap={32}>
                    <Grid.Item span={2}>
                        <div hidden={prefix === 'NN'}>
                            <Button type="button" onClick={() => navigate(-1)} style={{
                                // boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                border: 'none',
                                zIndex: '999999',
                                background: 'none',
                                color: '#E28A74',
                                fontSize: 30,
                                marginTop: 5
                            }}>
                                <FontAwesomeIcon icon={solid('arrow-left-long')} />
                            </Button>
                        </div>
                    </Grid.Item>
                    <Grid.Item span={4}>
                        <div>
                            <Image
                                style={{
                                    marginTop: 10
                                }}
                                preview={false} src={'/images/logo_header.png'} width={150}
                                   onClick={() => navigate('/home')}/>
                        </div>
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <div hidden={prefix === 'NN'}>
                            <Button type="button" onClick={showDrawer} style={{
                                // boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                border: 'none',
                                zIndex: '999999',
                                background: 'none',
                                color: '#E28A74',
                                fontSize: 30,
                                marginTop: 5
                            }}>
                                {/*{prefix.toUpperCase()}*/}
                                <FontAwesomeIcon icon={solid('grip-horizontal')} />
                            </Button>
                        </div>
                    </Grid.Item>
                </Grid>
            </div>
            <Layout>
                <Content style={{background: '#FAD1B7', textAlign: 'center', marginTop: '50px'}}>
                    {children}
                </Content>
            </Layout>
            {/*<Footer style={{background: '#ffd8af'}}></Footer>*/}

            <Popup style={{background: '#FAD1B7', textAlign: 'center', zIndex: '99999999'}}
                // title={<>
                //   <CloseOutlined onClick={onClose} style={{position: 'absolute', marginTop: '13px', marginLeft: '-110px', fontSize: 'x-large'}}/>
                //   <Image preview={false} src={'/images/logoSugar-removebg-preview.png'} width={100} onClick={() => navigate('/home')}/>
                // </>}
                   bodyStyle={{background: '#FAD1B7'}}
                   position={'right'}
                   onMaskClick={onClose}
                   afterClose={onClose}
                   visible={state.visible}
                   key={state.placement}
            >
                <Grid columns={2} gap={8} style={{width: 200}}>
                    <Grid.Item>
                        <Button shape={'rounded'}
                                onClick={() => onNavigate('/profile')}
                                style={{
                                    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                    border: 'none',
                                    fontSize: '30px',
                                    color: '#FFFFFF',
                                    background: '#800100FF',
                                    width: '60px',
                                    height: '60px',
                                    marginTop: '50px'
                                }}>
                            <FontAwesomeIcon icon={solid('user')} />
                        </Button>
                    </Grid.Item>
                    <Grid.Item>
                        <Button shape={'rounded'}
                                onClick={() => onNavigate('/product?page=riepilogo')}
                                style={{
                                    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                    border: 'none',
                                    fontSize: '30px',
                                    color: '#FFFFFF',
                                    background: '#e28a74',
                                    width: '60px',
                                    height: '60px',
                                    marginTop: '50px'
                                }}
                        >
                            <FontAwesomeIcon icon={solid('basket-shopping')} />
                        </Button>
                    </Grid.Item>
                </Grid>
                <Grid columns={2} gap={8}>
                    <Grid.Item>
                        <Button shape={'rounded'} style={{
                            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                            border: 'none',
                            fontSize: '30px',
                            color: '#FFFFFF',
                            background: '#e28a74',
                            width: '60px',
                            height: '60px',
                            marginTop: '50px'
                        }}>
                            <FontAwesomeIcon icon={solid('bell')} />
                        </Button>
                    </Grid.Item>
                    <Grid.Item>
                        <Button shape={'rounded'}
                                onClick={() => onNavigate('/network')}
                                style={{
                                    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                    border: 'none',
                                    fontSize: '30px',
                                    color: '#FFFFFF',
                                    background: '#800100',
                                    width: '60px',
                                    height: '60px',
                                    marginTop: '50px'
                                }}>
                            <FontAwesomeIcon icon={solid('network-wired')} />
                        </Button>
                    </Grid.Item>
                </Grid>
                <Grid columns={2} gap={8}>
                    <Grid.Item>
                        <Button shape={'rounded'}
                                onClick={() => onNavigate('/locator')}
                                style={{
                                    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                    border: 'none',
                                    fontSize: '30px',
                                    color: '#FFFFFF',
                                    background: '#800100FF',
                                    width: '60px',
                                    height: '60px',
                                    marginTop: '50px'
                                }}>
                            <FontAwesomeIcon icon={solid('location')} />
                        </Button>
                    </Grid.Item>
                    <Grid.Item>
                        <Button shape={'rounded'}
                                onClick={() => onNavigate('/movimenti')}
                                style={{
                                    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                    border: 'none',
                                    fontSize: '30px',
                                    color: '#FFFFFF',
                                    background: '#e28a74',
                                    width: '60px',
                                    height: '60px',
                                    marginTop: '50px'
                                }}>
                            <FontAwesomeIcon icon={solid('list-check')} />
                        </Button>
                    </Grid.Item>
                </Grid>
                <Grid columns={2} gap={8}>
                    <Grid.Item>
                        <Button shape={'rounded'}
                                onClick={() => onNavigate('/home')}
                                style={{
                                    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                    border: 'none',
                                    fontSize: '30px',
                                    color: '#FFFFFF',
                                    background: '#e28a74',
                                    width: '60px',
                                    height: '60px',
                                    marginTop: '50px'
                                }}>
                            <FontAwesomeIcon icon={solid('house')} />
                        </Button>
                    </Grid.Item>
                    <Grid.Item>
                        <Button shape={'rounded'}
                                onClick={() => logout()}
                                style={{
                                    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                    border: 'none',
                                    fontSize: '30px',
                                    color: '#FFFFFF',
                                    background: '#800100',
                                    width: '60px',
                                    height: '60px',
                                    marginTop: '50px'
                                }}>
                            <FontAwesomeIcon icon={solid('sign-out')} />
                        </Button>
                    </Grid.Item>
                </Grid>
            </Popup>
        </>
    );
};
