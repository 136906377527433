import {
    IResourceComponentsProps,
} from '@pankod/refine-core';
import {
    Avatar,
    Image,
    Typography,
    Card,
    Row,
    Col,
    Radio,
    useForm,
    Select,
    Input,
    Form,
    Button,
    Checkbox, ImageField, Upload,
    getValueFromEvent
} from '@pankod/refine-antd';
import Icon, {EditOutlined, ClockCircleOutlined, SearchOutlined, RightCircleOutlined, EditFilled, CreditCardFilled, CreditCardOutlined, AlipayCircleOutlined} from '@ant-design/icons';
import {IUser, IUserVariable} from '../../interfaces';
import React, {useEffect, useState} from 'react';
import jwt_decode from 'jwt-decode';
import {API_URL, TOKEN_KEY} from '../../constants';
import {DeliveryMap} from '../../components/deliveryMap';
const { Title } = Typography;

export const LocatorList: React.FC<IResourceComponentsProps> = () => {
    const [isEdit, setIsEdit] = useState(false);
    const [tipologia, setTipologia] = useState('');
    const [image, setImage] = useState('');
    const apiImage = API_URL;
    // @ts-ignore
    const decoded: any = jwt_decode(localStorage.getItem(TOKEN_KEY));
    useEffect(() => {

    });

    return (
        <>
            <div>
                <Row>
                    <Col span={24}>
                        <Title level={4} style={{
                            color: '#8E201BFF',
                            marginTop: '30px'
                        }}>Bar Locator</Title>
                    </Col>
                </Row>
            </div>
            <div style={{
                overflowY: 'auto',
                height: window.innerHeight - 200
            }}>
            <Row>
                <div style={{ height: '100vh', width: '100%' }}>
                    <DeliveryMap />
                </div>
            </Row>
            </div>
        </>
    );
};
