import {useTranslate} from '@pankod/refine-core';
import React from 'react';
import {
    Row,
    Col,
    AntdLayout,
    Card,
    Typography,
    Image,
} from "@pankod/refine-antd";

import {
    RightCircleFilled
} from '@ant-design/icons';
import {useLocation, useNavigate} from 'react-router-dom';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const { Title } = Typography;

export const ChoicePage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const token = decodeURIComponent(location.search)?.split('?')[1]?.split('&')[0].split('=')[1];
    const id = decodeURIComponent(location.search)?.split('?')[1]?.split('&')[1]?.split('=')[1];
    console.log(token, id);
    return (
        <AntdLayout
            style={{
                background: "#fad1b7"
            }}
        >
            <Row>
                <Col style={{textAlign: 'center'}}>
                    <Image
                        style={{ marginBottom: 26, marginTop: 10}}
                        src="/images/logo.png"
                        width="50%"
                        preview={false}
                    />
                    <Title level={3} style={{textAlign: 'center', color: '#800000'}}>Che Utente sei?</Title>
                </Col>
            </Row>
            <Row>
                <Col span={24} style={{textAlign: 'left'}}>
                        <Card style={{
                            border: 'none',
                            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                            margin: '15px',
                            padding: '8px',
                            borderRadius: '30px',
                            background: '#c64141',
                            height: '180px',
                            color: '#ffffff'}}
                              onClick={() => navigate('/register?type=lover', {state: {token, id}})}
                        >
                            <Title level={3} style={{color: '#FFFFFF'}}>Coffee user</Title>
                            <p>Breve spiegazione</p>
                            <p>del tipo utente</p>
                            <Row gutter={[16, 16]}>
                                <Col span={4} style={{textAlign: 'left'}}>
                                    <Title style={{color: '#fcefef', marginTop: '-15px'}}>...</Title>
                                </Col>
                                <Col span={4} style={{textAlign: 'right'}}>

                                </Col>
                                <Col span={4} style={{textAlign: 'right'}}>

                                </Col>
                                <Col span={4} style={{textAlign: 'right'}}>

                                </Col>
                                <Col span={4} style={{textAlign: 'right'}}>

                                </Col>
                                <Col span={4} style={{textAlign: 'right'}}>
                                    <FontAwesomeIcon
                                        style={{
                                            marginRight: -10
                                        }}
                                        fontSize={33}
                                        icon={solid('arrow-circle-right')} />
                                        {/*<RightCircleFilled*/}
                                        {/*    style={{fontSize: '25px'}}*/}
                                        {/*    onClick={() => navigate('/register?type=lover', {state: {token, id}})}/>*/}
                                </Col>
                            </Row>
                        </Card>
                </Col>
            </Row>
            <Row>
                <Col span={24} style={{textAlign: 'left'}}>
                    <Card style={{
                        border: 'none',
                        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                        margin: '15px',
                        padding: '8px',
                        borderRadius: '30px',
                        background: '#f28f8f',
                        height: '180px',
                        color: '#ffffff'}}
                          onClick={() => navigate('/register?type=bar')}
                    >
                        <Title level={3} style={{color: '#fcefef'}}>Bar</Title>
                        <p>Breve spiegazione</p>
                        <p>del tipo utente</p>
                        <Row gutter={[16, 16]}>
                            <Col span={4} style={{textAlign: 'left'}}>
                                <Title style={{color: '#fcefef', marginTop: '-15px'}}>...</Title>
                            </Col>
                            <Col span={4} style={{textAlign: 'right'}}>

                            </Col>
                            <Col span={4} style={{textAlign: 'right'}}>

                            </Col>
                            <Col span={4} style={{textAlign: 'right'}}>

                            </Col>
                            <Col span={4} style={{textAlign: 'right'}}>

                            </Col>
                            <Col span={4} style={{textAlign: 'right'}}>
                                <FontAwesomeIcon
                                    style={{
                                        marginRight: -10
                                    }}
                                    fontSize={33}
                                    icon={solid('arrow-circle-right')} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col span={24} style={{textAlign: 'left'}}>
                    <Card style={{
                        border: 'none',
                        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                        margin: '15px',
                        padding: '8px',
                        borderRadius: '30px',
                        background: '#800101',
                        height: '180px',
                        color: '#ffffff'}}
                          onClick={() => navigate('/register?type=retail')}
                    >
                        <Title level={3} style={{color: '#fcefef'}}>Retail</Title>
                        <p>Breve spiegazione</p>
                        <p>del tipo utente</p>
                        <Row gutter={[16, 16]}>
                            <Col span={4} style={{textAlign: 'left'}}>
                                <Title style={{color: '#fcefef', marginTop: '-15px'}}>...</Title>
                            </Col>
                            <Col span={4} style={{textAlign: 'right'}}>

                            </Col>
                            <Col span={4} style={{textAlign: 'right'}}>

                            </Col>
                            <Col span={4} style={{textAlign: 'right'}}>

                            </Col>
                            <Col span={4} style={{textAlign: 'right'}}>

                            </Col>
                            <Col span={4} style={{textAlign: 'right'}}>
                                <FontAwesomeIcon
                                    fontSize={33}
                                    style={{
                                        marginRight: -10
                                    }}
                                    icon={solid('arrow-circle-right')} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </AntdLayout>
    );
};
