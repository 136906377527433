import {Col, Icons, Row, Tooltip, Typography, useSelect} from '@pankod/refine-antd';
import {
    QrcodeOutlined
} from '@ant-design/icons';
import {Space, Spin } from 'antd';
import React, {useEffect, useState} from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import {Top} from '../../components/layout/top';
import jwt_decode from 'jwt-decode';
import {TOKEN_KEY} from '../../constants';
import {Button, Toast} from 'antd-mobile';
import {apiFunction} from '../../apiFuntion';
import {useCreate, useLogout, useUpdate} from '@pankod/refine-core';
import {IUser, IUserGift, IUserMovimentazioni} from '../../interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid} from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
const { Title } = Typography;


export const Home: React.FC = () => {
    const location = useLocation();
    // @ts-ignore
    const decoded: any = localStorage.getItem(TOKEN_KEY) ? jwt_decode(localStorage.getItem(TOKEN_KEY)) : {id: 0};
    const token = localStorage.getItem(TOKEN_KEY);
    const { mutate: logout } = useLogout();
    const tokenGift = decodeURIComponent(location.search)?.split('?')[1]?.split('&')[0].split('=')[1];
    const id = decodeURIComponent(location.search)?.split('?')[1]?.split('&')[1]?.split('=')[1];
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [credito, setCredito] = useState(0);
    const [tipologia, setTipologia] = useState('');
    const [isFirstLogin, setIsFirstLogin] = useState<boolean>();
    const [add, setAdd] = useState(false);
    const userUpdateMovimentazioni = useUpdate<IUserMovimentazioni>();
    const userUpdate = useUpdate<IUser>();
    const {mutate: addUserGift, context, error} = useCreate<IUserGift>();
    // const resultFriend = useList<IUserFriend>({
    //     resource: "user-friends",
    //     metaData: {
    //         populate: "*",
    //     },
    //     queryOptions: {
    //         enabled: false
    //     }
    // });
    // const friend = useCreate<IUserFriend>();
    // const addReceivedGift = (giftReceived: any) => {
    //     console.log(giftReceived)
    //     userUpdateMovimentazioni.mutate({
    //         resource: "user-movimentazionis",
    //         id: Number(giftReceived.id),
    //         values: {
    //             isUsed: true,
    //         }
    //     });
    //     if (userUpdateMovimentazioni.isError) throw 'Errore Update Movimentazioni';
    //     gift.mutate({
    //         resource: "user-gifts",
    //         values: {
    //             descrizione: "Ricevuto" + ' ' + giftReceived.attributes['user_gift'].data?.attributes.descrizione,
    //             user: decoded.id,
    //             prodotti: giftReceived.attributes['user_gift'].data?.attributes['prodotti']?.data?.id,
    //             tipo: 'ricevuto',
    //             quantita: 1
    //         }
    //     });
    //     if (gift.isError) throw 'Errore Recupero Gift';
    // }

    // async function getFriend(fromUser: any, toUser: any) {
    //     return new Promise<any>(resolve => {
    //         resultFriend.refetch().then( (res: any) => {
    //             // console.log(res);
    //             if (res.status === 'success') {
    //                 console.log(fromUser, toUser, res.data.data.findIndex( (x: IUserFriend) => (x.friends.id === fromUser && x.users.id === toUser)));
    //                 const fU = res.data.data.findIndex( (x: IUserFriend) => (x.friends.id === fromUser && x.users.id === toUser));
    //                 if (fU !== -1) {
    //                     resolve(true);
    //                 } else {
    //                     resolve(false);
    //                 }
    //             }
    //         }).catch( e => {
    //             console.log(e);
    //         });
    //     })
    // }

    // const addFriend = () => {
    //     getFriend(Number(id), Number(decoded.id)).then( (res: boolean) => {
    //         // console.log(res);
    //         if (!res) {
    //             friend.mutate({
    //                 resource: "user-friends",
    //                 values: {
    //                     descrizione: "Nuovo Collegamento",
    //                     isActive: true,
    //                     friends: id,
    //                     users: decoded.id
    //                 }
    //             });
    //             friend.mutate({
    //                 resource: "user-friends",
    //                 values: {
    //                     descrizione: "Nuovo Collegamento",
    //                     isActive: true,
    //                     friends: decoded.id,
    //                     users: id
    //                 }
    //             });
    //         }
    //     }).catch(e => {
    //         console.log(e);
    //     })
    // }

    useEffect(() => {
        if (decoded.id) {
            apiFunction.getUserData(token, decoded.id).then( (data: any) => {
                setIsFirstLogin(data.isFirstLogin);
                if (data.tipologia) {
                    setTipologia(data.tipologia);
                    // ADD GIFT AND FRIENDS
                    // if (data.tipologia === 'lover') {
                    //     if (tokenGift) {
                    //         apiFunction.getUserMovimentazioni(token, tokenGift).then( (res: any) => {
                    //             if (res && res.data?.data.length > 0) {
                    //                 setAdd(true);
                    //                 if (add) {
                    //                     apiFunction.addReceivedGift(res.data?.data[0], decoded.id, token);
                    //                     apiFunction.addFriend(Number(id), decoded.id, token);
                    //                 }
                    //             } else {
                    //                 setAdd(false);
                    //             }
                    //         })
                    //     }
                    // }
                }
                if (!data.nickname && localStorage.getItem('isProfileOk') === 'false') {
                    setLoading(true);
                    setTimeout(() => {
                        navigate('/profile')
                    }, 2000)
                }

                apiFunction.getUserCrediti(token, decoded.id).then( (queryResult: any) => {
                    setCredito(queryResult);
                });

            })
        }
    });

    console.log(tipologia);
    useEffect(() => {
        console.log(isFirstLogin)
        if (isFirstLogin) {
            userUpdate.mutate({
                resource: "users",
                id: decoded.id,
                values: {
                    isFirstLogin: false
                }
            })
            setTimeout(() => {
                addUserGift({
                    resource: "user-gifts",
                    values: {
                        descrizione: "Omaggio Gift",
                        tipo: 'acquistato',
                        user: decoded.id,
                        fromUser: decoded.id,
                        quantita: 1,
                        isOmaggio: true,
                        prodotti: 2
                    }
                });
                setIsFirstLogin(false);
                console.log(isFirstLogin)
                if (error) throw 'Errore';
            }, 2000)
        }
    }, [isFirstLogin])

    function onNavigate(path: string) {
        navigate(path, { replace: true });
    }

    return (
        <>
            <div
                hidden={!loading}
                style={{
                    position: 'absolute',
                    marginTop: '80%',
                    left: '50%',
                    transform: 'translate(-50%, 0)'
                }}>
                <Space size="large">
                    <Spin
                        style={{
                            color: '#ffffff',
                        }}
                        tip="Redirecting to Profile..." size="large"/>
                </Space>
            </div>
                <div>
                <Top>
                    <Row justify="end" align="top" style={{marginTop: 30, marginRight: 5}}>
                    {/*    {decoded.id > 0 &&*/}
                    {/*        <Col hidden={true}>*/}
                    {/*            /!*<Title level={5}>Saldo: {credito} €</Title>*!/*/}
                    {/*            <Button shape={'rounded'} style={{*/}
                    {/*                border: 'none',*/}
                    {/*                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',*/}
                    {/*                fontSize: '16px',*/}
                    {/*                color: '#ffffff',*/}
                    {/*                background: '#b66868',*/}
                    {/*                width: '100px',*/}
                    {/*                height: '60px',*/}
                    {/*                marginTop: '0px'*/}
                    {/*            }}*/}
                    {/*                    onClick={() => navigate('/acquista')}*/}
                    {/*            >*/}
                    {/*                Saldo: {credito.toLocaleString(undefined, {*/}
                    {/*                minimumFractionDigits: 2,*/}
                    {/*                maximumFractionDigits: 2*/}
                    {/*            })} €*/}
                    {/*            </Button>*/}
                    {/*        </Col>*/}
                    {/*    }*/}
                    </Row>
                    {tipologia === 'lover' &&
                        <>
                            <Row justify="center" align="top">
                                <Col span={10}>
                                    <Tooltip placement="top" title={'Acquista'}>
                                        <Button shape={'rounded'} style={{
                                            border: 'none',
                                            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                            fontSize: '50px',
                                            color: '#FFFFFF',
                                            background: '#800100FF',
                                            width: '148px',
                                            height: '148px',
                                            marginTop: '50px'
                                        }}
                                                onClick={() => navigate('/product?page=riepilogo')}>
                                            <FontAwesomeIcon icon={solid('money-bill-wave')} />
                                            <Title level={5} style={{
                                                color: 'white'
                                            }}>Acquista</Title>
                                        </Button>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row justify="center" align="top">
                                <Col span={10}>
                                    <Tooltip placement="top" title={'Invia'}>
                                        <Button shape={'rounded'} style={{
                                            border: 'none',
                                            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                            fontSize: '50px',
                                            color: '#FFFFFF',
                                            background: '#E28A74FF',
                                            width: '148px',
                                            height: '148px',
                                            marginTop: '30px'
                                        }}
                                                onClick={() => navigate('/invia', {state: 'tutti'})}>
                                            <FontAwesomeIcon icon={solid('paper-plane')} />
                                            <Title level={5} style={{
                                                color: 'white'
                                            }}>Invia</Title>
                                        </Button>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row justify="center" align="top">
                                <Col span={10}>
                                    <Tooltip placement="top" title={'Usa'}>
                                        <Button shape={'rounded'} style={{
                                            border: 'none',
                                            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                            fontSize: '50px',
                                            color: '#FFFFFF',
                                            background: '#C64141FF',
                                            width: '148px',
                                            height: '148px',
                                            marginTop: '30px'
                                        }}
                                                onClick={() => navigate('/use' + location.search, {state: 'tutti'})}>
                                            <FontAwesomeIcon icon={solid('qrcode')} />
                                            <Title level={5} style={{
                                                color: 'white'
                                            }}>Consuma</Title>
                                        </Button>
                                    </Tooltip>
                                </Col>
                            </Row>
                            </>
                    }
                    {tipologia === 'bar' &&
                        <>
                            <Row justify="center" align="top">
                                <Col span={10}>
                                    <Tooltip placement="top" title={'Usa'}>
                                        <Button shape={'rounded'} style={{
                                            border: 'none',
                                            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                            fontSize: '50px',
                                            color: '#FFFFFF',
                                            background: '#800100FF',
                                            width: '148px',
                                            height: '148px',
                                            marginTop: '30px'
                                        }}
                                                onClick={() => navigate('/qrcode')}>
                                            <FontAwesomeIcon icon={solid('qrcode')} />
                                            <Title level={5} style={{
                                                color: 'white'
                                            }}>Leggi QR</Title>
                                        </Button>
                                    </Tooltip>
                                </Col>
                            </Row>
                            {/*<Row>*/}
                            {/*    <Col span={24} style={{*/}
                            {/*        marginTop: 10*/}
                            {/*    }}>*/}
                            {/*        <Title level={3}>Scannerizza Qr Code</Title>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            <Row justify="center" align="top">
                                <Col span={10}>
                                    <Tooltip placement="top" title={'Usa'}>
                                        <Button shape={'rounded'} style={{
                                            border: 'none',
                                            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                            fontSize: '50px',
                                            color: '#FFFFFF',
                                            background: '#e28a74',
                                            width: '148px',
                                            height: '148px',
                                            marginTop: '30px'
                                        }}
                                                onClick={() => navigate('/movimenti', { state: 'riscuotere'})}>
                                            <FontAwesomeIcon icon={solid('list-check')} />
                                            <Title level={5} style={{
                                                color: 'white'
                                            }}>Movimenti</Title>
                                        </Button>
                                    </Tooltip>
                                </Col>
                            </Row>
                            {/*<Row justify="center" align="top">*/}
                            {/*    <Col span={10}>*/}
                            {/*        <Button shape={'rounded'} style={{*/}
                            {/*            border: 'none',*/}
                            {/*            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',*/}
                            {/*            fontSize: '30px',*/}
                            {/*            color: '#FFFFFF',*/}
                            {/*            background: '#C64141FF',*/}
                            {/*            width: '140px',*/}
                            {/*            height: '140px',*/}
                            {/*            marginTop: '30px'*/}
                            {/*        }}*/}
                            {/*                onClick={() => navigate('/login')}>*/}
                            {/*            <Icons.LogoutOutlined style={{*/}
                            {/*                fontSize: 70*/}
                            {/*            }} />*/}
                            {/*            <Title level={4}>Esci</Title>*/}
                            {/*        </Button>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                        </>
                    }
                </Top>
            </div>
        </>
    );
}
