import {
    IResourceComponentsProps, useCreate,
} from '@pankod/refine-core';
import {
    Avatar,
    Image,
    Typography,
    Card,
    Row,
    Col, Skeleton, useSelect
} from '@pankod/refine-antd';
import {Button, Modal, Toast} from 'antd-mobile'
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {IBasketListItem, IUserCrediti, IUserGift} from '../../interfaces';
import {API_URL, TOKEN_KEY} from '../../constants';
import jwt_decode from 'jwt-decode';
import {ShoppingCartOutlined} from '@ant-design/icons';
const { Title } = Typography;
const { Meta } = Card;


export const CheckoutList: React.FC<IResourceComponentsProps> = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    // @ts-ignore
    const decoded: any = localStorage.getItem(TOKEN_KEY) ? jwt_decode(localStorage.getItem(TOKEN_KEY)) : {id: 0};
    const [cart, setCart] = useState([]);
    const apiImage = API_URL;
    const [credito, setCredito] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const gift = useCreate<IUserGift>();
    const crediti = useCreate<IUserCrediti>();
    const { queryResult } = useSelect<IUserCrediti>({
        resource: "user-creditis",
        metaData: {
            populate: "user",
        },
        filters: [
            {
                field: "user][id]",
                operator: "eq",
                value: decoded?.id
            }
        ]
    });

    useEffect(() => {
         // @ts-ignore
        setCart(location.state.data);
        console.log(cart);
        if (queryResult.status === 'success') {
            setCredito(queryResult.data.data.reduce((total,currentItem: IUserCrediti) =>  total = total + currentItem.importo , 0 ));
        }
    });

    const total = (cart.reduce((total,currentItem: IBasketListItem) =>  total = total + currentItem.importo , 0 ));

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        navigate('/acquista');
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const saveCredito = () => {
        crediti.mutate({
            resource: "user-creditis",
            values: {
                descrizione: "Acquisto Gift",
                user: decoded.id,
                importo: - total
            }
        });
    }

    const saveData = () => {
        cart.forEach( (c: any) => {
            gift.mutate({
                resource: "user-gifts",
                values: {
                    descrizione: "Acquisto" + ' ' + c.item.descrizione,
                    user: decoded.id,
                    fromUser: decoded.id,
                    prodotti: c.item.id,
                    tipo: 'acquistato',
                    quantita: c.quantita
                }
            });
        });
        localStorage.setItem('basket', '');
        saveCredito();
        navigate('/completato', {state: []})
    }

    function confermaOrdine() {
        if (Number(credito) < total) {
            console.log('CREDITO INSUFFICENTE');
            Modal.show({
                content: 'Credito insufficiente',
                closeOnAction: true,
                actions: [
                    {
                        key: 'ok',
                        text: 'Acquista Crediti',
                        primary: true,
                    },
                    {
                        key: 'ko',
                        text: 'Annulla',
                    },
                ],
                onAction: (e) => {
                    if (e.key === 'ok') {
                        handleOk();
                    } else {
                        handleCancel();
                    }
                }
            })
        } else {
            // console.log('ORDINE CONFERMATO:', cart);
            saveData()
        }
    }

    function go(path: string) {
            navigate(path, { replace: true, state: {data: cart} });

    }

    return (
        <>
            <Row justify="center" align="top">
                <Col span={10}>
                    <Button shape={'rounded'} style={{
                        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                        border: 'none',
                        fontSize: '30px',
                        color: '#FFFFFF',
                        background: '#800100FF',
                        width: '100px',
                        height: '100px',
                        marginTop: '50px'
                    }}
                    >
                        Pay
                    </Button>
                </Col>
            </Row>
            <Row justify="center" align="top" style={{marginTop: '30px'}}>
                <Col span={10}>
                    <Title level={5}>Coffe Basket</Title>
                </Col>
            </Row>
            <Row justify="center" align="top" style={{marginTop: '30px'}}>
                <Card
                    bordered={false}
                    style={{
                        width: 300,
                        marginTop: 10,
                        borderRadius: 20,
                        border: 'none',
                        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                        background: '#e28a74' }}
                >
                    <Skeleton loading={false} avatar active>
                        <Meta
                            avatar={<ShoppingCartOutlined style={{
                                fontSize: 50,
                                color: '#000000',
                            }}/>}
                            title={<div style={{color: '#ffffff', textAlign: 'right'}}>Totale Spesa: € {Number(total).toLocaleString(undefined, {minimumFractionDigits: 2,
                                maximumFractionDigits: 2})}</div>}
                            description={<div style={{color: '#000000', textAlign: 'right'}}>Credito a disposizione: € {Number(credito).toLocaleString(undefined, {minimumFractionDigits: 2,
                                maximumFractionDigits: 2})}</div>}
                        />
                    </Skeleton>
                </Card>
            </Row>
            <Row justify="center" align="top" style={{marginTop: 50}}>
                <Col span={10}>
                    <Button style={{
                        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                        background: '#ffffff',
                        color: '#800100',
                        borderRadius: '30px',
                        width: '150px',
                        height: '40px',
                        border: 'none'}}
                            onClick={() => go('/product?page=riepilogo')}>Indietro</Button>
                </Col>
                <Col span={10}>
                    <Button style={{boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)', background: '#93292a', color: '#FFFFFF', borderRadius: '30px', width: '150px', height: '40px', border: 'none'}} onClick={confermaOrdine}>Conferma</Button>
                </Col>
            </Row>
            {/*<Modal title="Attenzione" okText={'Acquista Crediti'} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>*/}
            {/*    <p>Credito insufficiente</p>*/}
            {/*</Modal>*/}
        </>
    );
};
