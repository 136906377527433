import {
    CrudFilters,
    IResourceComponentsProps, useCreate, useList, useUpdate,
} from '@pankod/refine-core';
import {
    Typography,
    Row,
    Col,
    Avatar,
    Card,
    Carousel,
    Image,
    Skeleton,
    Input,
    Radio,
    RadioChangeEvent,
    UploadProps,
    Upload,
    useSelect,
    useTable,
    Form,
    Icons
} from '@pankod/refine-antd';
import { usePublish } from "@pankod/refine-core";
import {IMessage, IUserCrediti, IUserFriend, IUserGift, IUserMovimentazioni} from '../../interfaces';
import React, { useEffect, useState} from 'react';
import {API_URL, SITE_URL, TOKEN_KEY} from '../../constants';
import {useLocation, useNavigate} from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import {
    FlagOutlined,
    AppstoreOutlined,
    DashOutlined, LinkOutlined, MailOutlined
} from '@ant-design/icons';
import { InboxOutlined } from '@ant-design/icons';
import {Button} from '@pankod/refine-antd';
import {Toast} from 'antd-mobile'
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
const { TextArea } = Input;
const { Title } = Typography;
const { Meta } = Card;
const { Dragger } = Upload;

export const InviaList: React.FC<IResourceComponentsProps> = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const location = useLocation();
    // @ts-ignore
    const decoded: any = localStorage.getItem(TOKEN_KEY) ? jwt_decode(localStorage.getItem(TOKEN_KEY)) : {id: 0};
    const apiImage = API_URL;
    const [tipo, setTipo] = useState('acquistato');
    const [view, setView] = useState('list');
    const [tipoInvio, setTipoInvio] = useState('');
    const [giftId, setGiftId] = useState(0);
    const [productId, setProductId] = useState(0);
    const [friendId, setFriendId] = useState(0);
    const [qta, setQta] = useState(0);
    const [qtaUsata, setQtaUsata] = useState(0);
    const [tokenGift, setTokenGift] = useState('');
    const [leggiMessaggioSubito, setLeggiMessaggioSubito] = useState(true);
    const [messaggio, setMessaggio] = useState('');
    const [immagine, setImmagine] = useState([]);
    const [friend, setFriend] = useState('');
    const [credito, setCredito] = useState(0);
    const { queryResult } = useSelect<IUserCrediti>({
        resource: "user-creditis",
        metaData: {
            populate: "user",
        },
        filters: [
            {
                field: "user][id]",
                operator: "eq",
                value: decoded?.id
            }
        ]
    });
    const friends = useTable<IUserFriend>(
        {
            resource: 'user-friends',
            metaData: {
                populate: "users,users.avatar,friends, friends.avatar",
            },
            permanentFilter: [
                {
                    field: "users][id]",
                    operator: "eq",
                    value: decoded?.id
                }
            ],
            onSearch: (params: any) => {
                const filters: CrudFilters = [];
                const { description } = params;

                filters.push(
                    {
                        operator: 'or',
                    value : [
                        {
                            field: "friends][cognome]",
                            operator: "contains",
                            value: description,
                        },
                        {
                            field: "friends][nome]",
                            operator: "contains",
                            value: description,
                        },
                        {
                            field: "friends][nickname]",
                            operator: "contains",
                            value: description,
                        },
                    ],
                    }
                );

                return filters;
            },
            });

    const searchFriend = (e: any) => {
        console.log(e.target.value);
    }

    const {data, error, isLoading} = useList<IUserGift>({
        resource: 'user-gifts',
        metaData: {
            populate: "prodotti, prodotti.foto",
        },
        config: {
            filters: [
                {
                    field: "user][id]",
                    operator: "eq",
                    value: decoded?.id
                },
                {
                    field: "quantita",
                    operator: "gt",
                    value: 0
                },
                {
                    field: "tipo",
                    operator: "contains",
                    value: tipo
                }
            ]
        },
    });
    const userMovimentazioni = useCreate<IUserMovimentazioni>();
    const userGift = useUpdate<IUserGift>();
    const addUserGift = useCreate<IUserGift>();
    const addMessage = useCreate<IMessage>();
    const [products, setProducts] = useState<any>([]);
    const onChange = (e: RadioChangeEvent) => {
        console.log('radio checked', e.target.value);
        setLeggiMessaggioSubito(e.target.value);
    };

    useEffect(() => {
        if (queryResult.status === 'success') {
            setCredito(queryResult.data.data.reduce((total,currentItem: IUserCrediti) =>  total = total + currentItem.importo , 0 ));
        }
    });

    function uuidv4() {
        let d = new Date().getTime();//Timestamp
        let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            let r = Math.random() * 16;//random number between 0 and 16
            if(d > 0){//Use timestamp until depleted
                r = (d + r)%16 | 0;
                d = Math.floor(d/16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r)%16 | 0;
                d2 = Math.floor(d2/16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }
    useEffect(() => {
        localStorage.setItem('gift', JSON.stringify(products));
        switch (location.state) {
            case 'ricevuto':
                setTipo('ricevuto');
                break;
            case 'acquistato':
                setTipo('acquistato');
                break;
            default:
                setTipo('acquistato');
                break;

        }
    }, [location.state]);

    const changeView = (view: string, product: any, qta: number, qtaUsata: number) => {
        console.log(qta, qtaUsata);
        setView(view)
        if (view === 'send') {
            setGiftId(product?.id);
            setProductId(product?.prodotti?.id);
            setQta(qta);
            setQtaUsata(qtaUsata ? qtaUsata : 0);
            setTokenGift(uuidv4());
        }
    }
    // const result = data?.data.reduce((r: any, { id, descrizione, prodotti, quantita, quantitaUsata}) => {
    //     console.log(r)
    //     // if (tipo === '') {
    //     //     r.push({ id, descrizione, prodotti, quantita });
    //     //     return r;
    //     // } else {
    //         let temp: any = r.find((o: any) => o?.prodotti?.id === prodotti?.id);
    //         if (!temp) {
    //             r.push({ id, descrizione, prodotti, quantita, quantitaUsata });
    //         } else {
    //             temp.quantita += quantita;
    //         }
    //         return r;
    //     // }
    // }, []);

    function sendMessage(id?: number | null) {
        addMessage.mutate( {
            resource: 'messages',
            successNotification: false,
            values: {
                descrizione: messaggio,
                fromUsers: decoded.id,
                toUsers: id ? id : null,
                immagine: immagine,
                isRead: false
            }
        })
    }
    const inviaGift = (productId: number, userId: number) => {
        if (view === 'messaggio') {
            console.log(productId, userId)
            addUserGift.mutate({
                resource: "user-gifts",
                successNotification: false,
                values: {
                    descrizione: "Ricevuto Gift",
                    tipo: 'ricevuto',
                    user: userId,
                    fromUser: decoded.id,
                    quantita: 1,
                    prodotti: productId
                }
            });
            if (addUserGift.error) throw 'Errore';
            userGift.mutate({
                resource: "user-gifts",
                successNotification: false,
                id: giftId,
                values: {
                    quantita: qta - 1,
                    quantitaUsata: qtaUsata + 1,
                },
            })
            if (userGift.error) throw 'Errore Update Gift';
            userMovimentazioni.mutate({
                resource: "user-movimentazionis",
                successNotification: false,
                values: {
                    descrizione: "Invio Gift",
                    users: decoded.id,
                    token: tokenGift,
                    user_gift: giftId,
                    toUSer: userId,
                    tipo: 'inviato',
                    messaggio,
                    immagine: image,
                    leggiMessaggioSubito,
                    isUsed: false,
                    isFriend: true
                }
            });
            if (userMovimentazioni.error) throw 'Errore';
            // sendMessage(userId);
            navigate('/inviato',  {state: {token: null, id: decoded.id, message: messaggio, image: image, friend}});
        }
    }

    const inviaLinkGift = (token: string, id: number) => {
        if (view === 'messaggio') {
            console.log(products)
            userMovimentazioni.mutate({
                resource: "user-movimentazionis",
                successNotification: false,
                values: {
                    descrizione: "Invio Gift",
                    users: decoded.id,
                    token: tokenGift,
                    user_gift: id,
                    tipo: 'inviato',
                    messaggio,
                    immagine: image,
                    leggiMessaggioSubito,
                    isUsed: false,
                    isFriend: false
                }
            });
            if (userMovimentazioni.error) throw 'Errore';
            userGift.mutate({
                resource: "user-gifts",
                successNotification: false,
                id: id,
                values: {
                    quantita: qta - 1,
                    quantitaUsata: qtaUsata + 1
                },
            })
            if (userGift.error) throw 'Errore Update Gift';
            // sendMessage(null);
            navigate('/inviato',  {state: {token, id: decoded.id, message: messaggio, image: image, friend}});
        }
    }
    const [image, setImage] = useState<any>([]);
    const publish = usePublish();
    const getBase64 = (file: any) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    const props: UploadProps = {
        name: 'files',
        multiple: false,
        listType: 'picture-card',
        action: API_URL + '/api/upload',
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList, info.file.response[0]);
                setImage(info.file.response[0]);
                setImmagine(info.file.response[0].id)
                // // @ts-ignore
                // publish({
                //     channel: "notifications",
                //     type: "message",
                //     payload: {
                //         "custom-property": "custom-property-value",
                //     },
                //     date: new Date(),
                // });
            }
            if (status === 'done') {
                // Toast.show(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                // Toast.show(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return (
        view === 'messaggio' &&
            <>
                <Row justify={'center'} align={'top'} style={{marginTop: 40, justifyContent: 'center'}}>
                    <Col span={24} style={{ padding: '20px' }}>
                        <Card
                            style={{
                                textAlign: 'center',
                                border: 'none',
                                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                borderRadius: '40px',
                                height: 280,
                                width: '100%',
                                background: '#e28a74',
                                color: '#FFFFFF' }}>
                            <Dragger {...props}
                                     style={{
                                         background: '#e28a74',
                                         border: 'none'
                                     }}>
                                <Image hidden={!image.url} preview={false}
                                       style={{
                                           position: 'relative',
                                           width: '80%',
                                           marginTop: 1,
                                           borderRadius: 22
                                       }}
                                       src={API_URL + image.url}/>
                                <div hidden={image.url}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined style={{
                                            color: '#000000'
                                        }}/>
                                    </p>
                                    <p className="ant-upload-text">Clicca qui per aggiungere una foto da far ricevere insieme al caffè!</p>
                                    <p className="ant-upload-hint">
                                        Carica l'immagine da Inviare!
                                    </p>
                                </div>

                            </Dragger>
                            <Row gutter={[16, 32]} style={{marginBottom: '10px'}}>
                                <Col span={24}>
                                    <Image hidden={image.length === 0}
                                           style={{
                                               borderRadius: 20,
                                               position: 'relative',
                                               marginTop: 10,

                                               width: '70%'
                                           }}
                                           src={API_URL + image.url}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row justify={'center'} align={'top'} style={{marginTop: -38, textAlign: 'center'}}>
                    <Col span={24}>
                        <Button
                            onClick={(val) => {
                                console.log('Invio: ', giftId);
                                console.log('Tipo: ', tipoInvio);
                                console.log('Friend: ', friendId);
                                console.log('Messaggio: ', messaggio);
                                switch (tipoInvio) {
                                    case 'link':
                                        inviaLinkGift(tokenGift, giftId);
                                        break;
                                    case 'gift':
                                        inviaGift(productId, friendId);
                                        break;
                                }
                            }}
                            style={{
                            position: 'absolute',
                            width: 80,
                            height: 80,
                            border: '2px solid #FFFFFF',
                            borderRadius: '50%',
                            background: '#e28a74',
                            zIndex: '99999',
                            padding: 5,
                            marginLeft: '-10%',
                            marginTop: -25
                        }} type="primary" shape="circle" icon={<Icons.ArrowRightOutlined style={{
                            fontSize: 35,
                            fontWeight: 800
                        }} />} />
                    </Col>
                </Row>

                <Row justify={'center'} align={'top'} style={{marginTop: 1, justifyContent: 'center'}}>
                    <Col span={24} style={{ padding: '20px' }}>
                        <Card
                            style={{
                                textAlign: 'center',
                                border: 'none',
                                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                borderRadius: '40px',
                                height: 280,
                                width: '100%',
                                background: '#e28a74',
                                color: '#FFFFFF' }}>
                            <Row gutter={[16, 32]} style={{marginBottom: '30px', justifyContent: 'center'}}>
                                <Col span={22}>
                                        <Row gutter={[16, 32]} style={{marginTop: 20, marginBottom: '10px', justifyContent: 'center'}}>
                                            <Col span={22}>
                                                <TextArea
                                                    style={{
                                                        background: 'rgba(0,0,0,0)',
                                                        border: 'none'
                                                    }}
                                                    rows={9} value={messaggio} onChange={(e: any) => setMessaggio(e.target.value)} placeholder="Clicca qui pe aggiungere una frase da far ricevere insieme al caffè..." maxLength={1000} />
                                            </Col>
                                        </Row>
                                    <Button shape={'round'} style={{
                                        position: 'absolute',
                                        zIndex: 99999,
                                        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                        border: 'none',
                                        fontSize: '20px',
                                        color: '#FFFFFF',
                                        background: '#800100FF',
                                        width: 70,
                                        height: 70,
                                        marginLeft: -33,
                                        marginTop: -40
                                    }}
                                            onClick={(val) => {
                                                console.log('Invio: ', giftId);
                                                console.log('Tipo: ', tipoInvio);
                                                console.log('Friend: ', friendId);
                                                console.log('Messaggio: ', messaggio);
                                                switch (tipoInvio) {
                                                    case 'link':
                                                        inviaLinkGift(tokenGift, giftId);
                                                        break;
                                                    case 'gift':
                                                        inviaGift(productId, friendId);
                                                        break;
                                                }
                                            }}
                                    >
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                {/*<Row justify={'center'} align={'top'} style={{marginTop: 30, justifyContent: 'center'}}>*/}
                {/*    /!*<Col span={24} style={{ padding: '1px' }}>*!/*/}
                {/*    /!*    <Title level={5} style={{*!/*/}
                {/*    /!*        color: '#FFFFFF',*!/*/}
                {/*    /!*        textDecorationLine: 'underline',*!/*/}
                {/*    /!*        textUnderlineOffset: 10,*!/*/}
                {/*    /!*        textDecorationThickness: 3*!/*/}
                {/*    /!*    }}>Quando vuoi che il messaggio venga letto?</Title>*!/*/}
                {/*    /!*</Col>*!/*/}
                {/*    /!*<Col span={24} style={{ padding: '1px' }}>*!/*/}
                {/*    /!*    <Radio.Group buttonStyle={'outline'}*!/*/}
                {/*    /!*                 onChange={onChange} value={leggiMessaggioSubito} style={{*!/*/}
                {/*    /!*        marginTop: 10,*!/*/}
                {/*    /!*    }}>*!/*/}
                {/*    /!*        <Radio value={true}>Subito</Radio>*!/*/}
                {/*    /!*        <Radio value={false}>Dopo</Radio>*!/*/}
                {/*    /!*    </Radio.Group>*!/*/}
                {/*    /!*</Col>*!/*/}
                {/*    <Col span={24} style={{ padding: '20px' }}>*/}
                {/*        <Card*/}
                {/*            style={{*/}
                {/*                textAlign: 'center',*/}
                {/*                border: 'none',*/}
                {/*                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',*/}
                {/*                borderRadius: '40px',*/}
                {/*                height: 260,*/}
                {/*                width: '100%',*/}
                {/*                background: '#e28a74',*/}
                {/*                color: 'rgb(255,255,255)' }}>*/}

                {/*        </Card>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                {/*<Row justify={'center'} align={'top'} style={{marginTop: -10, textAlign: 'center'}}>*/}
                {/*    <Col span={24}>*/}

                {/*    </Col>*/}
                {/*</Row>*/}
                {/*<Row justify={'center'} align={'top'} style={{marginTop: -15, justifyContent: 'center'}}>*/}
                {/*    <Col span={24} style={{ padding: '20px' }}>*/}

                {/*    </Col>*/}
                {/*</Row>*/}
                {/*<Row justify={'center'} align={'top'} style={{marginTop: 1, justifyContent: 'center'}}>*/}
                {/*    <Col>*/}
                {/*        <Button shape={'rounded'} style={{*/}
                {/*            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',*/}
                {/*            border: 'none',*/}
                {/*            fontSize: '20px',*/}
                {/*            color: '#FFFFFF',*/}
                {/*            background: '#800100FF',*/}
                {/*            width: 70,*/}
                {/*            height: 70,*/}
                {/*            marginTop: '30px'*/}
                {/*        }}*/}
                {/*                onClick={(val) => {*/}
                {/*                    console.log('Invio: ', giftId);*/}
                {/*                    console.log('Tipo: ', tipoInvio);*/}
                {/*                    console.log('Friend: ', friendId);*/}
                {/*                    console.log('Messaggio: ', messaggio);*/}
                {/*                    switch (tipoInvio) {*/}
                {/*                        case 'link':*/}
                {/*                            inviaLinkGift(tokenGift, giftId);*/}
                {/*                            break;*/}
                {/*                        case 'gift':*/}
                {/*                            inviaGift(productId, friendId);*/}
                {/*                            break;*/}
                {/*                    }*/}
                {/*                }}*/}
                {/*        >*/}
                {/*            <FontAwesomeIcon icon={solid('arrow-circle-right')} style={{*/}
                {/*                fontSize: 20*/}
                {/*            }} />*/}
                {/*        </Button>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
            </>
        ||
        data?.data && data.data?.length === 0 &&
                <>
                    <Row justify={'center'} align={'top'} style={{marginTop: 40, justifyContent: 'center'}}>
                        <Col span={24} style={{ padding: '20px' }}>
                            <Card
                                style={{
                                    textAlign: 'center',
                                    border: 'none',
                                    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                    borderRadius: '40px',
                                    height: 280,
                                    width: '100%',
                                    background: '#93292a',
                                    color: '#FFFFFF' }}>
                                <Row gutter={[16, 32]} style={{marginBottom: '10px'}}>
                                    <Col span={24}>
                                        <Title level={5} style={{color: '#FFFFFF'}}>Animazione</Title>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row justify={'center'} align={'top'} style={{marginTop: -38, textAlign: 'center'}}>
                        <Col span={24}>
                            <div style={{
                                position: 'absolute',
                                width: 80,
                                height: 80,
                                border: '2px solid #FFFFFF',
                                borderRadius: '50%',
                                background: '#93292a',
                                zIndex: '9',
                                padding: 5,
                                marginLeft: '40%',
                                marginTop: -25
                            }}>
                                <FlagOutlined style={{fontSize: 30, verticalAlign: 'middle', padding: 20, color: '#FFFFFF'}}/>
                            </div>
                        </Col>
                    </Row>

                    <Row justify={'center'} align={'top'} style={{marginTop: 1, justifyContent: 'center'}}>
                        <Col span={24} style={{ padding: '20px' }}>
                            <Card
                                style={{
                                    textAlign: 'center',
                                    border: 'none',
                                    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                    borderRadius: '40px',
                                    height: 230,
                                    width: '100%',
                                    background: '#93292a',
                                    color: '#FFFFFF' }}>
                                <Row gutter={[16, 32]} style={{marginBottom: '10px', justifyContent: 'center'}}>
                                    <Col span={22}>
                                        <Title level={3} style={{color: '#FFFFFF', marginTop: 25}}>Non hai coffee voucher a disposizione!</Title>
                                        <Title level={5} style={{color: '#FFFFFF', fontSize: 12}}>Converti il credito a disposizione in Voucher</Title>
                                        <Button type="default"
                                                style={{background: '#f3d3ba', color: '#000000', borderRadius: '30px', width: '150px', height: '40px', border: 'none', marginTop: 10}}
                                                onClick={() => navigate('/product?page=riepilogo')}
                                        >Converti</Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    {/*<Row justify={'center'} align={'top'} style={{marginTop: 1, justifyContent: 'center'}}>*/}
                    {/*    <Col span={24} style={{ padding: '1px' }}>*/}
                    {/*        <Button type="button"*/}
                    {/*                style={{background: '#93292a', color: '#FFFFFF', borderRadius: '30px', width: '150px', height: '40px', border: 'none'}}*/}
                    {/*                onClick={() => navigate('/product')}*/}
                    {/*        >Vai allo Shop</Button>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                </> ||
        <>
            <Row justify="center" align="top">
                <Col>
                    {/*<Title level={4} style={{*/}
                    {/*    color: '#8E201BFF',*/}
                    {/*    marginTop: '50px'*/}
                    {/*}}>Coffe lovers</Title>*/}
                    <Title level={5} style={{
                        color: '#8E201BFF',
                        marginTop: '50px'
                    }}>{(Number(credito) && (view !== 'send')) ? 'Hai dei crediti, convertili in voucher e offri un caffe' : (view !== 'send') ? 'Non hai crediti, acquistali e offri il tuo caffe' : ''}</Title>
                </Col>
            </Row>
            <Row justify="space-around" align="top" style={{marginTop: '30px'}}>
                <Col span={14} style={{marginTop: -30}}>
                    <Title level={5} style={{
                        fontWeight: 400,
                        color: '#c64140'
                    }}>{view === 'send' ? 'Vuoi inviare un coffe gift\n' +
                        (friends.tableQueryResult && friends.tableQueryResult.data?.data?.length !== 0 ? '\na qualcuno ma non lo trovi?' : '\n  \nClicca sul Pulsante LINK') : 'Seleziona la Gift da Inviare'}</Title>
                </Col>
            </Row>
            <Row justify="center" align="top" hidden={view !== 'send' || (friends.tableQueryResult && friends.tableQueryResult.data?.data?.length === 0)}>
                <Col span={10} style={{marginTop: -30}}>
                    <Title level={5} style={{
                        fontWeight: 400,
                        color: '#8E201BFF',
                        marginTop: '30px',
                        cursor: 'pointer'
                    }}
                           onClick={(val) => {
                               console.log('Invio: ', giftId);
                               //inviaLinkGift(tokenGift, giftId);
                               setView('messaggio');
                               setTipoInvio('link');
                           }}
                    ><u>Clicca Qui</u> e segui le indicazioni</Title>
                </Col>
            </Row>
            <Row justify="center" align="top" style={{marginTop: '30px'}} hidden={view === 'send'}>
                {/*<Col span={8}>*/}
                {/*    <Title level={5} onClick={() => navigate('/invia', { state: 'tutti'})}>*/}
                {/*        <p style={{*/}
                {/*        textDecoration: 'underline',*/}
                {/*        textDecorationColor: tipo === '' ? '#ffffff' : '#FFFFFF00',*/}
                {/*        textUnderlineOffset: 5,*/}
                {/*        textDecorationThickness: 2*/}
                {/*    }}>tutti</p>*/}
                {/*    </Title>*/}
                {/*</Col>*/}
                {/*<Col span={12}>*/}
                {/*    <Title level={5} onClick={() => navigate('/invia', { state: 'acquistato'})}>*/}
                {/*        <p style={{*/}
                {/*            textDecoration: 'underline',*/}
                {/*            textDecorationColor: tipo === 'acquistato' ? '#FFFFFF' : '#FFFFFF00',*/}
                {/*            textUnderlineOffset: 5,*/}
                {/*            textDecorationThickness: 2*/}
                {/*        }}>acquistati</p>*/}
                {/*    </Title>*/}
                {/*</Col>*/}
                {/*<Col span={12}>*/}
                {/*    <Title level={5} onClick={() => navigate('/invia', { state: 'ricevuto'})}>*/}
                {/*        <p style={{*/}
                {/*            textDecoration: 'underline',*/}
                {/*            textDecorationColor: tipo === 'ricevuto' ? '#FFFFFF' : '#FFFFFF00',*/}
                {/*            textUnderlineOffset: 5,*/}
                {/*            textDecorationThickness: 2*/}
                {/*        }}>ricevuti</p>*/}
                {/*    </Title>*/}
                {/*</Col>*/}
            </Row>
            {/*<Row justify="center" align="top" style={{marginTop: '3px'}} hidden={view === 'send'}>*/}
            {/*    <Col span={2}>*/}
            {/*        <DashOutlined onClick={() => changeView('carousel', 0, 0, 0)} />*/}
            {/*    </Col>*/}
            {/*    <Col span={2}>*/}
            {/*        <AppstoreOutlined onClick={() => changeView('list', 0, 0, 0)} />*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            {/*<Row justify="center" align="top" hidden={view !== 'send'}>*/}
            {/*    <Col span={18} style={{marginTop: -30}}>*/}
            {/*        <Title level={5}*/}
            {/*               onClick={(val) => {*/}
            {/*                   console.log('Invio: ', giftId);*/}
            {/*                   //inviaLinkGift(tokenGift, giftId);*/}
            {/*                   setView('messaggio');*/}
            {/*                   setTipoInvio('link');*/}
            {/*               }}*/}
            {/*               style={{*/}
            {/*                    color: '#8E201BFF',*/}
            {/*                    cursor: 'pointer',*/}
            {/*                    marginTop: '30px'*/}
            {/*        }}>Clicca sul Pulsante!</Title>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            {view === 'send' &&
            <Row justify="center" align="top" style={{marginTop: '3px'}}>
                <Col span={18} hidden={friends.tableQueryResult && friends.tableQueryResult.data?.data?.length === 0}>
                    <Form form={form} layout="horizontal" {...friends.searchFormProps} labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}>
                        <Form.Item name="description">
                            <Input bordered={false} style={{
                                color: '#000000',
                                borderRadius: '0px',
                                textAlign: 'left',
                                border: 'none',
                                borderBottom: '2px solid #FFFFFF',
                                width: '100%'}}
                                   onChange={(e) => searchFriend(e)}
                                   placeholder="cerca..." />
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={32}>
                    {friends && friends.tableQueryResult.data?.data.map( (pro: any, i) => (
                        <Card key={pro.id}
                              bordered={false}
                              onClick={(val) => {
                                  console.log('Invio: ', giftId);
                                  //inviaGift(productId, pro?.friends?.id);
                                  setView('messaggio');
                                  setTipoInvio('gift');
                                  setFriend(pro?.friends);
                                  setFriendId(pro?.friends?.id);
                              }}
                              style={{
                                  width: 300,
                                  marginTop: 10,
                                  borderRadius: 20,
                                  border: 'none',
                                  boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                  background: '#932928' }}
                        >
                            <Skeleton loading={false} avatar active>
                                <Meta
                                    avatar={<Image src={apiImage + '' + pro?.friends?.avatar?.url} style={{width: 60, borderRadius: 10}} />}
                                    title={<div style={{color: '#ffffff'}}>{pro.friends?.nome} {pro.friends?.cognome}</div>}
                                    description=""
                                />
                            </Skeleton>
                        </Card>
                    ))}
                </Col>
                <Col span={10} hidden={friends && Number(friends.tableQueryResult.data?.total) > 0}>
                    <Button shape={'round'} style={{
                        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                        border: 'none',
                        fontSize: '30px',
                        color: '#FFFFFF',
                        background: '#800100FF',
                        width: '100px',
                        height: '100px',
                        marginTop: '30px'
                    }}
                            onClick={(val) => {
                                console.log('Invio: ', giftId);
                                //inviaLinkGift(tokenGift, giftId);
                                setView('messaggio');
                                setTipoInvio('link');
                            }}
                    >
                        Link
                    </Button>
                </Col>
            </Row>
            }
            {view === 'carousel' &&
            <Row justify="center" align="top" style={{marginTop: '30px'}}>
                <Col>
                        <Carousel
                            className={'center'}
                            dots={false}
                            centerMode={data?.data?.length !== 1}
                            centerPadding={'30px'}
                            slidesToShow={1}
                            style={{width: '100%'}}>
                            {data?.data && data.data?.map((pro: any) => (
                                <div key={pro.id}>
                                    <div style={{
                                        background: pro.isRicevuto ? '#FFFFFF' : '#93292a',
                                        color: '#FFFFFF',
                                        height: 300,
                                        boxShadow: '-3px 1px 3px rgba(0, 0, 0, 0.1)',
                                        borderRadius: '30px',
                                        marginLeft: '15px',
                                        marginRight: '15px'
                                    }} onClick={(val) => {
                                        console.log(pro.id);
                                        changeView('send', pro, pro.quantita, pro.quantitaUsata)
                                    }}>
                                        <Avatar style={{
                                            width: '100%',
                                            height: 160,
                                            borderTopLeftRadius: '30px',
                                            borderTopRightRadius: '30px'
                                        }} src={apiImage + '' + pro?.prodotti?.foto?.url}/>
                                        <div style={{margin: '15px'}} hidden={pro.isRicevuto}>
                                            {/*<Checkbox*/}
                                            {/*    value={pro.id}*/}
                                            {/*    onChange={(val) => {*/}
                                            {/*        console.log(val, pro.id);*/}
                                            {/*        setProducts(pro)*/}
                                            {/*    }}></Checkbox>*/}
                                        </div>
                                        <div style={{
                                            textAlign: 'center',
                                            marginRight: 45,
                                        }}>
                                            <Button style={{
                                                marginTop: 80,
                                                height: 40,
                                                width: 40,
                                                position: 'absolute',
                                                border: 'none',
                                                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                                background: '#F4D3BAFF'
                                            }} shape={'round'}>{pro.quantita}</Button>
                                        </div>
                                        <div>
                                            <Title level={3} style={{
                                                color: pro.isRicevuto ? '#93292a' : '#FFFFFF',
                                                marginTop: pro.isRicevuto ? 57 : 0
                                            }}>{pro.descrizione}</Title>
                                        </div>
                                        <div style={{
                                            marginRight: -230,
                                            marginTop: -10
                                        }}>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                </Col>
            </Row>
                || view === 'list' &&
                <>
                <Row justify="center" align="top" style={{marginTop: '10px'}}>
                    {data?.data && data.data?.map((pro: any) => (
                        <div key={pro.id} style={{
                            padding: 10,
                        }}>
                            {/*<Col span={32}>*/}
                            {/*    <Card bordered={false}*/}
                            {/*          style={{*/}
                            {/*              width: 150,*/}
                            {/*              height: 200,*/}
                            {/*              // padding: 1,*/}
                            {/*              borderRadius: 20,*/}
                            {/*              border: 'none',*/}
                            {/*              boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',*/}
                            {/*              color: pro.isRicevuto ? '#93292a' : '#FFFFFF',*/}
                            {/*              background: pro.isRicevuto ? '#FFFFFF' : '#93292a', }}*/}
                            {/*          onClick={(val) => {*/}
                            {/*              console.log(pro.id);*/}
                            {/*              changeView('send', pro, pro.quantita, pro.quantitaUsata)*/}
                            {/*          }}*/}
                            {/*    >*/}
                            {/*        <div style={{*/}
                            {/*            textAlign: 'right',*/}
                            {/*            marginRight: 15,*/}
                            {/*        }}>*/}
                            {/*            <Button style={{*/}
                            {/*                marginTop: 145,*/}
                            {/*                position: 'absolute',*/}
                            {/*                zIndex: 999999,*/}
                            {/*                border: 'solid',*/}
                            {/*                borderColor: 'white',*/}
                            {/*                height: 30,*/}
                            {/*                width: 30,*/}
                            {/*                fontSize: 12,*/}
                            {/*                padding: '3px 3px 2px 3px',*/}
                            {/*                marginLeft: -10,*/}
                            {/*                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',*/}
                            {/*                background: '#F4D3BAFF'*/}
                            {/*            }} shape={'rounded'}>{pro.quantita}</Button>*/}
                            {/*        </div>*/}
                            {/*        <Image src={apiImage + '' + pro?.prodotti?.foto.url} style={{*/}
                            {/*            width: 100,*/}
                            {/*            height: 100,*/}
                            {/*            borderRadius: 10}}*/}
                            {/*               preview={false} />*/}
                            {/*        <p style={{*/}
                            {/*            padding: 5,*/}
                            {/*            fontSize: 12*/}
                            {/*        }}>{pro.descrizione}</p>*/}
                            {/*    </Card>*/}
                            {/*</Col>*/}
                            <div style={{
                                background: pro.isRicevuto ? '#FFFFFF' : 'rgba(147,41,42,0)',
                                color: '#FFFFFF',
                                height: 200,
                                width: 130,
                                boxShadow: '-3px 1px 3px rgba(0, 0, 0, 0.1)',
                                borderRadius: '30px',
                                marginLeft: '15px',
                                marginRight: '15px'
                            }}
                                           onClick={(val) => {
                                               console.log(pro.id);
                                               changeView('send', pro, pro.quantita, pro.quantitaUsata)
                                           }}
                            >
                                <Avatar style={{
                                    width: '100%',
                                    height: '100%',
                                    borderTopLeftRadius: 20,
                                    borderTopRightRadius: 20,
                                    borderBottomLeftRadius: 20,
                                    borderBottomRightRadius: 20
                                }} src={apiImage + '' + pro?.prodotti?.foto.url}/>
                                <div style={{margin: '15px'}} hidden={pro.isRicevuto}>
                                    {/*<Checkbox*/}
                                    {/*    value={pro.id}*/}
                                    {/*    onChange={(val) => {*/}
                                    {/*        console.log(val, pro.id);*/}
                                    {/*        setProducts(pro)*/}
                                    {/*    }}></Checkbox>*/}
                                </div>
                                <div style={{
                                    textAlign: 'center',
                                    marginRight: 45,
                                }}>
                                    <Button style={{
                                        marginTop: -200,
                                        position: 'absolute',
                                        zIndex: 999999,
                                        border: 'solid',
                                        borderColor: 'white',
                                        height: 30,
                                        width: 30,
                                        fontSize: 12,
                                        padding: '3px 3px 2px 3px',
                                        marginLeft: 5,
                                        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                                        background: '#F4D3BAFF'
                                    }} shape={'round'}>{pro.quantita}</Button>
                                </div>
                                <div>
                                    <Title level={3} style={{
                                        fontSize: 13,
                                        position: 'absolute',
                                        color: pro.isRicevuto ? '#93292a' : '#FFFFFF',
                                        marginTop: pro.isRicevuto ? 60 : -70,
                                        zIndex: 99999999,
                                        textAlign: 'center',
                                        inlineSize: 120,
                                        padding: '1px 3px 10px 10px',
                                        overflowWrap: 'break-word'
                                    }}>Crediti acquistati il: {dayjs(pro.createdAt).format('DD/MM/YYYY')}</Title>
                                    <Title level={3} style={{
                                        fontSize: 13,
                                        position: 'absolute',
                                        color: pro.isRicevuto ? '#93292a' : '#FFFFFF',
                                        marginTop: pro.isRicevuto ? 60 : -36,
                                        zIndex: 99999999,
                                        textAlign: 'center',
                                        inlineSize: 120,
                                        padding: '1px 3px 10px 10px',
                                        overflowWrap: 'break-word'
                                    }}>Ore: {dayjs(pro.createdAt).format('HH:mm:ss')}</Title>
                                </div>
                                <div style={{
                                    marginRight: -230,
                                    marginTop: -10
                                }}>
                                </div>
                            </div>
                        </div>

                        ))
                    }
                </Row>
                </>
            }
        </>
    );
};
