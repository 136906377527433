import {AuthProvider} from '@pankod/refine-core';
import { AuthHelper } from "@pankod/refine-strapi-v4";

import { TOKEN_KEY, API_URL } from "./constants";
import axios from "axios";
import {socket} from './App';
import {apiFunction} from './apiFuntion';


export const axiosInstance = axios.create();

const strapiAuthHelper = AuthHelper(API_URL + "/api");
export const authProvider: AuthProvider = {
  login: async ({ username, password, redirectPath }) => {
    const { data, status } = await strapiAuthHelper.login(username, password);
    if (status === 200) {
      localStorage.setItem(TOKEN_KEY, data.jwt);
      localStorage.setItem('id', data.user.id.toString());
      const id = data.user.id;
      console.log('SOCKET: ', socket.id);
      // set header axios instance
      if (socket.id) {
        await apiFunction.setSocketId(data.jwt, Number(id), socket.id);
      }
      axiosInstance.defaults.headers = {
        Authorization: `Bearer ${data.jwt}`,
      };
      return Promise.resolve(redirectPath);
    }
    return Promise.reject();
  },
  logout: () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem('id');
    localStorage.removeItem('isProfileOk');
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      axiosInstance.defaults.headers = {
        Authorization: `Bearer ${token}`,
      };
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return Promise.reject();
    }

    const { data, status } = await strapiAuthHelper.me(token);
    if (status === 200) {
      // @ts-ignore
      const { id, username, email } = data;
      return Promise.resolve({
        id,
        username,
        email
      });
    }

    return Promise.reject();
  },
};
