import { useList, useNavigation } from "@pankod/refine-core";
import GoogleMapReact from "google-map-react";
import { MapMarker } from "components/map";
import {UserIcon} from 'components/icons';

import {IUser} from 'interfaces';
import React, {useRef, useState} from 'react';
import { usePosition } from 'use-position';
import {BarIcon} from '../icons/bar';
import {Popover} from '@pankod/refine-antd';

export const DeliveryMap: React.FC = (props) => {
    const { data: userData } = useList<IUser>({
        resource: "users",
        config: {
            filters: [
                {
                    field: "tipologia",
                    operator: "eq",
                    value: "bar",
                },
            ],
            pagination: {
                pageSize: 1000,
            },
        },
    });
    const { show } = useNavigation();
    const [visible, setVisible] = useState(false);
    const {
        latitude,
        longitude,
        // @ts-ignore
        error
    } = usePosition(false);

    const defaultProps = {
        center: {
            lat: latitude,
            lng: longitude,
        },
        zoom: 13,
    };

    const hide = () => {
        setVisible(false);
    };

    const handleVisibleChange = (newVisible: boolean) => {
        setVisible(newVisible);
    };

    if (error) return <div>{error}</div>
    if (latitude && longitude)
    return (
        <GoogleMapReact
            bootstrapURLKeys={{
                key: 'AIzaSyDmu4vnp3NLZyvp1sfMwfJamEk0y8Uk1t0',
            }}
            // @ts-ignore
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
        >
            {userData?.data.map((user) => {
                return (
                    <MapMarker
                        key={Math.random()}
                        // @ts-ignore
                        lat={defaultProps.center.lat}
                        // @ts-ignore
                        lng={defaultProps.center.lng}
                    >
                        <Popover content={
                            <>
                                <div>
                                    La tua Posizione
                                </div>
                            </>
                        }
                                 title={user.insegnaBar}
                                 trigger="click"
                                 onVisibleChange={handleVisibleChange}
                        >
                            <UserIcon />
                        </Popover>
                    </MapMarker>
                );
            })}
            {userData?.data.map((user) => {
                return (
                    <MapMarker
                        key={user.id}
                        lat={user.latitudine}
                        lng={user.longitudine}
                    >
                        <Popover content={
                            <>
                            <div>
                                {user.indirizzo}
                            </div>
                            <div>
                                Cap: {user.cap} - {user.comune} - {user.provincia}
                            </div>
                            <div>
                                Tel: {user.telefono}
                            </div>
                            <div>
                                Email: {user.email}
                            </div>
                            </>
                        }
                                 title={user.insegnaBar}
                                 trigger="click"
                                 onVisibleChange={handleVisibleChange}
                        >
                            <BarIcon fill={'#850000'}/>
                        </Popover>
                    </MapMarker>
                );
            })}
        </GoogleMapReact>
    );
    return (<div>Loading...</div>)
};
