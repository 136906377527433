import {
    IResourceComponentsProps,
} from '@pankod/refine-core';
import {
    Typography,
    Card,
    Row,
    Col,
    Button, Form, Input, Avatar,
} from '@pankod/refine-antd';
import {CreditCardFilled, FlagOutlined, CreditCardOutlined, EditFilled} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';

const { Title } = Typography;


export const CompletatoList: React.FC<IResourceComponentsProps> = (props) => {
    const navigate = useNavigate();
    return (
        <>
            <Row justify={'center'} align={'top'} style={{marginTop: 40, justifyContent: 'center'}}>
                <Col span={24} style={{ padding: '20px' }}>
                    <Card
                        style={{
                            textAlign: 'center',
                            border: 'none',
                            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                            borderRadius: '40px',
                            height: 280,
                            width: '100%',
                            background: '#e28a74',
                            color: '#FFFFFF' }}>
                        <Row gutter={[16, 32]} style={{marginBottom: '10px'}}>
                            <Col span={24}>
                                <Title level={3} style={{color: '#800000', marginTop: 25}}>Acquisto Completato</Title>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row justify={'center'} align={'top'} style={{marginTop: -38, textAlign: 'center'}}>
                <Col span={24}>
                    <div style={{
                        position: 'absolute',
                        width: 80,
                        height: 80,
                        border: '2px solid #FFFFFF',
                        borderRadius: '50%',
                        background: '#e28a74',
                        zIndex: '99999',
                        padding: 5,
                        marginLeft: '40%',
                        marginTop: -25
                    }}>
                        <FlagOutlined style={{fontSize: 30, verticalAlign: 'middle', padding: 20, color: '#FFFFFF'}}/>
                    </div>
                </Col>
            </Row>

            <Row justify={'center'} align={'top'} style={{marginTop: 1, justifyContent: 'center'}}>
                <Col span={24} style={{ padding: '20px' }}>
                    <Card
                        style={{
                            textAlign: 'center',
                            border: 'none',
                            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                            borderRadius: '40px',
                            height: 230,
                            width: '100%',
                            background: '#e28a74',
                            color: '#FFFFFF' }}>
                        <Row gutter={[16, 32]} style={{marginBottom: '10px', justifyContent: 'center'}}>
                            <Col span={22}>
                                <Title level={3} style={{color: '#800000', marginTop: 25}}> </Title>
                                <Title level={5} style={{color: '#FFFFFF', fontSize: 18}}>Ora che hai acquistato i tuoi coffe gift, sei pronto ad inviarli a chi desideri!</Title>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row justify={'center'} align={'top'} style={{marginTop: 1, justifyContent: 'center'}}>
                <Col span={24} style={{ padding: '1px' }}>
                    <Button type="primary" htmlType={'submit'}
                            style={{background: '#e28a74', borderRadius: '30px', width: '150px', height: '40px', border: 'none', marginRight: 3}}
                            onClick={() => navigate('/invia')}
                    >Invia</Button>
                    <Button type="primary" htmlType={'submit'}
                            style={{background: '#93292a', borderRadius: '30px', width: '150px', height: '40px', border: 'none'}} onClick={() => navigate('/home')}
                    >Torna alla Home</Button>
                </Col>
            </Row>

        </>
    );
};
