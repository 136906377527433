import {API_URL } from "./constants";
import axios from "axios";

export const axiosInstance = axios.create();
export const apiFunction: any = {
  addReceivedGift: async (fromId: number, id: number, tokenSession: string, token: string) => {
    if (!token && !id) {
      return false;
    } else {
      const resultMovimentazioni = await axios.get(API_URL + '/api/user-movimentazionis?populate=user_gift, immagine, user_gift.prodotti, users&filters[token]=' + token + '&filters[isUsed]=false', {
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${tokenSession}`}
      })
      console.log(resultMovimentazioni)
      if (resultMovimentazioni.status === 200 && resultMovimentazioni.data.data.length > 0 && !resultMovimentazioni.data.data[0].attributes.isUsed) {
        try {
          const userUpdateMovimentazioni = await axios.put(API_URL + '/api/user-movimentazionis/' + Number(resultMovimentazioni.data.data[0].id), {
            data: {
              isUsed: true,
              toUser: id
            }
          }, {headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${tokenSession}` }})
          try {
            const {data} = await axios.post(API_URL + '/api/user-gifts', {
              data: {
                descrizione: "Ricevuto" + ' ' + resultMovimentazioni.data.data[0]?.attributes?.user_gift?.data?.attributes?.descrizione,
                user: id,
                fromUser: fromId,
                prodotti: resultMovimentazioni.data.data[0]?.attributes?.user_gift?.data?.attributes?.prodotti?.data?.id,
                tipo: 'ricevuto',
                quantita: 1
              },
            }, {headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${tokenSession}` }})
            console.log('XXXX:', data.data.id)
            try {
              const {data: datamov} = await axios.post(API_URL + '/api/user-movimentazionis', {
                data: {
                  descrizione: "Ricevuto Gift",
                  users: id,
                  user_gift: data.data.id,
                  tipo: 'ricevuto',
                  isUsed: false,
                  isFriend: false,
                  fromUser: fromId
                },
              }, {headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${tokenSession}` }})
              return resultMovimentazioni;
            } catch (e) {

            }

          } catch (e) {
            return false;
          }
        } catch (e) {
          return false;
        }
      } else {
        return false;
      }
    }
  },
  addFriend: async (toId: number, id: number, token: string) => {
    const resultFriend = await axios.get(API_URL + '/api/user-friends?populate=*', {
      headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}
    })
    if (resultFriend.status === 200) {
      console.log(resultFriend);
      const fU = resultFriend.data?.data?.findIndex((x: any) => (Number(x.attributes.friends.data.id) === Number(id) && Number(x.attributes.users.data.id) === Number(toId)));
      console.log(fU);
      if (fU !== -1) {
        console.log('Amico già Presente')
      } else {
        const addFriend = await axios.post(API_URL + '/api/user-friends', {
          data: {
            descrizione: 'Nuovo Collegamento',
            isActive: true,
            friends: toId,
            users: id
          },
        }, {headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}})
        const addFriendTo = await axios.post(API_URL + '/api/user-friends', {
          data: {
            descrizione: 'Nuovo Collegamento',
            isActive: true,
            friends: id,
            users: toId
          },
        }, {headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}})
      }
    }
    return true;
  },
  getUserData: async (token?: string, id?: number) => {
    if (!token && !id) {
      return Promise.reject();
    } else {
      const {data} = await axios.get(API_URL + '/api/users/' + id, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
      })
      return data['data']
    }
  },
  getUserCrediti: async (token?: string, id?: number) => {
    if (!token) {
      return Promise.reject();
    } else {
      const {data} = await axios.get(API_URL + '/api/user-creditis?pagination[page]=1&pagination[pageSize]=999999999&populate=user&filters[user][id][$eq]=' + id, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
      })
      return data.data.reduce((total: any,{attributes}: any) =>  total = total + attributes.importo , 0);
    }
  },
  setSocketId: async (token?: string, id?: number, socketId?: string) => {
    if (token) {
      return await axios.put(API_URL + '/api/users/' + id, {
            socketId: socketId
          },
          {headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}});
    } else {
      return false;
    }
  },
  getUserMovimentazioni: async (token: string, tokenGift: string) => {
    if (tokenGift && token) {
      return new Promise( resolve => {
        axios.get(API_URL + '/api/user-movimentazionis?pagination[page]=1&pagination[pageSize]=10&populate=user_gift%2C%20user_gift.prodotti%2C%20users&filters[$and][0][token][$eq]=' + tokenGift + '&filters[$and][1][isUsed][$eq]=false' ,
            {headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}})
      })
    }
  },
  getUserGift: async (token?: string, id?: number) => {
    if (token && id) {
      return await axios.get(API_URL + '/api/user-gifts/' + id ,
          {headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}})
    } else {
      return false;
    }
  },
  resetPassword: async (code: any, password: any, passwordConfirmation: any) => {
    if (code && password && passwordConfirmation) {
      return await axios.post(API_URL + '/api/auth/reset-password' ,
          {
            code: code,
            password: password,
            passwordConfirmation: passwordConfirmation
          },
          {headers: {'Content-Type': 'application/json'}})
    } else {
      return false;
    }
  },
  resetLinkPassword: async (email: string) => {
    if (email) {
      return await axios.post(API_URL + '/api/auth/forgot-password' ,
          {
            email: email,
          },
          {headers: {'Content-Type': 'application/json'}})
    } else {
      return false;
    }
  }
}
